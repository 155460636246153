import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../../Assets/Lib/history";
import List from "./List";
import Details from "./Details";
import Add from "../../Therapists/Items/Add";

const Partnerships = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/admin/leads/therapists" component={List} />
        <Route exact path="/admin/leads/therapists/:id" component={Details} />
        <Route path="/admin/therapists/items/new" component={Add} />
      </Switch>
    </Router>
  );
};
export default Partnerships;
