import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Lib/history";
import NavigationLayout from "../../Components/NavigationLayout";
import GridView from "./Grid";
import Worldwide from "./Worldwide/Main";

const Hotlines = () => {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/hotlines" component={GridView} />
          <Route path="/admin/hotlines/worldwide" component={Worldwide} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
};
export default Hotlines;
