import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../Assets/Lib/history";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, titleEn: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, titleAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeTextEn = (e) => {
    setItem({ ...item, textEn: e.target.getContent() });
    setTextError("");
  };
  const handleChangeTextAr = (e) => {
    setItem({ ...item, textAr: e.target.getContent() });
    setTextError("");
  };
  const [textError, setTextError] = useState("");
  const handleChangeButtonEn = (newValue) => {
    setItem({ ...item, ctaTextEn: newValue });
  };
  const handleChangeButtonAr = (newValue) => {
    setItem({ ...item, ctaTextAr: newValue });
  };
  const handleChangeButtonLink = (newValue) => {
    setItem({ ...item, ctaLink: newValue });
  };
  const [isSaving, setIsSaving] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImageError, setDesktopImageError] = useState("");
  const [mobileImageError, setMobileImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      desktopImage:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setDesktopImageError("");
  };

  const [filesMobile, setFilesMobile] = useState([]);
  const handleDropZoneMobile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMobile((filesMobile) => [...filesMobile, ...acceptedFiles]),
    []
  );
  const fileUploadMobile = !filesMobile.length && <DropZone.FileUpload />;
  let uploadedFilesMobile = filesMobile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMobile[filesMobile.length - 1].name}
        source={
          validImageTypes.indexOf(filesMobile[filesMobile.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMobile[filesMobile.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMobile[filesMobile.length - 1].name}{" "}
        <Caption>{filesMobile[filesMobile.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageMobile = (filesMobile) => {
    setItem({
      ...item,
      mobileImage:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setMobileImageError("");
  };
  const [checkedShow, setCheckedShow] = useState(true);

  const handleChangeShow = (newValue) => {
    setItem({ ...item, show: newValue });
  };
  const [item, setItem] = useState({
    titleEn: "",
    textEn: "",
    titleAr: "",
    textAr: "",
    desktopImage: "",
    mobileImage: "",
    show: "",
    ctaTextEn: "",
    ctaTextAr: "",
    ctaLink: "",
  });
  useEffect(() => {
    axios
      .get(`admin/sliders/${id}`)
      .then((result) => {
        setItem({
          titleEn: result?.data?.title?.en ? result.data.title.en : "",
          titleAr: result?.data?.title?.ar ? result.data.title.ar : "",
          textEn: result?.data?.description?.en
            ? result.data.description.en
            : "",
          textAr: result?.data?.description?.ar
            ? result.data.description.ar
            : "",
          ctaTextEn: result?.data?.btn_name?.en ? result.data.btn_name.en : "",
          ctaTextAr: result?.data?.btn_name?.ar ? result.data.btn_name.ar : "",
          ctaLink: result?.data?.link ? result.data.link : "",
          desktopImage: result?.data?.image ? result.data.image : "",
          mobileImage: result?.data?.image_mob ? result.data.image_mob : "",
          show: result.data.show == 0 ? false : true,
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page title="Edit Banner" breadcrumbs={[{ url: "/admin/banners" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField value={item.titleEn} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField value={item.titleAr} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Description En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.textEn}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Description Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.textAr}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">CTA Text En</TextStyle>
              <TextField
                value={item.ctaTextEn}
                onChange={handleChangeButtonEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">CTA Text Ar</TextStyle>
              <TextField
                value={item.ctaTextAr}
                onChange={handleChangeButtonAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">CTA Link</TextStyle>
          <TextField value={item.ctaLink} onChange={handleChangeButtonLink} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Desktop Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={item.desktopImage ? item.desktopImage : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Mobile Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={item.mobileImage ? item.mobileImage : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone
                    onDrop={handleDropZoneMobile}
                    onDropAccepted={sendImageMobile}
                  >
                    {uploadedFilesMobile}
                    {fileUploadMobile}
                  </DropZone>
                </div>
              </div>
              <InlineError message={mobileImageError} />
            </FormLayout>
          </FormLayout.Group>
          <Checkbox
            label="Is Active"
            checked={item.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/sliders/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/banners`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if ((!item.titleEn && !item.titleAr) || (!item.textEn && !item.textAr)) {
      !item.titleEn && !item.titleAr && setTitleError("This field is required");
      !item.textEn && !item.textAr && setTextError("This field is required");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("id", id);
      item.titleEn && form_data.append("title[en]", item.titleEn);
      item.titleAr && form_data.append("title[ar]", item.titleAr);
      item.textEn && form_data.append("description[en]", item.textEn);
      item.textAr && form_data.append("description[ar]", item.textAr);
      files.length && form_data.append("image", files[files.length - 1]);
      filesMobile.length &&
        form_data.append("image_mob", filesMobile[filesMobile.length - 1]);
      form_data.append("order", 1);
      form_data.append("show", 1);
      item.ctaTextEn && form_data.append("btn_name[en]", item.ctaTextEn);
      item.ctaTextAr && form_data.append("btn_name[ar]", item.ctaTextAr);
      item.ctaLink && form_data.append("link", item.ctaLink);
      form_data.append("show", item.show ? 1 : 0);

      axios
        .post("/admin/sliders/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/banners");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Edit;
