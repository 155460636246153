import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Lib/history";
import NavigationLayout from "../../Components/NavigationLayout";
import GridView from "./Grid";
import Items from "./Items/Main";
import Categories from "./Categories/Main";

const FAQs = () => {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/faqs" component={GridView} />
          <Route path="/admin/faqs/categories" component={Categories} />
          <Route path="/admin/faqs/items" component={Items} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
};
export default FAQs;
