import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";
import { useParams } from "react-router-dom";
import RichEditor from "@methodexists/me-rich-editor";

const Edit = () => {
  var PromiseAll = require("promises-all");
  const { id } = useParams();
  const handleChangeTitleEn = (newValue) => {
    setArticle({
      ...article,
      titleEn: newValue,
      // alias: convertToSlug(newValue),
    });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setArticle({ ...article, titleAr: newValue });
    setTitleError("");
  };
  const handleChangeAuthorEn = (newValue) => {
    setArticle({ ...article, authorEn: newValue });
  };
  const handleChangeAuthorAr = (newValue) => {
    setArticle({ ...article, authorAr: newValue });
  };
  const handleChangeDate = (newValue) => {
    setArticle({ ...article, date: newValue });
  };
  const handleChangeDescriptionEn = (newValue) => {
    setArticle({ ...article, descriptionEn: newValue });
  };
  const handleChangeDescriptionAr = (newValue) => {
    setArticle({ ...article, descriptionAr: newValue });
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeTextEn = (newValue) => {
    setArticle({ ...article, textEn: newValue });
    setTextError("");
  };
  const handleChangeTextAr = (newValue) => {
    setArticle({ ...article, textAr: newValue });
    setTextError("");
  };
  const handleChangeButtonTextEn = (newValue) => {
    setArticle({ ...article, buttonTextEn: newValue });
  };
  const handleChangeButtonTextAr = (newValue) => {
    setArticle({ ...article, buttonTextAr: newValue });
  };
  const handleChangeButtonLink = (newValue) => {
    setArticle({ ...article, buttonLink: newValue });
  };
  const handleChangeButtonArLink = (newValue) => {
    setArticle({ ...article, buttonLinkAr: newValue });
  };
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [mobileImagePreview, setMobileImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [mobileImageError, setMobileImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setArticle({
      ...article,
      desktopImage:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setDesktopImageError("");
  };

  const [filesMobile, setFilesMobile] = useState([]);
  const handleDropZoneMobile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMobile((filesMobile) => [...filesMobile, ...acceptedFiles]),
    []
  );
  const fileUploadMobile = !filesMobile.length && <DropZone.FileUpload />;
  let uploadedFilesMobile = filesMobile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMobile[filesMobile.length - 1].name}
        source={
          validImageTypes.indexOf(filesMobile[filesMobile.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMobile[filesMobile.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMobile[filesMobile.length - 1].name}{" "}
        <Caption>{filesMobile[filesMobile.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageMobile = (filesMobile) => {
    setArticle({
      ...article,
      mobileImage:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setMobileImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const handleChangeShow = (newValue) => {
    setArticle({ ...article, show: newValue });
  };
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleSelectChangeCategory = (newValue) => {
    console.log(newValue);
    setArticle({ ...article, category: newValue });
    setCategoryError("");
  };
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [categoryError, setCategoryError] = useState("");
  const [article, setArticle] = useState({
    titleEn: "",
    textEn: "",
    titleAr: "",
    textAr: "",
    desktopImage: "",
    mobileImage: "",
    tags: "",
    show: false,
    category: "",
    authorEn: "",
    authorAr: "",
    descriptionEn: "",
    descriptionAr: "",
    alias: "",
    date: "",
    buttonTextEn: "",
    buttonTextAr: "",
    buttonLink: "",
    buttonLinkAr: "",
  });

  useEffect(() => {
    console.log("HELLO".charAt("HELLO".length - 1));
    PromiseAll.all([getCategories(), getTags(), getItem()]).then(function (
      response
    ) {
      setIsSaving(false);
      if (typeof response.resolve[0] === "object") {
        setOptionsCategories(
          response.resolve[0].data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
            };
          })
        );
      }
      if (typeof response.resolve[1] === "object") {
        setOptions(
          response.resolve[1].data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: item.id,
            };
          })
        );
      }
      if (typeof response.resolve[2] === "object") {
        setSelected(
          response.resolve[2].data.tags.map((item, index) => {
            return { label: item.name.en, value: item.id };
          })
        );
        response.resolve[2].data.category_id !== null &&
          response.resolve[2].data.category_id !== "undefined" &&
          setSelectedCategory(String(response.resolve[2].data.category_id));
        setArticle({
          titleEn: response.resolve[2]?.data.title?.en
            ? response.resolve[2].data.title.en
            : "",
          titleAr: response.resolve[2]?.data.title?.ar
            ? response.resolve[2].data.title.ar
            : "",
          authorEn: response.resolve[2].data?.author?.en
            ? response.resolve[2].data.author.en
            : "",
          authorAr: response.resolve[2]?.data.author?.ar
            ? response.resolve[2].data.author.ar
            : "",
          descriptionEn: response.resolve[2]?.data.description?.en
            ? response.resolve[2].data.description.en
            : "",
          descriptionAr: response.resolve[2]?.data.description?.ar
            ? response.resolve[2].data.description.ar
            : "",
          textEn: response.resolve[2]?.data.text?.en
            ? response.resolve[2].data.text.en
            : "",
          textAr: response.resolve[2]?.data.text?.ar
            ? response.resolve[2].data.text.ar
            : "",
          desktopImage: response.resolve[2]?.data.image
            ? response.resolve[2].data.image
            : "",
          mobileImage: response.resolve[2]?.data.image_mob
            ? response.resolve[2].data.image_mob
            : "",
          show: response.resolve[2].data.show == 0 ? false : true,
          category: String(response.resolve[2]?.data.category_id),
          alias:
            response.resolve[2]?.data.alias && response.resolve[2].data.alias,
          date: response.resolve[2]?.data.date && response.resolve[2].data.date,
          buttonTextEn: response.resolve[2]?.data.btn_name?.en
            ? response.resolve[2].data.btn_name.en
            : "",
          buttonTextAr: response.resolve[2]?.data.btn_name?.ar
            ? response.resolve[2].data.btn_name.ar
            : "",
          buttonLink: response.resolve[2]?.data.btn_link?.en
            ? response.resolve[2].data.btn_link?.en
            : "",
          buttonLinkAr: response.resolve[2]?.data.btn_link?.ar
            ? response.resolve[2].data.btn_link?.ar
            : "",
        });
      }
    });
  }, []);

  const getCategories = () => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get("/admin/article-categories")
          .then(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 0);
    });
  };
  const getTags = () => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get("/admin/tags")
          .then(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 1000);
    });
  };
  const getItem = () => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get(`/admin/articles/${id}`)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 2000);
    });
  };
  const handleImageUpload = (file) => {
    const form_data = new FormData();
    form_data.append("image", file);
    return axios
      .post("/admin/images", form_data)
      .then((res) => {
        return res.data.path;
      })
      .catch((err) => console.log(""));
  };
  return (
    <Page title="Edit Page" breadcrumbs={[{ url: "/admin/pages" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Category</TextStyle>
          <Select
            options={optionsCategories}
            onChange={handleSelectChangeCategory}
            value={article.category}
            placeholder="Please select"
            error={categoryError}
          />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={article.titleEn}
                onChange={handleChangeTitleEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={article.titleAr}
                onChange={handleChangeTitleAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Alias</TextStyle>
              <TextField value={article.alias} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Date</TextStyle>
              <TextField
                value={article.date}
                onChange={handleChangeDate}
                type="date"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Author En</TextStyle>
              <TextField
                value={article.authorEn}
                onChange={handleChangeAuthorEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Author Ar</TextStyle>
              <TextField
                value={article.authorAr}
                onChange={handleChangeAuthorAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Description En</TextStyle>
              <TextField
                value={article.descriptionEn}
                onChange={handleChangeDescriptionEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Description Ar</TextStyle>
              <TextField
                value={article.descriptionAr}
                onChange={handleChangeDescriptionAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Button Text En</TextStyle>
              <TextField
                value={article.buttonTextEn}
                onChange={handleChangeButtonTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Button Text Ar</TextStyle>
              <TextField
                value={article.buttonTextAr}
                onChange={handleChangeButtonTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Button Link En</TextStyle>
              <TextField
                value={article.buttonLink}
                onChange={handleChangeButtonLink}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Button Link Ar</TextStyle>
              <TextField
                value={article.buttonLinkAr}
                onChange={handleChangeButtonArLink}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Content En</TextStyle>
              <RichEditor
                value={article.textEn}
                onChange={handleChangeTextEn}
                onImageUpload={handleImageUpload}
                debounceDelay={0}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Conent Ar</TextStyle>
              <RichEditor
                value={article.textAr}
                onChange={handleChangeTextAr}
                onImageUpload={handleImageUpload}
                debounceDelay={0}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Desktop Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={article.desktopImage ? article.desktopImage : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Mobile Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={article.mobileImage ? article.mobileImage : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone
                    onDrop={handleDropZoneMobile}
                    onDropAccepted={sendImageMobile}
                  >
                    {uploadedFilesMobile}
                    {fileUploadMobile}
                  </DropZone>
                </div>
              </div>
              <InlineError message={mobileImageError} />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout>
            <TextStyle variation="strong">Tags</TextStyle>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            />
          </FormLayout>
          <Checkbox
            label="Is Active"
            checked={article.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/articles/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/pages`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (!article.titleEn && !article.titleAr) {
      setTitleError("Please enter a title");
    } else {
      let fruits = ["Apple", "Banana"];
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("id", id);
      article.titleEn && form_data.append("title[en]", article.titleEn);
      article.titleAr && form_data.append("title[ar]", article.titleAr);
      form_data.append("text[en]", article.textEn);
      form_data.append("text[ar]", article.textAr);
      selected.forEach((selected, index) =>
        form_data.append(`tags[${index}]`, selected.value)
      );
      files.length && form_data.append("image", files[files.length - 1]);
      filesMobile.length &&
        form_data.append("image_mob", filesMobile[filesMobile.length - 1]);
      form_data.append("show", article.show ? 1 : 0);
      form_data.append("highlighted", 1);
      form_data.append("order", 1);
      form_data.append("author[en]", article.authorEn);
      form_data.append("author[ar]", article.authorAr);
      form_data.append("description[en]", article.descriptionEn);
      form_data.append("description[ar]", article.descriptionAr);
      form_data.append("btn_name[en]", article.buttonTextEn);
      form_data.append("btn_name[ar]", article.buttonTextAr);
      form_data.append("btn_link[en]", article.buttonLink);
      form_data.append("btn_link[ar]", article.buttonLinkAr);
      form_data.append("date", article.date);
      article.alias && form_data.append("alias", article.alias);
      article.category && form_data.append("category_id", article.category);

      axios
        .post("/admin/articles/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/pages");
          }
        })
        .catch((err) => console.log(""));
    }
  }
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
};
export default Edit;
