import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Lib/history";
import NavigationLayout from "../../Components/NavigationLayout";
import GridView from "./Grid";
import Organizations from "./Organizations/Main";
import Therapists from "./Therapists/Main";
import WellnessCenters from "./WellnessCenters/Main";

const Leads = () => {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/leads" component={GridView} />
          <Route path="/admin/leads/organizations" component={Organizations} />
          <Route path="/admin/leads/therapists" component={Therapists} />
          <Route
            path="/admin/leads/wellness-centers"
            component={WellnessCenters}
          />
        </Switch>
      </Router>
    </NavigationLayout>
  );
};
export default Leads;
