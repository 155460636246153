import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../../Assets/Lib/history";
import List from "./List";
import Add from "./Add";
import NavigationLayout from "../../../Components/NavigationLayout";
import Edit from "./Edit";

const Doctors = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/admin/useful-links/:linkId/centers/:centerId/doctors"
          component={List}
        />
        <Route
          path="/admin/useful-links/:linkId/centers/:centerId/doctors/new"
          component={Add}
        />
        <Route
          path="/admin/useful-links/:linkId/centers/:centerId/doctors/:doctorId"
          component={Edit}
        />
      </Switch>
    </Router>
  );
};
export default Doctors;
