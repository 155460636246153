import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  Button,
  Toast,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../../Assets/Lib/history";
import { useParams } from "react-router-dom";
import MultiSelect from "react-multi-select-component";

const Add = () => {
  const { id } = useParams();
  var PromiseAll = require("promises-all");
  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, nameEn: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, nameAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeEmail = (newValue) => {
    setItem({ ...item, email: newValue });
  };
  const handleChangePhoneNumberEn = (newValue) => {
    setItem({ ...item, phoneNumberEn: newValue });
  };
  const handleChangePhoneNumberAr = (newValue) => {
    setItem({ ...item, phoneNumberAr: newValue });
  };
  const handleChangeAddressEn = (newValue) => {
    setItem({ ...item, addressEn: newValue });
  };
  const handleChangeAddressAr = (newValue) => {
    setItem({ ...item, addressAr: newValue });
  };
  const handleChangeTextEn = (newValue) => {
    setItem({ ...item, summaryEn: newValue });
    setTextError("");
  };
  const handleChangeTextAr = (newValue) => {
    setItem({ ...item, summaryAr: newValue });
    setTextError("");
  };
  const [textError, setTextError] = useState("");
  const handleChangeProfileEn = (newValue) => {
    setItem({ ...item, profileEn: newValue });
    setProfileError("");
  };
  const handleChangeProfileAr = (newValue) => {
    setItem({ ...item, profileAr: newValue });
    setProfileError("");
  };
  const [profileError, setProfileError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
  };
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = (newValue) => {
    setItem({ ...item, show: newValue });
  };
  const [summaryError, setSummaryError] = useState("");
  const handleChangeVisit = (newValue) => {
    setItem({ ...item, visit: newValue });
  };
  const [optionsCountries, setOptionsCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const handleChangeOnline = (newValue) => {
    setItem({ ...item, online: newValue });
  };
  const handleChangeRecommended = (newValue) => {
    setItem({ ...item, recommended: newValue });
  };
  const handleChangeYoutube = (newValue) => {
    setItem({ ...item, youtube: newValue });
  };
  const handleChangePrinterest = (newValue) => {
    setItem({ ...item, printerest: newValue });
  };
  const handleChangeInstagram = (newValue) => {
    setItem({ ...item, instagram: newValue });
  };
  const handleChangeFacebook = (newValue) => {
    setItem({ ...item, facebook: newValue });
  };
  const handleChangeTwitter = (newValue) => {
    setItem({ ...item, twitter: newValue });
  };
  const handleChangeAppStore = (newValue) => {
    setItem({ ...item, appStore: newValue });
  };
  const handleChangePlayStore = (newValue) => {
    setItem({ ...item, playStore: newValue });
  };
  const [item, setItem] = useState({
    nameEn: "",
    nameAr: "",
    phoneNumberEn: "",
    phoneNumberAr: "",
    addressEn: "",
    addressAr: "",
    email: "",
    summaryEn: "",
    summaryAr: "",
    profileEn: "",
    profileAr: "",
    image: "",
    show: "",
    website: "",
    online: "",
    youtube: "",
    printerest: "",
    instagram: "",
    facebook: "",
    twitter: "",
    appStore: "",
    playStore: "",
    recommended: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    let responseCountries = "";
    let responseItem = "";

    try {
      responseCountries = await axios.get(`/admin/countries`);
      responseCountries?.data &&
        setOptionsCountries(
          responseCountries.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseItem = await axios.get(`/admin/organizations/${id}`);
      setItem({
        appStore: responseItem?.data?.social?.app_store
          ? responseItem.data.social.app_store
          : "",
        playStore: responseItem?.data?.social?.play_store
          ? responseItem.data.social.play_store
          : "",
        youtube: responseItem?.data?.social?.youtube
          ? responseItem.data.social.youtube
          : "",
        printerest: responseItem?.data?.social?.printerest
          ? responseItem.data.social.printerest
          : "",
        facebook: responseItem?.data?.social?.facebook
          ? responseItem.data.social.facebook
          : "",
        instagram: responseItem?.data?.social?.instagram
          ? responseItem.data.social.instagram
          : "",
        twitter: responseItem?.data?.social?.youtube
          ? responseItem.data.social.twitter
          : "",
        nameEn: responseItem?.data?.name?.en ? responseItem.data.name.en : "",
        nameAr: responseItem?.data?.name?.ar ? responseItem.data.name.ar : "",
        phoneNumberEn: responseItem?.data?.phone?.en
          ? responseItem.data.phone.en
          : "",
        phoneNumberAr: responseItem?.data?.phone?.ar
          ? responseItem.data.phone.ar
          : "",
        addressEn: responseItem?.data?.address?.en
          ? responseItem.data.address.en
          : "",
        addressAr: responseItem?.data?.address?.ar
          ? responseItem.data.address.ar
          : "",
        email: responseItem?.data?.email ? responseItem.data.email : "",
        summaryEn: responseItem?.data?.summary?.en
          ? responseItem.data.summary.en
          : "",
        summaryAr: responseItem?.data?.summary?.ar
          ? responseItem.data.summary.ar
          : "",
        profileEn: responseItem?.data?.profile?.en
          ? responseItem.data.profile.en
          : "",
        profileAr: responseItem?.data?.profile?.ar
          ? responseItem.data.profile.ar
          : "",
        image: responseItem?.data?.image ? responseItem.data.image : "",
        show: responseItem.data.show,

        visit: responseItem?.data?.website ? responseItem.data.website : "",
        online: responseItem?.data?.online ? true : false,
        recommended: responseItem?.data?.recommended ? true : false,
      });
      responseItem.data.countries &&
        setSelectedCountries(
          responseItem.data.countries.map((item, index) => {
            return {
              label: item.en_name,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    setIsSaving(false);
  }
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content="Image Deleted" onDismiss={toggleActive} />
  ) : null;
  const deleteImage = () => {
    axios
      .get(`/admin/organizations/${id}/image/clear`)
      .then((res) => {
        setItem({ ...item, image: "" });
        toggleActive();
        // history.push("/admin/therapists/items");
      })
      .catch((err) => console.log(""));
  };
  return (
    <Page
      title="Edit Item"
      breadcrumbs={[{ url: "/admin/therapists/organizations" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField value={item.nameEn} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField value={item.nameAr} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Phone Number En</TextStyle>
              <TextField
                value={item.phoneNumberEn}
                onChange={handleChangePhoneNumberEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Phone Number Ar</TextStyle>
              <TextField
                value={item.phoneNumberAr}
                onChange={handleChangePhoneNumberAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Address En</TextStyle>
              <TextField
                value={item.addressEn}
                onChange={handleChangeAddressEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Address Ar</TextStyle>
              <TextField
                value={item.addressAr}
                onChange={handleChangeAddressAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Email</TextStyle>
          <TextField value={item.email} onChange={handleChangeEmail} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Summary En</TextStyle>
              <TextField
                value={item.summaryEn}
                onChange={handleChangeTextEn}
              ></TextField>
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Summary Ar</TextStyle>
              <TextField
                value={item.summaryAr}
                onChange={handleChangeTextAr}
              ></TextField>
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={summaryError} />
          <TextStyle variation="strong">Visit</TextStyle>
          <TextField
            value={item.visit}
            onChange={handleChangeVisit}
            autoComplete="off"
          />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Youtube Link</TextStyle>
              <TextField
                value={item.youtube}
                onChange={handleChangeYoutube}
                autoComplete="off"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Printerest Link</TextStyle>
              <TextField
                value={item.printerest}
                onChange={handleChangePrinterest}
                autoComplete="off"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Instagram Link</TextStyle>
              <TextField
                value={item.instagram}
                onChange={handleChangeInstagram}
                autoComplete="off"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Facebook Link</TextStyle>
              <TextField
                value={item.facebook}
                onChange={handleChangeFacebook}
                autoComplete="off"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Twitter Link</TextStyle>
              <TextField
                value={item.twitter}
                onChange={handleChangeTwitter}
                autoComplete="off"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">App Store Link</TextStyle>
              <TextField
                value={item.appStore}
                onChange={handleChangeAppStore}
                autoComplete="off"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Play Store Link</TextStyle>
              <TextField
                value={item.playStore}
                onChange={handleChangePlayStore}
                autoComplete="off"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Profile En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.profileEn}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleChangeProfileEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Profile Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.profileAr}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleChangeProfileAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={profileError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail size="large" source={item.image} />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>
          <Button destructive onClick={deleteImage} size="slim">
            Delete Image
          </Button>
          <TextStyle variation="strong">Countries</TextStyle>
          <MultiSelect
            options={optionsCountries}
            value={selectedCountries}
            onChange={setSelectedCountries}
            labelledBy="Please select a country"
          />
          <Checkbox
            label="Is Purely Online"
            checked={item.online}
            onChange={handleChangeOnline}
          />
          <Checkbox
            label="Is Recommended"
            checked={item.recommended}
            onChange={handleChangeRecommended}
          />
          <Checkbox
            label="Is Active"
            checked={item.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/organizations/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/therapists/organizations`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
      {toastMarkup}
    </Page>
  );

  function handleSave() {
    if (!item.nameEn && !item.nameAr) {
      setTitleError("Please enter a name");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      item.visit && form_data.append("website", item.visit);
      item.nameEn && form_data.append("name[en]", item.nameEn);
      item.nameAr && form_data.append("name[ar]", item.nameAr);
      item.summaryEn && form_data.append("summary[en]", item.summaryEn);
      item.summaryAr && form_data.append("summary[ar]", item.summaryAr);
      item.profileEn && form_data.append("profile[en]", item.profileEn);
      item.profileAr && form_data.append("profile[ar]", item.profileAr);
      files.length && form_data.append("image", files[files.length - 1]);
      form_data.append("show", item.show ? 1 : 0);
      form_data.append("highlighted", 1);
      form_data.append("order", 1);
      item.addressEn && form_data.append("address[en]", item.addressEn);
      item.addressAr && form_data.append("address[ar]", item.addressAr);
      item.phoneNumberEn && form_data.append("phone[en]", item.phoneNumberEn);
      item.phoneNumberAr && form_data.append("phone[ar]", item.phoneNumberAr);
      item.email && form_data.append("email", item.email);
      form_data.append("id", id);
      selectedCountries.length &&
        selectedCountries.forEach((selected, index) =>
          form_data.append(`country_id[${index}]`, selected.value)
        );
      form_data.append("online", item.online ? 1 : 0);
      item.youtube && form_data.append("social[youtube]", item.youtube);
      item.printerest &&
        form_data.append("social[printerest]", item.printerest);
      item.instagram && form_data.append("social[instagram]", item.instagram);
      item.facebook && form_data.append("social[facebook]", item.facebook);
      item.twitter && form_data.append("social[twitter]", item.twitter);
      item.appStore && form_data.append("social[app_store]", item.appStore);
      item.playStore && form_data.append("social[play_store]", item.playStore);
      form_data.append("recommended", item.recommended ? 1 : 0);
      axios
        .post("/admin/organizations/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/therapists/organizations");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Add;
