import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  RadioButton,
  Select,
  Button,
  Toast,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const handleChangeYearsOfExperienceEn = (newValue) => {
    setItem({ ...item, experienceEn: newValue });
  };
  const handleChangeYearsOfExperienceAr = (newValue) => {
    setItem({ ...item, experienceAr: newValue });
  };
  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, nameEn: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, nameAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeOccupationEn = (newValue) => {
    setItem({ ...item, occupationEn: newValue });
  };
  const handleChangeOccupationAr = (newValue) => {
    setItem({ ...item, occupationAr: newValue });
  };
  const handleChangeTextEn = (value) => {
    setItem({ ...item, summaryEn: value });
    setTextError("");
  };
  const handleChangeTextAr = (value) => {
    setItem({ ...item, summaryAr: value });
    setTextError("");
  };
  const handleChangeServicesEn = (value) => {
    setItem({ ...item, servicesEn: value });
  };
  const handleChangeServicesAr = (value) => {
    setItem({ ...item, servicesAr: value });
  };
  const [textError, setTextError] = useState("");
  const handleChangeProfileEn = (value) => {
    setItem({ ...item, profileEn: value });
    setProfileError("");
  };
  const handleChangeProfileAr = (value) => {
    setItem({ ...item, profileAr: value });
    setProfileError("");
  };
  const handleChangePhoneNumberEn = (newValue) => {
    setItem({ ...item, phoneNumberEn: newValue });
  };
  const handleChangePhoneNumberAr = (newValue) => {
    setItem({ ...item, phoneNumberAr: newValue });
  };
  const handleChangeEmail = (newValue) => {
    setItem({ ...item, email: newValue });
  };
  const handleChangeSpecialtiesEn = (value) => {
    setItem({ ...item, specialtiesEn: value });
  };
  const handleChangeSpecialtiesAr = (value) => {
    setItem({ ...item, specialtiesAr: value });
  };
  const [profileError, setProfileError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [optionsLanguages, setOptionsLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [optionsDisorders, setOptionsDisorders] = useState([]);
  const [selectedDisorders, setSelectedDisorders] = useState([]);
  const [optionsOrganizations, setOptionOrganizationss] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [optionsProfessions, setOptionsProfessions] = useState([]);
  const [selectedProfessions, setSelectedProfessions] = useState([]);
  const handleChangeShow = (newValue) => {
    setItem({ ...item, show: newValue });
  };
  const handleChangeRecommended = (newValue) => {
    setItem({ ...item, recommended: newValue });
  };
  const [optionsCountries, setOptionsCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [optionsSpecialicities, setOptionsSpecialicities] = useState([]);
  const handleSelectSpecialicity = (newValue) => {
    setItem({ ...item, specialty: newValue });
    setSpecialtyError("");
  };
  const [optionsAges, setOptionsAges] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);
  const [valueGender, setGender] = useState("");
  const handleChangeGender = useCallback((_checked, newValue) => {
    setItem({ ...item, gender: newValue });
    setGenderError("");
  }, []);
  const [ageError, setAgeError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [specialtyError, setSpecialtyError] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [languageError, setLanguageError] = useState("");
  const [optionsContributions, setOptionsContributions] = useState([
    { label: "Content for Databank", value: "Content for Databank" },
    {
      label: "Run support group for Houna",
      value: "Run support group for Houna",
    },
    {
      label: "Content for social media posts",
      value: "Content for social media posts",
    },
    { label: "Instagram live sessions", value: "Instagram live sessions" },
    {
      label: "Provide free sessions to users",
      value: "Provide free sessions to users",
    },
    {
      label: "Provide discounted sessions to users",
      value: "Provide discounted sessions to users",
    },
    {
      label: "Review content for scientific and evidence-based accuracy",
      value: "Review content for scientific and evidence-based accuracy",
    },
  ]);
  const [selectedContributions, setSelectedContributions] = useState([]);
  const handleChangeVisit = (newValue) => {
    setItem({ ...item, visit: newValue });
  };
  const handleChangeOnline = (newValue) => {
    setItem({ ...item, online: newValue });
  };
  const handleChangeYoutube = (newValue) => {
    setItem({ ...item, youtube: newValue });
  };
  const handleChangePrinterest = (newValue) => {
    setItem({ ...item, printerest: newValue });
  };
  const handleChangeInstagram = (newValue) => {
    setItem({ ...item, instagram: newValue });
  };
  const handleChangeFacebook = (newValue) => {
    setItem({ ...item, facebook: newValue });
  };
  const handleChangeTwitter = (newValue) => {
    setItem({ ...item, twitter: newValue });
  };
  const handleChangeAppStore = (newValue) => {
    setItem({ ...item, appStore: newValue });
  };
  const handleChangePlayStore = (newValue) => {
    setItem({ ...item, playStore: newValue });
  };
  const [item, setItem] = useState({
    nameEn: "",
    nameAr: "",
    summaryEn: "",
    summaryAr: "",
    profileEn: "",
    profileAr: "",
    country: "",
    specialty: "",
    image: "",
    show: "",
    occupationEn: "",
    occupationAr: "",
    phoneNumberEn: "",
    phoneNumberAr: "",
    email: "",
    servicesEn: "",
    servicesAr: "",
    specialtiesEn: "",
    specialtiesAr: "",
    experienceEn: "",
    experienceAr: "",
    visit: "",
    online: "",
    youtube: "",
    printerest: "",
    instagram: "",
    facebook: "",
    twitter: "",
    appStore: "",
    playStore: "",
    recommended: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    let responseAges = "";
    let responseLanguages = "";
    let responseCountries = "";
    let responseSpecialties = "";
    let responseTags = "";
    let responseDisorders = "";
    let responseOrganizations = "";
    let responseProfessions = "";
    try {
      responseAges = await axios.get(`/admin/age-range`);
      responseAges?.data &&
        setOptionsAges(
          responseAges.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseLanguages = await axios.get(`/admin/languages`);
      responseLanguages?.data &&
        setOptionsLanguages(
          responseLanguages.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseCountries = await axios.get(`/admin/countries`);
      responseCountries?.data &&
        setOptionsCountries(
          responseCountries.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseSpecialties = await axios.get(`/admin/therapists-speciality`);
      responseSpecialties?.data &&
        setOptionsSpecialicities(
          responseSpecialties.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseTags = await axios.get(`/admin/tags`);
      responseTags?.data &&
        setOptions(
          responseTags.data.data.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseOrganizations = await axios.get(`/admin/organizations`);
      responseOrganizations?.data &&
        setOptionOrganizationss(
          responseOrganizations.data.data.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseDisorders = await axios.get(`/admin/disorders`);
      responseDisorders?.data &&
        setOptionsDisorders(
          responseDisorders.data.data.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseProfessions = await axios.get(`/admin/professions`);
      responseProfessions?.data?.data &&
        setOptionsProfessions(
          responseProfessions.data.data.map((item, index) => {
            return {
              label: item.value[Object.keys(item.value)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      const response = await axios.get(`/admin/therapists/${id}`);
      console.log(response);
      response?.data &&
        setItem({
          appStore: response?.data?.social?.app_store
            ? response.data.social.app_store
            : "",
          playStore: response?.data?.social?.play_store
            ? response.data.social.play_store
            : "",
          youtube: response?.data?.social?.youtube
            ? response.data.social.youtube
            : "",
          printerest: response?.data?.social?.printerest
            ? response.data.social.printerest
            : "",
          facebook: response?.data?.social?.facebook
            ? response.data.social.facebook
            : "",
          instagram: response?.data?.social?.instagram
            ? response.data.social.instagram
            : "",
          twitter: response?.data?.social?.youtube
            ? response.data.social.twitter
            : "",
          nameEn: response?.data?.name?.en ? response.data.name.en : "",
          nameAr: response?.data?.name?.ar ? response.data.name.ar : "",
          occupationEn: response?.data?.occupation?.en
            ? response.data.occupation.en
            : "",
          occupationAr: response?.data?.occupation?.ar
            ? response.data.occupation.ar
            : "",
          phoneNumberEn: response?.data?.phone?.en
            ? response.data.phone.en
            : "",
          phoneNumberAr: response?.data?.phone?.ar
            ? response.data.phone.ar
            : "",
          summaryEn: response?.data?.summary?.en
            ? response.data.summary.en
            : "",
          summaryAr: response?.data?.summary?.ar
            ? response.data.summary.ar
            : "",
          specialtiesEn: response?.data?.specialties_text?.en
            ? response.data.specialties_text.en
            : "",
          specialtiesAr: response?.data?.specialties_text?.ar
            ? response.data.specialties_text.ar
            : "",
          servicesEn: response?.data?.services?.en
            ? response.data.services.en
            : "",
          servicesAr: response?.data?.services?.ar
            ? response.data.services.ar
            : "",
          profileEn: response?.data?.profile?.en
            ? response.data.profile.en
            : "",
          profileAr: response?.data?.profile?.ar
            ? response.data.profile.ar
            : "",

          country:
            response?.data?.country_id != ""
              ? String(response.data.country_id)
              : "",
          specialty:
            response?.data?.speciality_id != ""
              ? String(response.data.speciality_id)
              : "",
          image: response?.data?.image ? response.data.image : "",
          show: response.data.show ? true : false,
          recommended: response.data.recommended ? true : false,
          email: response.data?.email && response.data.email,
          experienceEn: response?.data?.experience?.en
            ? response.data.experience.en
            : "",
          experienceAr: response?.data?.experience?.ar
            ? response.data.experience.ar
            : "",
          visit: response?.data?.website ? response.data.website : "",
          online: response?.data?.online ? true : false,
        });
      response?.data?.gender != "" && setGender(response.data.gender);
      response.data.countries &&
        setSelectedCountries(
          response.data.countries.map((item, index) => {
            return {
              label: item.en_name,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      response.data.languages &&
        setSelectedLanguages(
          response.data.languages.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      response.data.age_range &&
        setSelectedAges(
          response.data.age_range.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      response.data.tags &&
        setSelected(
          response.data.tags.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      response.data.organizations &&
        setSelectedOrganizations(
          response.data.organizations.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      response.data.disorders &&
        setSelectedDisorders(
          response.data.disorders.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      response.data.professions &&
        setSelectedProfessions(
          response.data.professions.map((item, index) => {
            return {
              label: item.value.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      response.data.contributions &&
        setSelectedContributions(
          response.data.contributions.map((item, index) => {
            return {
              label: item,
              value: item,
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content="Image Deleted" onDismiss={toggleActive} />
  ) : null;
  const deleteImage = () => {
    axios
      .get(`/admin/therapists/${id}/image/clear`)
      .then((res) => {
        setItem({ ...item, image: "" });
        toggleActive();
        // history.push("/admin/therapists/items");
      })
      .catch((err) => console.log(""));
  };
  return (
    <Page
      title="Edit Therapist"
      breadcrumbs={[{ url: "/admin/therapists/items" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField value={item.nameEn} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField value={item.nameAr} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Occupation En</TextStyle>
              <TextField
                value={item.occupationEn}
                onChange={handleChangeOccupationEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Occupation Ar</TextStyle>
              <TextField
                value={item.occupationAr}
                onChange={handleChangeOccupationAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Years of experience En</TextStyle>
              <TextField
                value={item.experienceEn}
                onChange={handleChangeYearsOfExperienceEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Years of experience Ar</TextStyle>
              <TextField
                value={item.experienceAr}
                onChange={handleChangeYearsOfExperienceAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Summary En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.summaryEn}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Summary Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.summaryAr}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={summaryError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Services En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.servicesEn}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeServicesEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Services Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.servicesAr}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeServicesAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Phone Number En</TextStyle>
              <TextField
                value={item.phoneNumberEn}
                onChange={handleChangePhoneNumberEn}
              ></TextField>
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Phone Number Ar</TextStyle>
              <TextField
                value={item.phoneNumberAr}
                onChange={handleChangePhoneNumberAr}
              ></TextField>
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Email</TextStyle>
          <TextField
            value={item.email}
            onChange={handleChangeEmail}
          ></TextField>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Profile En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.profileEn}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeProfileEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Profile Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.profileAr}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeProfileAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={profileError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={item.image ? item.image : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>

              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout>
            <Button destructive onClick={deleteImage} size="slim">
              Delete Image
            </Button>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Youtube Link</TextStyle>
                <TextField
                  value={item.youtube}
                  onChange={handleChangeYoutube}
                  autoComplete="off"
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Printerest Link</TextStyle>
                <TextField
                  value={item.printerest}
                  onChange={handleChangePrinterest}
                  autoComplete="off"
                />
              </FormLayout>
            </FormLayout.Group>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Instagram Link</TextStyle>
                <TextField
                  value={item.instagram}
                  onChange={handleChangeInstagram}
                  autoComplete="off"
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Facebook Link</TextStyle>
                <TextField
                  value={item.facebook}
                  onChange={handleChangeFacebook}
                  autoComplete="off"
                />
              </FormLayout>
            </FormLayout.Group>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Twitter Link</TextStyle>
                <TextField
                  value={item.twitter}
                  onChange={handleChangeTwitter}
                  autoComplete="off"
                />
              </FormLayout>
            </FormLayout.Group>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">App Store Link</TextStyle>
                <TextField
                  value={item.appStore}
                  onChange={handleChangeAppStore}
                  autoComplete="off"
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Play Store Link</TextStyle>
                <TextField
                  value={item.playStore}
                  onChange={handleChangePlayStore}
                  autoComplete="off"
                />
              </FormLayout>
            </FormLayout.Group>
            <TextStyle variation="strong">Website</TextStyle>
            <TextField
              value={item.visit}
              onChange={handleChangeVisit}
              autoComplete="off"
            />
            <TextStyle variation="strong">Specialty</TextStyle>
            <Select
              options={optionsSpecialicities}
              value={item.specialty}
              onChange={handleSelectSpecialicity}
              placeholder="Please select a specialty"
            />
            <InlineError message={specialtyError} />

            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Specialties En</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  value={item.specialtiesEn}
                  init={{
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onEditorChange={handleChangeSpecialtiesEn}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Specialties Ar</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  value={item.specialtiesAr}
                  init={{
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onEditorChange={handleChangeSpecialtiesAr}
                />
              </FormLayout>
            </FormLayout.Group>
            <TextStyle variation="strong">Profession</TextStyle>
            <MultiSelect
              options={optionsProfessions}
              value={selectedProfessions}
              onChange={setSelectedProfessions}
              labelledBy="Please select a profession"
            />
            <TextStyle variation="strong">Age</TextStyle>
            <MultiSelect
              options={optionsAges}
              value={selectedAges}
              onChange={setSelectedAges}
              labelledBy="Please select age"
            />
            <InlineError message={ageError} />
            <TextStyle variation="strong">Tags</TextStyle>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            />
            <TextStyle variation="strong">Languages</TextStyle>
            <MultiSelect
              options={optionsLanguages}
              value={selectedLanguages}
              onChange={setSelectedLanguages}
              labelledBy="Please select a language"
            />
            <InlineError message={languageError} />
            <TextStyle variation="strong">Organizations</TextStyle>
            <MultiSelect
              options={optionsOrganizations}
              value={selectedOrganizations}
              onChange={setSelectedOrganizations}
              labelledBy="Select"
            />
            <TextStyle variation="strong">Disorders</TextStyle>
            <MultiSelect
              options={optionsDisorders}
              value={selectedDisorders}
              onChange={setSelectedDisorders}
              labelledBy="Select"
            />
            <TextStyle variation="strong">Contributions</TextStyle>
            <MultiSelect
              options={optionsContributions}
              value={selectedContributions}
              onChange={setSelectedContributions}
              labelledBy="Please select"
            />
            <TextStyle variation="strong">Countries</TextStyle>
            <MultiSelect
              options={optionsCountries}
              value={selectedCountries}
              onChange={setSelectedCountries}
              labelledBy="Please select a country"
            />
            <InlineError message={countryError} />
            <TextStyle variation="strong">Gender</TextStyle>
            <Stack horizontal>
              <RadioButton
                label="Male"
                checked={valueGender === "male"}
                id="male"
                name="male"
                onChange={handleChangeGender}
              />
              <RadioButton
                label="Female"
                id="female"
                name="female"
                checked={valueGender === "female"}
                onChange={handleChangeGender}
              />
            </Stack>
            <InlineError message={genderError} />
          </FormLayout>
          <Checkbox
            label="Is Purely Online"
            checked={item.online}
            onChange={handleChangeOnline}
          />
          <Checkbox
            label="Is Recommended"
            checked={item.recommended}
            onChange={handleChangeRecommended}
          />
          <Checkbox
            label="Is Active"
            checked={item.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/therapists/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/therapists/items`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
      {toastMarkup}
    </Page>
  );

  function handleSave() {
    if (
      (!item.nameEn && !item.nameAr) ||
      (!item.summaryEn && !item.summaryAr) ||
      (!item.profileEn && !item.profileAr) ||
      !selectedAges.length ||
      !selectedCountries.length ||
      item.gender == "" ||
      item.specialty == "" ||
      !selectedLanguages.length
    ) {
      !item.nameEn && !item.nameAr && setTitleError("Please enter a title");
      !item.summaryEn &&
        !item.summaryAr &&
        setSummaryError("Please enter a summary");
      !item.profileEn &&
        !item.profileAr &&
        setProfileError("Please enter a profile");
      !selectedAges.length && setAgeError("Please select an age");
      !selectedCountries.length && setCountryError("Please select a country");
      item.gender == "" && setGenderError("Please select a gender");
      item.specialty == "" && setSpecialtyError("Please select an age");
      !selectedLanguages.length && setLanguageError("Please select a language");
    } else {
      setIsSaving(true);

      const form_data = new FormData();
      item.visit && form_data.append("website", item.visit);
      item.nameEn && form_data.append("name[en]", item.nameEn);
      item.nameAr && form_data.append("name[ar]", item.nameAr);
      item.summaryEn && form_data.append("summary[en]", item.summaryEn);
      item.summaryAr && form_data.append("summary[ar]", item.summaryAr);
      item.profileEn && form_data.append("profile[en]", item.profileEn);
      item.profileAr && form_data.append("profile[ar]", item.profileAr);
      selected.length &&
        selected.forEach((selected, index) =>
          form_data.append(`tags[${index}]`, selected.value)
        );
      selectedLanguages.length &&
        selectedLanguages.forEach((selected, index) =>
          form_data.append(`languages[${index}]`, selected.value)
        );
      selectedDisorders.length &&
        selectedDisorders.forEach((selected, index) =>
          form_data.append(`disorders[${index}]`, selected.value)
        );
      selectedDisorders.length &&
        selectedDisorders.forEach((selected, index) =>
          form_data.append(`disorders[${index}]`, selected.value)
        );
      selectedAges.forEach((selected, index) =>
        form_data.append(`age_range[${index}]`, selected.value)
      );
      item.specialty && form_data.append("speciality_id", item.specialty);
      files.length && form_data.append("image", files[files.length - 1]);
      form_data.append("show", item.show ? 1 : 0);
      form_data.append("recommended", item.recommended ? 1 : 0);
      form_data.append("highlighted", 1);
      form_data.append("order", 1);
      valueGender && form_data.append("gender", valueGender);
      form_data.append("id", id);
      item.nameEn && form_data.append("alias", convertToSlug(item.nameEn));
      item.occupationEn &&
        form_data.append("occupation[en]", item.occupationEn);
      item.occupationAr &&
        form_data.append("occupation[ar]", item.occupationAr);
      item.phoneNumberEn && form_data.append("phone[en]", item.phoneNumberEn);
      item.phoneNumberAr && form_data.append("phone[ar]", item.phoneNumberAr);
      selectedOrganizations.forEach((selected, index) =>
        form_data.append(`organization[${index}]`, selected.value)
      );
      item.email && form_data.append("email", item.email);
      item.servicesEn && form_data.append("services[en]", item.servicesEn);
      item.servicesAr && form_data.append("services[ar]", item.servicesAr);
      item.experienceEn &&
        form_data.append("experience[en]", item.experienceEn);
      item.experienceAr &&
        form_data.append("experience[ar]", item.experienceAr);
      item.specialtiesEn &&
        form_data.append("specialties_text[en]", item.specialtiesEn);
      item.specialtiesAr &&
        form_data.append("specialties_text[ar]", item.specialtiesAr);
      selectedProfessions.length &&
        selectedProfessions.forEach((selected, index) =>
          form_data.append(`professions[${index}]`, selected.value)
        );
      selectedContributions.length &&
        selectedContributions.forEach((selected, index) =>
          form_data.append(`contributions[${index}]`, selected.value)
        );
      selectedCountries.length &&
        selectedCountries.forEach((selected, index) =>
          form_data.append(`country_id[${index}]`, selected.value)
        );
      form_data.append("online", item.online ? 1 : 0);
      item.youtube && form_data.append("social[youtube]", item.youtube);
      item.printerest &&
        form_data.append("social[printerest]", item.printerest);
      item.instagram && form_data.append("social[instagram]", item.instagram);
      item.facebook && form_data.append("social[facebook]", item.facebook);
      item.twitter && form_data.append("social[twitter]", item.twitter);
      item.appStore && form_data.append("social[app_store]", item.appStore);
      item.playStore && form_data.append("social[play_store]", item.playStore);
      axios
        .post("/admin/therapists/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/therapists/items");
          }
        })
        .catch((err) => console.log(""));
    }
  }
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
};
export default Edit;
