import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";
import RichEditor from "@methodexists/me-rich-editor";

const Add = () => {
  var PromiseAll = require("promises-all");
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
    setAliasValue(convertToSlug(newValue));
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);

  const [aliasValue, setAliasValue] = useState("");
  const handleChangeAlias = useCallback((newValue) => {
    setAliasValue(newValue);
  }, []);
  const [dateValue, setDateValue] = useState("");
  const handleChangeDate = useCallback((newValue) => {
    setDateValue(newValue);
  }, []);
  const [authorEnValue, setAuthorEnValue] = useState("");
  const handleChangeAuthorEn = useCallback((newValue) => {
    setAuthorEnValue(newValue);
  }, []);
  const [authorArValue, setAuthorArValue] = useState("");
  const handleChangeAuthorAr = useCallback((newValue) => {
    setAuthorArValue(newValue);
  }, []);
  const [descriptionEnValue, setDescriptionEnValue] = useState("");
  const handleChangeDescriptionEn = useCallback((newValue) => {
    setDescriptionEnValue(newValue);
  }, []);
  const [descriptionArValue, setDescriptionArValue] = useState("");
  const handleChangeDescriptionAr = useCallback((newValue) => {
    setDescriptionArValue(newValue);
  }, []);
  const [buttonTextEnValue, setButtonTextEnValue] = useState("");
  const handleChangeButtonTextEn = useCallback((newValue) => {
    setButtonTextEnValue(newValue);
  }, []);
  const [buttonTextArValue, setButtonTextArValue] = useState("");
  const handleChangeButtonTextAr = useCallback((newValue) => {
    setButtonTextArValue(newValue);
  }, []);
  const [buttonLinkValue, setButtonLinkValue] = useState("");
  const handleChangeButtonLink = useCallback((newValue) => {
    setButtonLinkValue(newValue);
  }, []);
  const [buttonLinkArValue, setButtonLinkArValue] = useState("");
  const handleChangeButtonArLink = useCallback((newValue) => {
    setButtonLinkArValue(newValue);
  }, []);
  const [titleError, setTitleError] = useState("");
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = (newValue) => {
    setTextEnValue(newValue);
    setTextError("");
  };
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = (newValue) => {
    setTextArValue(newValue);
    setTextError("");
  };
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [mobileImagePreview, setMobileImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [mobileImageError, setMobileImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setDesktopImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setDesktopImageError("");
  };

  const [filesMobile, setFilesMobile] = useState([]);
  const handleDropZoneMobile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMobile((filesMobile) => [...filesMobile, ...acceptedFiles]),
    []
  );
  const fileUploadMobile = !filesMobile.length && <DropZone.FileUpload />;
  let uploadedFilesMobile = filesMobile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMobile[filesMobile.length - 1].name}
        source={
          validImageTypes.indexOf(filesMobile[filesMobile.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMobile[filesMobile.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMobile[filesMobile.length - 1].name}{" "}
        <Caption>{filesMobile[filesMobile.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageMobile = (filesMobile) => {
    setMobileImagePreview(
      validImageTypes.indexOf(filesMobile[filesMobile.length - 1].type) > 0
        ? window.URL.createObjectURL(filesMobile[filesMobile.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setMobileImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleSelectChangeCategory = useCallback((newValue) => {
    setSelectedCategory(newValue);
    setCategoryError("");
  }, []);

  const [optionsCategories, setOptionsCategories] = useState([]);
  const [categoryError, setCategoryError] = useState("");
  useEffect(() => {
    PromiseAll.all([getCategories(), getTags()]).then(function (response) {
      setIsSaving(false);
      console.log("esponse.resolve[0]=", response.resolve[0].data.data);

      if (typeof response.resolve[0] === "object") {
        setOptionsCategories(
          response.resolve[0].data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
            };
          })
        );
      }
      if (typeof response.resolve[1] === "object") {
        setOptions(
          response.resolve[1].data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: item.id,
            };
          })
        );
      }
    });
  }, []);

  const getCategories = () => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get("/admin/article-categories")
          .then(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 0);
    });
  };
  const getTags = () => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get("/admin/tags")
          .then(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 1000);
    });
  };
  const handleImageUpload = (file) => {
    const form_data = new FormData();
    form_data.append("image", file);
    return axios
      .post("/admin/images", form_data)
      .then((res) => {
        return res.data.path;
      })
      .catch((err) => console.log(""));
  };

  return (
    <Page title="Add Page" breadcrumbs={[{ url: "/admin/pages" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Category</TextStyle>
          <Select
            options={optionsCategories}
            onChange={handleSelectChangeCategory}
            value={selectedCategory}
            placeholder="Please select"
            error={categoryError}
          />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField value={titleEnValue} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField value={titleArValue} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Alias</TextStyle>
              <TextField value={aliasValue} onChange={handleChangeAlias} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Date</TextStyle>
              <TextField
                value={dateValue}
                onChange={handleChangeDate}
                type="date"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Author En</TextStyle>
              <TextField
                value={authorEnValue}
                onChange={handleChangeAuthorEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Author Ar</TextStyle>
              <TextField
                value={authorArValue}
                onChange={handleChangeAuthorAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Description En</TextStyle>
              <TextField
                value={descriptionEnValue}
                onChange={handleChangeDescriptionEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Description Ar</TextStyle>
              <TextField
                value={descriptionArValue}
                onChange={handleChangeDescriptionAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Button Text En</TextStyle>
              <TextField
                value={buttonTextEnValue}
                onChange={handleChangeButtonTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Button Text Ar</TextStyle>
              <TextField
                value={buttonTextArValue}
                onChange={handleChangeButtonTextAr}
              />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Button Link En</TextStyle>
              <TextField
                value={buttonLinkValue}
                onChange={handleChangeButtonLink}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Button Link Ar</TextStyle>
              <TextField
                value={buttonLinkArValue}
                onChange={handleChangeButtonArLink}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Content En</TextStyle>
              {/* <RichEditor
                value={textEnValue}
                onChange={handleChangeTextEn}
                onImageUpload={handleImageUpload}
                debounceDelay={0}n
              /> */}
              <input
                id="my-file"
                type="file"
                name="my-file"
                style={{ display: "none" }}
                // onChange=""
              />
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textEnValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "textcolor advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount image",
                  ],
                  toolbar:
                    "insertfile |image|undo redo | formatselect | bold italic forecolor  | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                  file_browser_callback_types: "image",
                  file_picker_callback: function (callback, value, meta) {
                    if (meta.filetype == "image") {
                      var input = document.getElementById("my-file");
                      input.click();
                      input.onchange = function () {
                        var file = input.files[0];
                        console.log(file);
                        var reader = new FileReader();
                        reader.onload = function (e) {
                          console.log("name", e.target.result);
                          const form_data = new FormData();
                          form_data.append("image", file);
                          return axios
                            .post("/admin/images", form_data)
                            .then((res) => {
                              callback(res.data.path, {
                                alt: file.name,
                              });
                            })
                            .catch((err) => console.log(""));
                        };
                        reader.readAsDataURL(file);
                      };
                    }
                  },
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Content Ar</TextStyle>
              {/* <RichEditor
                value={textArValue}
                onChange={handleChangeTextAr}
                onImageUpload={handleImageUpload}
                debounceDelay={0}
              /> */}
              <input
                id="my-file"
                type="file"
                name="my-file"
                style={{ display: "none" }}
                // onChange=""
              />
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textArValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "textcolor advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount image",
                  ],
                  toolbar:
                    "insertfile |image|undo redo | formatselect | bold italic forecolor  | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                  file_browser_callback_types: "image",
                  file_picker_callback: function (callback, value, meta) {
                    if (meta.filetype == "image") {
                      var input = document.getElementById("my-file");
                      input.click();
                      input.onchange = function () {
                        var file = input.files[0];
                        console.log(file);
                        var reader = new FileReader();
                        reader.onload = function (e) {
                          console.log("name", e.target.result);
                          const form_data = new FormData();
                          form_data.append("image", file);
                          return axios
                            .post("/admin/images", form_data)
                            .then((res) => {
                              callback(res.data.path, {
                                alt: file.name,
                              });
                            })
                            .catch((err) => console.log(""));
                        };
                        reader.readAsDataURL(file);
                      };
                    }
                  },
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Desktop Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={desktopImagePreview ? desktopImagePreview : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Mobile Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={mobileImagePreview ? mobileImagePreview : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone
                    onDrop={handleDropZoneMobile}
                    onDropAccepted={sendImageMobile}
                  >
                    {uploadedFilesMobile}
                    {fileUploadMobile}
                  </DropZone>
                </div>
              </div>
              <InlineError message={mobileImageError} />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout>
            <TextStyle variation="strong">Tags</TextStyle>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            />
          </FormLayout>
          <Checkbox
            label="Is Active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (!titleEnValue && !titleArValue) {
      setTitleError("Please enter a title");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      titleEnValue && form_data.append("title[en]", titleEnValue);
      titleArValue && form_data.append("title[ar]", titleArValue);
      textEnValue && form_data.append("text[en]", textEnValue);
      textArValue && form_data.append("text[ar]", textArValue);
      selected.forEach((selected, index) =>
        form_data.append(`tags[${index}]`, selected.value)
      );
      files.length && form_data.append("image", files[files.length - 1]);
      filesMobile.length &&
        form_data.append("image_mob", filesMobile[filesMobile.length - 1]);
      form_data.append("show", checkedShow ? 1 : 0);
      form_data.append("highlighted", 1);
      form_data.append("order", 1);
      form_data.append("category_id", selectedCategory);
      dateValue && form_data.append("date", dateValue);
      authorEnValue && form_data.append("author[en]", authorEnValue);
      authorArValue && form_data.append("author[ar]", authorArValue);
      descriptionEnValue &&
        form_data.append("description[en]", descriptionEnValue);
      descriptionArValue &&
        form_data.append("description[ar]", descriptionArValue);
      buttonTextEnValue && form_data.append("btn_name[en]", buttonTextEnValue);
      buttonTextArValue && form_data.append("btn_name[ar]", buttonTextArValue);
      buttonLinkValue && form_data.append("btn_link[en]", buttonLinkValue);
      buttonLinkArValue && form_data.append("btn_link[ar]", buttonLinkArValue);
      aliasValue && form_data.append("alias", aliasValue);

      axios
        .post("/admin/articles", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/pages");
          }
        })
        .catch((err) => console.log(""));
    }
  }
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
};
export default Add;
