import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Thumbnail,
  Tag,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import Cookies from "js-cookie";
import { ExportMinor } from "@shopify/polaris-icons";

const List = () => {
  const perPage = 50;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleEdit = (e) => {
    history.push(`/admin/therapists/items/${e.currentTarget.id}`);
  };
  const handleUp = (e) => {
    console.log(e.currentTarget.id.split("_")[0]);
    console.log(e.currentTarget.id.split("_")[1]);

    const bodyObj = {
      id: parseInt(e.currentTarget.id.split("_")[0]),
      order: parseInt(e.currentTarget.id.split("_")[1]) - 1,
      title: "eSIM ONE TIME FEE",
      text: "eSIM ONE TIME FEE",
    };
    axios
      .post("/admin/articles/update", bodyObj)
      .then((res) => {
        //getData();
      })
      .catch((err) => console.log(""));
  };
  const getData = () => {
    axios
      .get(
        `/admin/therapists?per_page=${perPage}&page=${page}&filter[${
          availability == "" ? "name" : availability
        }]=${queryValue}&sort=${sortState}`
      )
      .then((result) => {
        setIsLoading(false);
        !result.data.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.total / perPage));
        setItems(
          result.data.data.map((item, index) => [
            item.id,
            item?.name && item.name[Object.keys(item.name)[0]],
            item?.tags?.length ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {item.tags.map((item) => {
                  return (
                    <div style={{ marginLeft: "7px" }} key={item.id}>
                      <Tag>{item.name[Object.keys(item.name)[0]]}</Tag>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            ),
            // item?.languages?.length ? (
            //   <div style={{ display: "flex", flexDirection: "row" }}>
            //     {item.languages.map((item) => {
            //       return (
            //         <div style={{ marginLeft: "7px" }} key={item.id}>
            //           <Tag>{item.name[Object.keys(item.name)[0]]}</Tag>
            //         </div>
            //       );
            //     })}
            //   </div>
            // ) : (
            //   ""
            // ),

            <ButtonGroup>
              <Button onClick={handleEdit} id={item.id}>
                Edit
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => setIsLoading(false));
  };
  useEffect(() => {
    getData();
  }, [page, sortState, availability, queryValue]);

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Name", value: "name" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  const handleAddNew = () => {
    Cookies.set("houna-lead", "");
    history.push("/admin/therapists/items/new");
  };
  const handleExport = () => {
    window.open(
      `https://staging.houna.org/admin/therapists/download?token=c3433c5e711e32c542b5cc5g5a54db12`
    );
  };
  return (
    <Page
      fullWidth
      title="Therapists"
      primaryAction={{
        content: "Add Therapist",
        // url: "/admin/therapists/items/new",
        onAction: handleAddNew,
      }}
      breadcrumbs={[{ url: "/admin/therapists" }]}
      secondaryActions={[
        {
          content: "Export",
          icon: ExportMinor,
          onAction: handleExport,
        },
      ]}
    >
      <Card>
        {loadingMarkup}
        <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">Name</TextStyle>,
            <TextStyle variation="strong">Tags</TextStyle>,
            // <TextStyle variation="strong">Languages</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[true, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default List;
