import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import MultiSelect from "react-multi-select-component";
import history from "../../../Assets/Lib/history";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { linkId, centerId, doctorId } = useParams();
  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, titleEn: newValue });
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, titleAr: newValue });
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeDescriptionEn = (newValue) => {
    setItem({ ...item, descriptionEn: newValue });
  };
  const handleChangeDescriptionAr = (newValue) => {
    setItem({ ...item, descriptionAr: newValue });
  };
  const handleChangeWebsite = (newValue) => {
    setItem({ ...item, website: newValue });
  };
  const handleChangePhone = (newValue) => {
    setItem({ ...item, phone: newValue });
  };
  const [descriptionError, setDescriptionError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [imageError, setImageError] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="doctor">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const handleChangeShow = (newValue) => {
    setItem({ ...item, show: newValue });
  };
  const [item, setItem] = useState({
    titleEn: "",
    titleAr: "",
    descriptionEn: "",
    descriptionAr: "",
    show: "",
    phone: "",
  });

  useEffect(() => {
    axios
      .get(`admin/hotlines/area/center/${centerId}/doctors/${doctorId}`)
      .then((result) => {
        console.log(result?.data);
        setItem({
          titleEn: result?.data?.name?.en ? result.data?.name.en : "",
          titleAr: result?.data?.name?.ar ? result.data?.name.ar : "",
          phone: result?.data?.phone ? result.data?.phone : "",
          descriptionEn: result?.data?.working_hours?.en
            ? result.data.working_hours.en
            : "",
          descriptionAr: result?.data?.working_hours?.ar
            ? result.data.working_hours.ar
            : "",
          show: result.data?.is_active == 0 ? false : true,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Page
      title="Edit Doctor"
      breadcrumbs={[
        { url: `/admin/useful-links/${linkId}/centers/${centerId}/doctors` },
      ]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={item.titleEn}
                onChange={handleChangeTitleEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={item.titleAr}
                onChange={handleChangeTitleAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Working Hours En</TextStyle>
              <TextField
                value={item.descriptionEn}
                onChange={handleChangeDescriptionEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Working Hours Ar</TextStyle>
              <TextField
                value={item.descriptionAr}
                onChange={handleChangeDescriptionAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            {/* <FormLayout>
              <TextStyle variation="strong">Website</TextStyle>
              <TextField value={item.website} onChange={handleChangeWebsite} />
            </FormLayout> */}
            <FormLayout>
              <TextStyle variation="strong">Phone Number</TextStyle>
              <TextField value={item.phone} onChange={handleChangePhone} />
            </FormLayout>
          </FormLayout.Group>

          {/* <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail size="large" source={item.image ? item.image : ""} />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError} /> */}

          <Checkbox
            label="Is Active"
            checked={item.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/hotlines/area/center/${linkId}/doctors/delete`, {
                  id: doctorId,
                })
                .then((result) => {
                  history.push(
                    `/admin/useful-links/${linkId}/centers/${centerId}/doctors`
                  );
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    setIsSaving(true);
    const form_data = new FormData();
    form_data.append("name[en]", item.titleEn);
    form_data.append("name[ar]", item.titleAr);
    form_data.append("description[en]", item.descriptionEn);
    form_data.append("description[ar]", item.descriptionAr);
    files.length && form_data.append("image", files[files.length - 1]);
    form_data.append("is_active", item.show ? 1 : 0);

    axios
      .post(
        `admin/hotlines/area/center/${linkId}/doctors/${doctorId}`,
        form_data
      )
      .then((res) => {
        if (res.status == 200) {
          history.push(
            `/admin/useful-links/${linkId}/centers/${centerId}/doctors`
          );
        }
      })
      .catch((err) => console.log(""));
  }
};
export default Edit;
