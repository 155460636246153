import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  RadioButton,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();

  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, titleEn: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, titleAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeAddressEn = (newValue) => {
    setItem({ ...item, addressEn: newValue });
  };
  const handleChangeAddressAr = (newValue) => {
    setItem({ ...item, addressAr: newValue });
  };

  const handleChangeDate = (newValue) => {
    setItem({ ...item, date: newValue });
    setDateError("");
  };
  const handleChangeTime = (newValue) => {
    setItem({ ...item, time: newValue });
    setDateError("");
  };
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");

  const [seatsValue, setSeatsValue] = useState("");
  const handleChangeSeats = (newValue) => {
    setItem({ ...item, seats: newValue });
  };
  const handleChangeActualSeats = (newValue) => {
    setItem({ ...item, actualSeats: newValue });
  };
  const handleChangePrice = (newValue) => {
    setItem({ ...item, price: newValue });
  };
  const handleChangeSchedEn = (newValue) => {
    setItem({ ...item, schedEn: newValue });
    setSchedError("");
  };
  const handleChangeSchedAr = (newValue) => {
    setItem({ ...item, schedAr: newValue });
    setSchedError("");
  };
  const [schedError, setSchedError] = useState("");

  const handleChangeMembersDiscount = (newValue) => {
    setItem({ ...item, membersDiscount: newValue });
  };
  const handleChangeDiscountPrice = (newValue) => {
    setItem({ ...item, discountedPrice: newValue });
  };
  const handleChangeTextEn = (newValue) => {
    setItem({ ...item, summaryEn: newValue });
  };
  const handleChangeTextAr = (newValue) => {
    setItem({ ...item, summaryAr: newValue });
  };

  const handleChangeProfileEn = (newValue) => {
    setItem({ ...item, aboutEn: newValue });
  };
  const handleChangeProfileAr = (newValue) => {
    setItem({ ...item, aboutAr: newValue });
  };
  const handleChangeTermsEn = (newValue) => {
    setItem({ ...item, termsEn: newValue });
  };
  const handleChangeTermsAr = (newValue) => {
    setItem({ ...item, termsAr: newValue });
  };
  const [profileError, setProfileError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [optionsOrganizations, setOptionOrganizationss] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [optionsLanguages, setOptionsLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [optionsDisorders, setOptionsDisorders] = useState([]);
  const [selectedDisorders, setSelectedDisorders] = useState([]);
  const [optionsProfessions, setOptionsProfessions] = useState([]);
  const [selectedProfessions, setSelectedProfessions] = useState([]);
  const [optionsCountries, setOptionsCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [optionsSupportGroups, setOptionsSupportGroups] = useState([]);
  const [selectedSupportGroups, setSelectedSupportGroups] = useState([]);
  const [optionsContributions, setOptionsContributions] = useState([
    { label: "Content for Databank", value: "Content for Databank" },
    {
      label: "Run support group for Houna",
      value: "Run support group for Houna",
    },
    {
      label: "Content for social media posts",
      value: "Content for social media posts",
    },
    { label: "Instagram live sessions", value: "Instagram live sessions" },
    {
      label: "Provide free sessions to users",
      value: "Provide free sessions to users",
    },
    {
      label: "Provide discounted sessions to users",
      value: "Provide discounted sessions to users",
    },
    {
      label: "Review content for scientific and evidence-based accuracy",
      value: "Review content for scientific and evidence-based accuracy ",
    },
  ]);
  const [optionsLabels, setOptionsLabels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [optionsSpeakers, setOptionsSpeakers] = useState([]);
  const [selectedSpeakers, setSelectedSpeakers] = useState([]);
  const [speakersError, setSpeakersError] = useState("");
  const [categoriesError, setCategoriesError] = useState("");

  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = (newChecked) => {
    setItem({ ...item, show: newChecked });
  };
  const handleChangeYearlyPrice = (newChecked) => {
    setItem({ ...item, checkedYearlyPrice: newChecked });
  };

  // const [optionsCountries, setOptionsCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const handleSelectCountry = useCallback((newValue) => {
    setSelectedCountry(newValue);
    setCountryError("");
  }, []);
  const [optionsSpecialicities, setOptionsSpecialicities] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState("");
  const handleSelectSpecialicity = useCallback((newValue) => {
    setSelectedSpeciality(newValue);
    setSpecialtyError("");
  }, []);
  const [optionsAges, setOptionsAges] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);
  const [valueGender, setGender] = useState("");
  const handleChangeGender = useCallback((_checked, newValue) => {
    setGender(newValue);
    setGenderError("");
  }, []);

  const [ageError, setAgeError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [specialtyError, setSpecialtyError] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [languageError, setLanguageError] = useState("");
  const [visitValue, setVisitValue] = useState("");
  const handleChangeVisit = useCallback(
    (newValue) => setVisitValue(newValue),
    []
  );
  const [checkedOnline, setCheckedOnline] = useState(false);
  const handleChangeOnline = (newChecked) => {
    setItem({ ...item, isOnline: newChecked });
  };
  const handleChangeMmembersOnly = (newChecked) => {
    setItem({ ...item, isMembersOnly: newChecked, membersDiscount: "" });
  };
  const handleChangeIsFree = (newChecked) => {
    setItem({
      ...item,
      isFree: newChecked,
      price: "",
      discountedPrice: "",
      membersDiscount: "",
    });
  };
  const handleChangeEventType = (_checked, newValue) => {
    setItem({ ...item, eventType: newValue, schedEn: "", schedAr: "" });
  };

  const [item, setItem] = useState({
    titleEn: "",
    titleAr: "",
    image: "",
    addressEn: "",
    addressAr: "",
    date: "",
    seats: "",
    price: "",
    discountedPrice: "",
    membersDiscount: "",
    summaryEn: "",
    summaryAr: "",
    aboutEn: "",
    aboutAr: "",
    show: "",
    isOnline: "",
    isMembersOnly: "",
    termsEn: "",
    termsAr: "",
    time: "",
    isFree: "",
    checkedYearlyPrice: "",
    schedEn: "",
    schedAr: "",
    eventType: "",
    actualSeats: "",
  });
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    let responseAges = "";
    let responseLanguages = "";
    let responseCountries = "";
    let responseLabels = "";
    let responseCategories = "";
    let responseSpeakers = "";
    let responseSupportGroups = "";
    try {
      responseAges = await axios.get(`/admin/age-range`);
      responseAges?.data &&
        setOptionsAges(
          responseAges.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseLanguages = await axios.get(`/admin/languages`);
      responseLanguages?.data &&
        setOptionsLanguages(
          responseLanguages.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    // try {
    //   responseLabels = await axios.get(`/admin/labels`);
    //   responseLabels?.data?.data &&
    //     setOptionsLabels(
    //       responseLabels.data.data.map((item, index) => {
    //         return {
    //           label: item.name[Object.keys(item.name)[0]],
    //           value: String(item.id),
    //           key: String(item.id),
    //         };
    //       })
    //     );
    // } catch (error) {
    //   console.log(error);
    // }
    try {
      responseCategories = await axios.get(`/admin/categories`);
      responseCategories?.data?.data &&
        setOptionsCategories(
          responseCategories.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseSpeakers = await axios.get(`/admin/speakers`);
      responseSpeakers?.data?.data &&
        setOptionsSpeakers(
          responseSpeakers.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      const response = await axios.get(`/admin/events/${id}`);
      console.log("price=", response.data.yearly_price);
      response?.data &&
        setItem({
          date: response?.data?.date?.en
            ? response.data.date.en.split(" ")[0].split(" ")[0]
            : "",
          time: response?.data?.date?.en
            ? response.data.date.en.split(" ")[1]
            : "",
          image: response?.data?.image ? response.data.image : "",
          seats:
            response?.data?.available_seats != null
              ? String(response.data.available_seats)
              : "",
          actualSeats:
            response?.data?.actual_seats != null
              ? String(response.data.actual_seats)
              : "",
          price:
            response?.data?.price != null ? String(response.data.price) : "",
          discountedPrice:
            response?.data?.discounted_price != null
              ? String(response.data.discounted_price)
              : "",
          membersDiscount:
            response?.data?.members_discount != null
              ? String(response.data.members_discount)
              : "",
          titleEn: response?.data?.title?.en ? response.data.title.en : "",
          titleAr: response?.data?.title?.ar ? response.data.title.ar : "",
          addressEn: response?.data?.address?.en
            ? response.data.address.en
            : "",
          addressAr: response?.data?.address?.ar
            ? response.data.address.ar
            : "",

          summaryEn: response?.data?.brief?.en ? response.data.brief.en : "",
          summaryAr: response?.data?.brief?.ar ? response.data.brief.ar : "",
          schedEn: response?.data?.date?.en ? response.data.date.en : "",
          schedAr: response?.data?.date?.ar ? response.data.date.ar : "",
          termsEn: response?.data?.terms?.en ? response.data.terms.en : "",
          termsAr: response?.data?.terms?.ar ? response.data.terms.ar : "",
          aboutEn: response?.data?.about?.en ? response.data.about.en : "",
          aboutAr: response?.data?.about?.ar ? response.data.about.ar : "",
          show: response.data.show ? true : false,
          isOnline: response.data?.online ? true : false,
          isMembersOnly: parseInt(response.data?.members_only) ? true : false,
          isFree: !response.data?.price ? true : false,
          checkedYearlyPrice: response.data?.yearly_price ? true : false,
          eventType: response?.data?.multiple_times ? "recurring" : "onetime",
        });

      response.data.languages &&
        setSelectedLanguages(
          response.data.languages.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      response.data.age_range &&
        setSelectedAges(
          response.data.age_range.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      response.data.groups &&
        setSelectedSupportGroups(
          response.data.groups.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      response.data.speakers &&
        setSelectedSpeakers(
          response.data.speakers.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      // response.data.labels &&
      //   setSelectedLabels(
      //     response.data.labels.map((item, index) => {
      //       return {
      //         label: item.name.en,
      //         value: String(item.id),
      //         key: String(item.id),
      //       };
      //     })
      //   );
      response.data.categories &&
        setSelectedCategories(
          response.data.categories.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseSupportGroups = await axios.get(`/admin/support-groups`);
      responseSupportGroups?.data?.data &&
        setOptionsSupportGroups(
          responseSupportGroups.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }

  return (
    <Page title="Edit Event" breadcrumbs={[{ url: "/admin/events/items" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField value={item.titleEn} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField value={item.titleAr} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>

          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={item.image ? item.image : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Address En</TextStyle>
              <TextField
                value={item.addressEn}
                onChange={handleChangeAddressEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Address Ar</TextStyle>
              <TextField
                value={item.addressAr}
                onChange={handleChangeAddressAr}
              />
            </FormLayout>
          </FormLayout.Group>
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Degree En</TextStyle>
              <TextField
                value={degreeEnValue}
                onChange={handleChangeDegreeEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Degree Ar</TextStyle>
              <TextField
                value={degreeArValue}
                onChange={handleChangeDegreeAr}
              />
            </FormLayout>
          </FormLayout.Group> */}

          <TextStyle variation="strong">Event Type</TextStyle>
          <Stack horizontal>
            <RadioButton
              label="One Time Event"
              checked={item.eventType === "onetime"}
              id="onetime"
              onChange={handleChangeEventType}
            />
            <RadioButton
              label="Recurring Event"
              id="recurring"
              checked={item.eventType === "recurring"}
              onChange={handleChangeEventType}
            />
          </Stack>
          {item.eventType === "onetime" ? (
            <FormLayout.Group>
              <FormLayout>
                <TextStyle>Date</TextStyle>
                <TextField
                  value={item.date}
                  onChange={handleChangeDate}
                  type="date"
                  error={dateError}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle>Time</TextStyle>
                <TextField
                  value={item.time}
                  onChange={handleChangeTime}
                  type="time"
                  error={timeError}
                />
              </FormLayout>
            </FormLayout.Group>
          ) : (
            <FormLayout>
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle>Schedule En</TextStyle>
                  <TextField
                    value={item.schedEn}
                    onChange={handleChangeSchedEn}
                    // type="date"
                  />
                </FormLayout>

                <FormLayout>
                  <TextStyle>Schedule Ar</TextStyle>
                  <TextField
                    value={item.schedAr}
                    onChange={handleChangeSchedAr}
                    // type="time"
                    // error={timeError}
                  />
                </FormLayout>
              </FormLayout.Group>
              <InlineError message={schedError} />
            </FormLayout>
          )}

          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Date</TextStyle>
              <TextField
                value={item.date}
                onChange={handleChangeDate}
                type="date"
                error={dateError}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Time</TextStyle>
              <TextField
                value={item.time}
                onChange={handleChangeTime}
                type="time"
                error={timeError}
              />
            </FormLayout>
          </FormLayout.Group> */}
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Price</TextStyle>
              <TextField
                value={item.price}
                onChange={handleChangePrice}
                type="number"
                disabled={item.isFree && true}
                placeholder="Enter a price here"
                connectedLeft={
                  <Checkbox
                    label="Is Free"
                    checked={item.isFree}
                    onChange={handleChangeIsFree}
                  />
                }
              />
              <Checkbox
                label="Yearly Price"
                checked={item.checkedYearlyPrice}
                onChange={handleChangeYearlyPrice}
              />
            </FormLayout>
            {/* <FormLayout>
              <TextStyle variation="strong">Discounted Price</TextStyle>
              <TextField
                value={item.discountedPrice}
                onChange={handleChangeDiscountPrice}
                type="number"
              />
            </FormLayout> */}
          </FormLayout.Group>
          <Checkbox
            label="Members Only"
            checked={item.isMembersOnly}
            onChange={handleChangeMmembersOnly}
          />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Members Discount</TextStyle>
              <TextField
                value={item.membersDiscount}
                onChange={handleChangeMembersDiscount}
                type="number"
                max={100}
                prefix="%"
                helpText="Enter a number between 0 and 100"
                disabled={item.isFree || (item.isMembersOnly && true)}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Available Seats</TextStyle>
              <TextField
                value={item.seats}
                onChange={handleChangeSeats}
                type="number"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Actual Seats</TextStyle>
              <TextField
                value={item.actualSeats}
                onChange={handleChangeActualSeats}
                type="number"
              />
            </FormLayout>
          </FormLayout.Group>
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Years of experience En</TextStyle>
              <TextField
                value={yearsOfExperienceEnValue}
                onChange={handleChangeYearsOfExperienceEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Years of experience Ar</TextStyle>
              <TextField
                value={yearsOfExperienceArValue}
                onChange={handleChangeYearsOfExperienceAr}
              />
            </FormLayout>
          </FormLayout.Group> */}
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Phone Number En</TextStyle>
              <TextField
                value={phoneNumberEnValue}
                onChange={handleChangePhoneNumberEn}
              ></TextField>
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Phone Number Ar</TextStyle>
              <TextField
                value={phoneNumberArValue}
                onChange={handleChangePhoneNumberAr}
              ></TextField>
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Email</TextStyle>
          <TextField
            value={emailValue}
            onChange={handleChangeEmail}
          ></TextField> */}

          {/* <TextStyle variation="strong">Tags</TextStyle>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            /> */}

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Summary En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // initialValue="<p>This is the initial content of the editor</p>"
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                value={item.summaryEn}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Summary Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.summaryAr}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={summaryError} />
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Services En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={servicesEnValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeServicesEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Services Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={servicesArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeServicesAr}
              />
            </FormLayout>
          </FormLayout.Group> */}

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Profile En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={profileEnValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeProfileEn}
                value={item.aboutEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Profile Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={profileArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeProfileAr}
                value={item.aboutAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={profileError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Terms En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={profileEnValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTermsEn}
                value={item.termsEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Terms Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={profileArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTermsAr}
                value={item.termsAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout>
            {/* <Select
              options={optionsCountries}
              onChange={handleSelectCountry}
              value={selectedCountry}
              placeholder="Please select a country"
            /> */}
            {/* <TextStyle variation="strong">Website</TextStyle>
            <TextField
              value={visitValue}
              onChange={handleChangeVisit}
              autoComplete="off"
            /> */}
            {/* <TextStyle variation="strong">Specialty</TextStyle>
            <Select
              options={optionsSpecialicities}
              value={selectedSpeciality}
              onChange={handleSelectSpecialicity}
              placeholder="Please select a specialty"
            />
            <InlineError message={specialtyError} /> */}

            {/* <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Specialties En</TextStyle>

                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  // value={specialtiesEnValue}
                  init={{
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onEditorChange={handleChangeSpecialtyEn}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Specialties Ar</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  // value={specialtiesEnValue}
                  init={{
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onEditorChange={handleChangeSpecialtyAr}
                />
              </FormLayout>
            </FormLayout.Group> */}

            {/* <TextStyle variation="strong">Organizations</TextStyle>
            <MultiSelect
              options={optionsOrganizations}
              value={selectedOrganizations}
              onChange={setSelectedOrganizations}
              labelledBy="Select"
            />
            <TextStyle variation="strong">Disorders</TextStyle>
            <MultiSelect
              options={optionsDisorders}
              value={selectedDisorders}
              onChange={setSelectedDisorders}
              labelledBy="Select"
            /> */}
            {/* <TextStyle variation="strong">Contributions</TextStyle>
            <MultiSelect
              options={optionsContributions}
              value={selectedContributions}
              onChange={setSelectedContributions}
              labelledBy="Please select"
            />
            <TextStyle variation="strong">Countries</TextStyle>
            <MultiSelect
              options={optionsCountries}
              value={selectedCountries}
              onChange={setSelectedCountries}
              labelledBy="Please select a country"
            /> */}
            {/* //a */}
            {/* <TextStyle variation="strong">Gender</TextStyle>
            <Stack horizontal>
              <RadioButton
                label="Male"
                checked={valueGender === "male"}
                id="male"
                name="male"
                onChange={handleChangeGender}
              />
              <RadioButton
                label="Female"
                id="female"
                name="female"
                checked={valueGender === "female"}
                onChange={handleChangeGender}
              />
            </Stack>
            <InlineError message={genderError} /> */}
            <FormLayout.Group>
              {/* <FormLayout>
                <TextStyle variation="strong">Countries</TextStyle>
                <MultiSelect
                  options={optionsCountries}
                  value={selectedCountries}
                  onChange={setSelectedCountries}
                  labelledBy="Please select a country"
                />
              </FormLayout> */}
              <FormLayout>
                <TextStyle variation="strong">Age</TextStyle>
                <MultiSelect
                  options={optionsAges}
                  value={selectedAges}
                  onChange={setSelectedAges}
                  labelledBy="Please select age"
                />
              </FormLayout>
            </FormLayout.Group>

            <InlineError message={ageError} />
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Categories</TextStyle>
                <MultiSelect
                  options={optionsCategories}
                  value={selectedCategories}
                  onChange={setSelectedCategories}
                  labelledBy="Please select a category"
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Languages</TextStyle>
                <MultiSelect
                  options={optionsLanguages}
                  value={selectedLanguages}
                  onChange={setSelectedLanguages}
                  labelledBy="Please select a language"
                />
              </FormLayout>
            </FormLayout.Group>
            <InlineError message={categoriesError} />
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Speakers</TextStyle>
                <MultiSelect
                  options={optionsSpeakers}
                  value={selectedSpeakers}
                  onChange={setSelectedSpeakers}
                  labelledBy="Please select a speaker"
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Support Groups</TextStyle>
                <MultiSelect
                  options={optionsSupportGroups}
                  value={selectedSupportGroups}
                  onChange={setSelectedSupportGroups}
                  labelledBy="Please select a support group"
                />
              </FormLayout>
            </FormLayout.Group>
            <InlineError message={speakersError} />
          </FormLayout>
          <Checkbox
            label="Is Online"
            checked={item.isOnline}
            onChange={handleChangeOnline}
          />

          <Checkbox
            label="Is Active"
            checked={item.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/events/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/events/items`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (
      (!item.titleEn && !item.titleAr) ||
      (item.eventType === "onetime" && !item.date) ||
      (item.eventType === "onetime" && !item.time) ||
      (item.eventType === "recurring" && !item.schedEn && !item.schedAr)
    ) {
      !item.titleEn && !item.titleAr && setTitleError("Please enter a title");
      !item.date && setDateError("Please select a date");
      !item.time && setTimeError("Please select a time");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      item.eventType === "onetime" &&
        item.date &&
        form_data.append("date[en]", item.date + " " + item.time);
      item.eventType === "onetime" &&
        item.date &&
        form_data.append("date[ar]", item.date + " " + item.time);
      form_data.append("multiple_times", item.eventType === "onetime" ? 0 : 1);
      item.eventType === "recurring" &&
        item.schedEn &&
        form_data.append("date[en]", item.schedEn);
      item.eventType === "recurring" &&
        item.schedAr &&
        form_data.append("date[ar]", item.schedAr);
      item.titleEn && form_data.append("title[en]", item.titleEn);
      item.titleAr && form_data.append("title[ar]", item.titleAr);
      form_data.append("brief[en]", item.summaryEn);
      form_data.append("brief[ar]", item.summaryAr);
      form_data.append("about[en]", item.aboutEn);
      form_data.append("about[ar]", item.aboutAr);
      form_data.append("members_only", item.isMembersOnly ? 1 : 0);
      selectedLanguages.forEach((selected, index) =>
        form_data.append(`languages[${index}]`, selected.value)
      );
      selectedCountries.forEach((selected, index) =>
        form_data.append(`countries[${index}]`, selected.value)
      );
      selectedAges.forEach((selected, index) =>
        form_data.append(`age_range[${index}]`, selected.value)
      );
      selectedSupportGroups.forEach((selected, index) =>
        form_data.append(`groups[${index}]`, selected.value)
      );
      selectedCategories.forEach((selected, index) =>
        form_data.append(`categories[${index}]`, selected.value)
      );
      selectedSpeakers.forEach((selected, index) =>
        form_data.append(`speakers[${index}]`, selected.value)
      );
      files[files.length - 1] &&
        form_data.append("image", files[files.length - 1]);
      form_data.append("online", item.isOnline ? 1 : 0);
      form_data.append("show", item.show ? 1 : 0);
      form_data.append("highlighted", 1);
      form_data.append("order", 1);
      form_data.append("price", item.price);
      form_data.append("discounted_price", item.discountedPrice);
      item.seats && form_data.append("available_seats", item.seats);
      form_data.append("members_discount", item.membersDiscount);
      item.titleEn && form_data.append("alias", convertToSlug(item.titleEn));
      form_data.append("address[en]", item.addressEn);
      form_data.append("address[ar]", item.addressAr);
      form_data.append("terms[en]", item.termsEn);
      form_data.append("terms[ar]", item.termsAr);
      form_data.append("id", id);
      form_data.append("yearly_price", item.checkedYearlyPrice ? 1 : 0);
      form_data.append("actual_seats", item.actualSeats);
      axios
        .post("/admin/events/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/events/items");
          }
        })
        .catch((err) => console.log(""));
    }
  }
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
  function findArrayElementByName(array, value) {
    return array.find((element, index) => {
      return element.name.en.toLowerCase() == value.toLowerCase();
    });
  }
  function findArrayProfessionByName(array, value) {
    return array.find((element, index) => {
      return element.value.en.toLowerCase() == value.toLowerCase();
    });
  }
  function average(a, b) {
    // force the input as numbers *1
    return (a * 1 + b * 1) / 2;
  }
  function isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0;
  }
  function isUrl(s) {
    var regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }
};
export default Edit;
