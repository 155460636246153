import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";

const Add = () => {
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [occupationEnValue, setOccupationEnValue] = useState("");
  const handleChangeOccupationEn = useCallback((newValue) => {
    setOccupationEnValue(newValue);
  }, []);
  const [occupationArValue, setOccupationArValue] = useState("");
  const handleChangeOccupationAr = useCallback((newValue) => {
    setOccupationArValue(newValue);
  }, []);
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = (newValue) => {
    setTextEnValue(newValue);
    setTextError("");
  };
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = (newValue) => {
    setTextArValue(newValue);
    setTextError("");
  };
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );
  const sendImage = (files) => {
    setDesktopImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setDesktopImageError("");
  };
  const [isPresident, setIsPresident] = useState(false);
  const handleChangeIsPresident = useCallback(
    (newChecked) => setIsPresident(newChecked),
    []
  );
  const [isManager, setIsManager] = useState(false);
  const handleChangeIsManager = useCallback(
    (newChecked) => setIsManager(newChecked),
    []
  );
  return (
    <Page title="Add Member" breadcrumbs={[{ url: "/admin/members" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField value={titleEnValue} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField value={titleArValue} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Occupation En</TextStyle>
              <TextField
                value={occupationEnValue}
                onChange={handleChangeOccupationEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Occupation Ar</TextStyle>
              <TextField
                value={occupationArValue}
                onChange={handleChangeOccupationAr}
              />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Text En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textEnValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Text Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textArValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={desktopImagePreview ? desktopImagePreview : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>
          <Checkbox
            label="Is President"
            checked={isPresident}
            onChange={handleChangeIsPresident}
          />
          <Checkbox
            label="Is Manager"
            checked={isManager}
            onChange={handleChangeIsManager}
          />
          <Checkbox
            label="Is Active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      (!titleEnValue && !titleArValue) ||
      (!textArValue && !textEnValue) ||
      !files.length
    ) {
      !titleEnValue && !titleArValue && setTitleError("Please enter a name");
      !textArValue && !textEnValue && setTextError("Please enter a text");
      !files.length && setDesktopImageError("Please upload an image");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      titleEnValue && form_data.append("name[en]", titleEnValue);
      titleArValue && form_data.append("name[ar]", titleArValue);
      occupationEnValue &&
        form_data.append("occupation[en]", occupationEnValue);
      occupationArValue &&
        form_data.append("occupation[ar]", occupationArValue);
      textEnValue && form_data.append("text[en]", textEnValue);
      textArValue && form_data.append("text[ar]", textArValue);
      files.length && form_data.append("image", files[files.length - 1]);
      form_data.append("order", 1);
      form_data.append("is_manager", isManager ? 1 : 0);
      form_data.append("is_president", isPresident ? 1 : 0);
      form_data.append("show", checkedShow ? 1 : 0);

      axios
        .post("/admin/members", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/members");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Add;
