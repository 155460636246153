import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../../Assets/Lib/history";
import List from "./List";
import Add from "./Add";
import NavigationLayout from "../../../Components/NavigationLayout";
import Edit from "./Edit";
import Doctors from "../Doctors/Main";

const MainEdit = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/admin/useful-links/:linkId/centers/:centerId"
          component={Edit}
        />
        <Route
          path="/admin/useful-links/:linkId/centers/:centerId/doctors"
          component={Doctors}
        />
      </Switch>
    </Router>
  );
};
export default MainEdit;
