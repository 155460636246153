import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import DateTimePicker from "react-datetime-picker";

const Add = () => {
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = useCallback((newValue) => {
    setTextEnValue(newValue);
    setTextError("");
  }, []);
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = useCallback((newValue) => {
    setTextArValue(newValue);
    setTextError("");
  }, []);
  const [textError, setTextError] = useState("");
  const [streamLinkValue, setStreamLinkValue] = useState("");
  const handleChangeStreamLink = useCallback((newValue) => {
    setStreamLinkValue(newValue);
  }, []);
  const [isSaving, setIsSaving] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [imageError, setImageError] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [checkedMembersOnly, setCheckedMembersOnly] = useState(false);
  const handleChangeMembersOnly = useCallback(
    (newChecked) => setCheckedMembersOnly(newChecked),
    []
  );
  const [startTimeValue, setStartTimeValue] = useState("");
  const handleChangeStartTime = useCallback(
    (newValue) => setStartTimeValue(newValue),
    []
  );
  const [startDateValue, setStartDateValue] = useState("");
  const handleChangeStartDate = useCallback(
    (newValue) => setStartDateValue(newValue),
    []
  );
  useEffect(() => {
    axios
      .get(`admin/tags`)
      .then((result) => {
        setOptions(
          result.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: item.id,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page title="Add Broadcast" breadcrumbs={[{ url: "/admin/broadcasts" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={titleEnValue}
                onChange={handleChangeTitleEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={titleArValue}
                onChange={handleChangeTitleAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Text En</TextStyle>
              <TextField
                value={textEnValue}
                onChange={handleChangeTextEn}
                multiline={4}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Text Ar</TextStyle>
              <TextField
                value={textArValue}
                onChange={handleChangeTextAr}
                multiline={4}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />

          <TextStyle variation="strong">Stream Link</TextStyle>
          <TextField
            value={streamLinkValue}
            onChange={handleChangeStreamLink}
          />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Start Date</TextStyle>
              <TextField
                value={startDateValue}
                onChange={handleChangeStartDate}
                type="date"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Start Time</TextStyle>
              <TextField
                value={startTimeValue}
                onChange={handleChangeStartTime}
                type="time"
              />
            </FormLayout>
          </FormLayout.Group>

          {/* <DateTimePicker
            onChange={handleChangeStartTime}
            value={startTimeValue}
          /> */}
          <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError} />

          <Checkbox
            label="Members Only"
            checked={checkedMembersOnly}
            onChange={handleChangeMembersOnly}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      (!titleEnValue && !titleArValue) ||
      (!textEnValue && !textArValue) ||
      !files.length
    ) {
      !titleEnValue && !titleArValue && setTitleError("Please enter a title");
      !textEnValue && !textArValue && setTextError("Please enter a text");
      !files.length && setImageError("Please upload an image");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("order", 1);
      form_data.append("show", checkedMembersOnly ? 1 : 0);
      form_data.append("highlighted", 1);
      titleEnValue && form_data.append("name[en]", titleEnValue);
      titleArValue && form_data.append("name[ar]", titleArValue);
      textEnValue && form_data.append("content[en]", textEnValue);
      textArValue && form_data.append("content[ar]", textArValue);
      files.length && form_data.append("image", files[files.length - 1]);
      startTimeValue &&
        startDateValue &&
        form_data.append("start_time", startDateValue + " " + startTimeValue);
      streamLinkValue && form_data.append("stream", streamLinkValue);
      form_data.append("member_only", checkedMembersOnly ? 1 : 0);

      axios
        .post("admin/broadcasts", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/broadcasts");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Add;
