import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Lib/history";
import NavigationLayout from "../../Components/NavigationLayout";
import GridView from "./Grid";
import Items from "./Items/Main";
import Specialties from "./Specialties/Main";
import Organizations from "./Organizations/Main";
import Professions from "./Professions/Main";
import Languages from "./Languages/Main";

const Therapists = () => {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/therapists" component={GridView} />
          <Route path="/admin/therapists/specialties" component={Specialties} />
          <Route path="/admin/therapists/items" component={Items} />
          <Route
            path="/admin/therapists/organizations"
            component={Organizations}
          />
          <Route path="/admin/therapists/professions" component={Professions} />
          <Route path="/admin/therapists/languages" component={Languages} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
};
export default Therapists;
