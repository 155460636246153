import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Thumbnail,
  Badge,
} from "@shopify/polaris";
import axios from "axios";
import history from "../../../Assets/Lib/history";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import Cookies from "js-cookie";
import { ExportMinor } from "@shopify/polaris-icons";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const Partnerships = () => {
  var PromiseAll = require("promises-all");
  const perPage = 50;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleEdit = (e) => {
    history.push(`/admin/pages/${e.currentTarget.id}`);
  };
  const handleUp = (e) => {
    console.log(e.currentTarget.id.split("_")[0]);
    console.log(e.currentTarget.id.split("_")[1]);

    const bodyObj = {
      id: parseInt(e.currentTarget.id.split("_")[0]),
      order: parseInt(e.currentTarget.id.split("_")[1]) - 1,
      title: "eSIM ONE TIME FEE",
      text: "eSIM ONE TIME FEE",
    };
    axios
      .post("/admin/articles/update", bodyObj)
      .then((res) => {
        //getData();
      })
      .catch((err) => console.log(""));
  };
  const onRowClick = (e) => {
    history.push(`/admin/leads/therapists/${e.currentTarget.id}`);
  };
  const handleImport = (e) => {
    Cookies.set("houna-lead", e.currentTarget.id);
    history.push(`/admin/therapists/items/new`);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = "myfile";
  const handleDownload = (e) => {
    const ws = XLSX.utils.json_to_sheet([JSON.parse(e.currentTarget.id)]);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, JSON.parse(e.currentTarget.id).name + fileExtension);
  };
  const [leads, setLeads] = useState("");
  useEffect(() => {
    getData();
  }, [page, sortState, availability, queryValue]);
  const getData = () => {
    axios
      .get(`https://partners.houna.org/api/partnership/`, {
        headers: {
          Authorization: "c0299c8e794e59c061b5cc7f7a43db19",
        },
      })
      .then((result) => {
        setIsLoading(false);
        !result.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
        console.log("result.data=", findElementByID(result.data, 1));
        setLeads(result.data);
        setItems(
          result.data.map((item, index) => [
            item?.id && (
              <div className="rowClick" onClick={onRowClick} id={item.id}>
                {item.id}
              </div>
            ),
            item?.name && (
              <div className="rowClick" onClick={onRowClick} id={item.id}>
                {item.name}
              </div>
            ),
            item?.country && (
              <div className="rowClick" onClick={onRowClick} id={item.id}>
                {item.country}
              </div>
            ),
            item?.type && (
              <div className="rowClick" onClick={onRowClick} id={item.id}>
                {item.type}
              </div>
            ),
            item?.email && (
              <div className="rowClick" onClick={onRowClick} id={item.id}>
                {item.email}
              </div>
            ),
            item?.phone_number && (
              <div className="rowClick" onClick={onRowClick} id={item.id}>
                {item.phone_number}
              </div>
            ),
            item?.office_address && (
              <div className="rowClick" onClick={onRowClick} id={item.id}>
                {item.office_address}
              </div>
            ),
            item?.min_age != null && (
              <div className="rowClick" onClick={onRowClick} id={item.id}>
                {item.min_age}
              </div>
            ),
            item?.max_age != null && (
              <div className="rowClick" onClick={onRowClick} id={item.id}>
                {item.max_age}
              </div>
            ),
            <ButtonGroup>
              <Button
                id={JSON.stringify(findElementByID(result.data, item.id))}
                onClick={(e) => handleImport(e)}
              >
                Import
              </Button>
              <Button
                primary
                id={JSON.stringify(findElementByID(result.data, item.id))}
                onClick={(e) => handleDownload(e)}
              >
                Download
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => setIsLoading(false));
  };
  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Title", value: "title" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  const handleExport = () => {
    window.open(
      `https://partners.houna.org/api/partnership/download?token=c3433c5e711e32c542b5cc5g5a54db12`
    );
  };
  return (
    <Page
      fullWidth
      title="Therapists"
      breadcrumbs={[{ url: "/admin/leads" }]}
      secondaryActions={[
        {
          content: "Export",
          icon: ExportMinor,
          onAction: handleExport,
        },
      ]}
    >
      <Card>
        {loadingMarkup}
        {/* <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section> */}
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">Name</TextStyle>,
            <TextStyle variation="strong">Country</TextStyle>,
            <TextStyle variation="strong">Type</TextStyle>,
            <TextStyle variation="strong">Email</TextStyle>,
            <TextStyle variation="strong">Phone Number</TextStyle>,
            <TextStyle variation="strong">Office Address</TextStyle>,
            <TextStyle variation="strong">Minimum Age</TextStyle>,
            <TextStyle variation="strong">Maximum Age</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function findElementByID(array, id) {
    return array.find((element) => {
      return element.id === id;
    });
  }
};

export default Partnerships;
