import React from "react";
import Grid from "@material-ui/core/Grid";
import { Page, Icon, Card, TextStyle } from "@shopify/polaris";
import {
  ListMajor,
  CustomersMajor,
  BankMajor,
  JobsMajor,
  LanguageMinor,
} from "@shopify/polaris-icons";
import history from "../../Assets/Lib/history";

const GridView = () => {
  return (
    <Page>
      <Card>
        <div style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="gridItem"
                onMouseDown={(e) =>
                  history.push("/admin/therapists/specialties")
                }
              >
                <div className="iconDiv">
                  <Icon
                    source={ListMajor}
                    style={{ margin: "0 5px" }}
                    color="subdued"
                  ></Icon>
                </div>
                <div style={{ flexDirection: "column", marginLeft: "10" }}>
                  <div className="titleDev">
                    <TextStyle variation="strong">Specialties</TextStyle>
                  </div>
                  <div className="description">
                    <TextStyle></TextStyle>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="gridItem"
                onMouseDown={(e) => history.push("/admin/therapists/languages")}
              >
                <div className="iconDiv">
                  <Icon
                    source={LanguageMinor}
                    style={{ margin: "0 5px" }}
                    color="subdued"
                  ></Icon>
                </div>
                <div style={{ flexDirection: "column", marginLeft: "10" }}>
                  <div className="titleDev">
                    <TextStyle variation="strong">Languages</TextStyle>
                  </div>
                  <div className="description">
                    <TextStyle></TextStyle>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="gridItem"
                onMouseDown={(e) => history.push("/admin/therapists/items")}
              >
                <div className="iconDiv">
                  <Icon
                    source={CustomersMajor}
                    style={{ margin: "0 5px" }}
                    color="subdued"
                  ></Icon>
                </div>
                <div style={{ flexDirection: "column", marginLeft: "10" }}>
                  <div className="titleDev">
                    <TextStyle variation="strong">Therapists</TextStyle>
                  </div>
                  <div className="description">
                    <TextStyle></TextStyle>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="gridItem"
                onMouseDown={(e) =>
                  history.push("/admin/therapists/organizations")
                }
              >
                <div className="iconDiv">
                  <Icon
                    source={BankMajor}
                    style={{ margin: "0 5px" }}
                    color="subdued"
                  ></Icon>
                </div>
                <div style={{ flexDirection: "column", marginLeft: "10" }}>
                  <div className="titleDev">
                    <TextStyle variation="strong">Organizations</TextStyle>
                  </div>
                  <div className="description">
                    <TextStyle></TextStyle>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
    </Page>
  );
};
export default GridView;
