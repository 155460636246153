import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  RadioButton,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";
import Cookies from "js-cookie";

const Add = () => {
  var PromiseAll = require("promises-all");
  const [titleEnValue, setTitleEnValue] = useState(
    Cookies.get("houna-organization") &&
      JSON.parse(Cookies.get("houna-organization"))?.name
      ? JSON.parse(Cookies.get("houna-organization")).name
      : ""
  );
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState(
    Cookies.get("houna-organization") &&
      JSON.parse(Cookies.get("houna-organization"))?.name_ar
      ? JSON.parse(Cookies.get("houna-organization")).name_ar
      : ""
  );
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [emailValue, setEmailValue] = useState(
    Cookies.get("houna-organization") &&
      JSON.parse(Cookies.get("houna-organization"))?.email
      ? JSON.parse(Cookies.get("houna-organization")).email
      : ""
  );
  const handleChangeEmail = useCallback((newValue) => {
    setEmailValue(newValue);
  }, []);
  const [phoneNumberEnValue, setPhoneNumberEnValue] = useState(
    Cookies.get("houna-organization") &&
      JSON.parse(Cookies.get("houna-organization"))?.phone_number
      ? JSON.parse(Cookies.get("houna-organization")).phone_number
      : ""
  );
  const handleChangePhoneNumberEn = useCallback((newValue) => {
    setPhoneNumberEnValue(newValue);
  }, []);
  const [phoneNumberArValue, setPhoneNumberArValue] = useState("");
  const handleChangePhoneNumberAr = useCallback((newValue) => {
    setPhoneNumberArValue(newValue);
  }, []);
  const [addressEnValue, setAddressEnValue] = useState(
    Cookies.get("houna-organization") &&
      JSON.parse(Cookies.get("houna-organization"))?.office_address
      ? JSON.parse(Cookies.get("houna-organization")).office_address
      : ""
  );
  const handleChangeAddressEn = useCallback((newValue) => {
    setAddressEnValue(newValue);
  }, []);
  const [addressArValue, setAddressArValue] = useState(
    Cookies.get("houna-organization") &&
      JSON.parse(Cookies.get("houna-organization"))?.office_address_ar
      ? JSON.parse(Cookies.get("houna-organization")).office_address_ar
      : ""
  );
  const handleChangeAddressAr = useCallback((newValue) => {
    setAddressArValue(newValue);
  }, []);
  const [textEnValue, setTextEnValue] = useState(
    Cookies.get("houna-organization") &&
      JSON.parse(Cookies.get("houna-organization"))?.biography
      ? JSON.parse(Cookies.get("houna-organization")).biography
      : ""
  );
  const handleChangeTextEn = useCallback((newValue) => {
    setTextEnValue(newValue);
    setTextError("");
  }, []);
  const [textArValue, setTextArValue] = useState(
    Cookies.get("houna-organization") &&
      JSON.parse(Cookies.get("houna-organization"))?.biography_ar
      ? JSON.parse(Cookies.get("houna-organization")).biography_ar
      : ""
  );
  const handleChangeTextAr = useCallback((newValue) => {
    setTextArValue(newValue);
    setTextError("");
  }, []);
  const [textError, setTextError] = useState("");
  const [profileEnValue, setProfileEnValue] = useState(
    Cookies.get("houna-organization") &&
      JSON.parse(Cookies.get("houna-organization"))?.services
      ? JSON.parse(Cookies.get("houna-organization")).services
      : ""
  );
  const handleChangeProfileEn = (newValue) => {
    setProfileEnValue(newValue);
    setProfileError("");
  };
  const [profileArValue, setProfileArValue] = useState(
    Cookies.get("houna-organization") &&
      JSON.parse(Cookies.get("houna-organization"))?.services_ar
      ? JSON.parse(Cookies.get("houna-organization")).services_ar
      : ""
  );
  const handleChangeProfileAr = (newValue) => {
    setProfileArValue(newValue);
    setProfileError("");
  };
  const [profileError, setProfileError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState(
    Cookies.get("houna-lead") && JSON.parse(Cookies.get("houna-lead"))?.image
      ? JSON.parse(Cookies.get("houna-lead")).image
      : ""
  );
  const [desktopImageError, setDesktopImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setDesktopImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setDesktopImageError("");
  };
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );

  const [summaryError, setSummaryError] = useState("");
  const [visitValue, setVisitValue] = useState("");
  const handleChangeVisit = useCallback(
    (newValue) => setVisitValue(newValue),
    []
  );
  const [optionsCountries, setOptionsCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [checkedOnline, setCheckedOnline] = useState(false);
  const handleChangeOnline = useCallback(
    (newChecked) => setCheckedOnline(newChecked),
    []
  );
  const [checkedRecommended, setCheckedRecommended] = useState(false);
  const handleChangeRecommended = useCallback(
    (newChecked) => setCheckedRecommended(newChecked),
    []
  );
  const [youtubeValue, setYoutubeValue] = useState("");
  const handleChangeYoutube = (newValue) => {
    setYoutubeValue(newValue);
  };
  const [printerestValue, setPrinterestValue] = useState("");
  const handleChangePrinterest = (newValue) => {
    setPrinterestValue(newValue);
  };
  const [instagramValue, setInstagramValue] = useState("");
  const handleChangeInstagram = (newValue) => {
    setInstagramValue(newValue);
  };
  const [facebookValue, setFacebookValue] = useState("");
  const handleChangeFacebook = (newValue) => {
    setFacebookValue(newValue);
  };
  const [twitterValue, setTwitterValue] = useState("");
  const handleChangeTwitter = (newValue) => {
    setTwitterValue(newValue);
  };
  const [appStoreValue, setAppStoreValue] = useState("");
  const handleChangeAppStore = (newValue) => {
    setAppStoreValue(newValue);
  };
  const [playStoreValue, setPlayStoreValue] = useState("");
  const handleChangePlayStore = (newValue) => {
    setPlayStoreValue(newValue);
  };
  useEffect(() => {
    Cookies.get("houna-organization") &&
      console.log(JSON.parse(Cookies.get("houna-organization")).country);
    axios
      .get(`/admin/countries`)
      .then((response) => {
        setIsSaving(false);
        response?.data &&
          setOptionsCountries(
            response.data.map((item, index) => {
              return {
                label: item.name[Object.keys(item.name)[0]],
                value: String(item.id),
                key: String(item.id),
              };
            })
          );

        if (Cookies.get("houna-organization")) {
          JSON.parse(Cookies.get("houna-organization"))?.country?.split(",")
            .length &&
            setSelectedCountries(
              JSON.parse(Cookies.get("houna-organization"))
                .country.split(",")
                .reduce((result, item, i) => {
                  if (findArrayElementByName(response.data, item)) {
                    result.push({
                      label: item,
                      value: String(
                        findArrayElementByName(response.data, item).id
                      ),
                    });
                  }
                  return result;
                }, [])
            );
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Add Organization"
      breadcrumbs={[{ url: "/admin/therapists/organizations" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField value={titleEnValue} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField value={titleArValue} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Phone Number En</TextStyle>
              <TextField
                value={phoneNumberEnValue}
                onChange={handleChangePhoneNumberEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Phone Number Ar</TextStyle>
              <TextField
                value={phoneNumberArValue}
                onChange={handleChangePhoneNumberAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Address En</TextStyle>
              <TextField
                value={addressEnValue}
                onChange={handleChangeAddressEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Address Ar</TextStyle>
              <TextField
                value={addressArValue}
                onChange={handleChangeAddressAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Email</TextStyle>
          <TextField value={emailValue} onChange={handleChangeEmail} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Summary En</TextStyle>
              <TextField
                value={textEnValue}
                onChange={handleChangeTextEn}
              ></TextField>
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Summary Ar</TextStyle>
              <TextField
                value={textArValue}
                onChange={handleChangeTextAr}
              ></TextField>
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={summaryError} />
          <TextStyle variation="strong">Visit</TextStyle>
          <TextField
            value={visitValue}
            onChange={handleChangeVisit}
            autoComplete="off"
          />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Youtube Link</TextStyle>
              <TextField
                value={youtubeValue}
                onChange={handleChangeYoutube}
                autoComplete="off"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Printerest Link</TextStyle>
              <TextField
                value={printerestValue}
                onChange={handleChangePrinterest}
                autoComplete="off"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Instagram Link</TextStyle>
              <TextField
                value={instagramValue}
                onChange={handleChangeInstagram}
                autoComplete="off"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Facebook Link</TextStyle>
              <TextField
                value={facebookValue}
                onChange={handleChangeFacebook}
                autoComplete="off"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Twitter Link</TextStyle>
              <TextField
                value={twitterValue}
                onChange={handleChangeTwitter}
                autoComplete="off"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">App Store Link</TextStyle>
              <TextField
                value={appStoreValue}
                onChange={handleChangeAppStore}
                autoComplete="off"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Play Store Link</TextStyle>
              <TextField
                value={playStoreValue}
                onChange={handleChangePlayStore}
                autoComplete="off"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Profile En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={profileEnValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleChangeProfileEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Profile Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={profileArValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleChangeProfileAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={profileError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={desktopImagePreview ? desktopImagePreview : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Countries</TextStyle>
          <MultiSelect
            options={optionsCountries}
            value={selectedCountries}
            onChange={setSelectedCountries}
            labelledBy="Please select a country"
          />
          <Checkbox
            label="Is Purely Online"
            checked={checkedOnline}
            onChange={handleChangeOnline}
          />
          <Checkbox
            label="Is Recommended"
            checked={checkedRecommended}
            onChange={handleChangeRecommended}
          />
          <Checkbox
            label="Is Active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (!titleEnValue && !titleArValue) {
      setTitleError("Please enter a name");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      visitValue && form_data.append("website", visitValue);
      titleEnValue && form_data.append("name[en]", titleEnValue);
      titleArValue && form_data.append("name[ar]", titleArValue);
      textEnValue && form_data.append("summary[en]", textEnValue);
      textArValue && form_data.append("summary[ar]", textArValue);
      profileEnValue && form_data.append("profile[en]", profileEnValue);
      profileArValue && form_data.append("profile[ar]", profileArValue);
      if (files.length) {
        form_data.append("image", files[files.length - 1]);
      } else if (
        Cookies.get("houna-organization") &&
        JSON.parse(Cookies.get("houna-organization"))?.image
          ? JSON.parse(Cookies.get("houna-organization")).image
          : ""
      ) {
        form_data.append("image", JSON.parse(Cookies.get("houna-lead")).image);
      }
      form_data.append("show", checkedShow ? 1 : 0);
      form_data.append("recommended", checkedRecommended ? 1 : 0);
      form_data.append("highlighted", 1);
      form_data.append("order", 1);
      addressEnValue && form_data.append("address[en]", addressEnValue);
      addressArValue && form_data.append("address[ar]", addressArValue);
      phoneNumberEnValue && form_data.append("phone[en]", phoneNumberEnValue);
      phoneNumberArValue && form_data.append("phone[ar]", phoneNumberArValue);
      emailValue && form_data.append("email", emailValue);
      selectedCountries.length &&
        selectedCountries.forEach((selected, index) =>
          form_data.append(`country_id[${index}]`, selected.value)
        );
      form_data.append("online", checkedOnline ? 1 : 0);
      youtubeValue && form_data.append("social[youtube]", youtubeValue);
      printerestValue &&
        form_data.append("social[printerest]", printerestValue);
      instagramValue && form_data.append("social[instagram]", instagramValue);
      facebookValue && form_data.append("social[facebook]", facebookValue);
      twitterValue && form_data.append("social[twitter]", twitterValue);
      appStoreValue && form_data.append("social[app_store]", appStoreValue);
      playStoreValue && form_data.append("social[play_store]", playStoreValue);
      axios
        .post("/admin/organizations", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/therapists/organizations");
          }
        })
        .catch((err) => console.log(""));
    }
  }
  function findArrayElementByName(array, value) {
    return array.find((element, index) => {
      return element.name.en.toLowerCase() == value.toLowerCase();
    });
  }
};
export default Add;
