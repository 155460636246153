import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Thumbnail,
  Badge,
  Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import emptyListImage from "../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
const List = () => {
  var PromiseAll = require("promises-all");
  const perPage = 50;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleEdit = (e) => {
    history.push(`/admin/pages/${e.currentTarget.id}`);
  };
  const handleUp = (e) => {
    console.log(e.currentTarget.id.split("_")[0]);
    console.log(e.currentTarget.id.split("_")[1]);

    const bodyObj = {
      id: parseInt(e.currentTarget.id.split("_")[0]),
      order: parseInt(e.currentTarget.id.split("_")[1]) - 1,
      title: "eSIM ONE TIME FEE",
      text: "eSIM ONE TIME FEE",
    };
    axios
      .post("/admin/articles/update", bodyObj)
      .then((res) => {
        //getData();
      })
      .catch((err) => console.log(""));
  };
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleSelectChangeCategory = (newValue) => {
    setSelectedCategory(newValue);
  };
  const [optionsCategory, setOptionsCategory] = useState("");
  useEffect(() => {
    !categories.length ? fct1() : fct2();
  }, [page, sortState, availability, queryValue, selectedCategory]);
  const fct1 = () => {
    PromiseAll.all([getCategories(), getList()]).then(function (response) {
      if (typeof response.resolve[0] === "object") {
        setCategories(response.resolve[0].data.data);
        setOptionsCategory(
          response.resolve[0].data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
            };
          })
        );
      }
      if (typeof response.resolve[1] === "object") {
        setIsLoading(false);
        !response.resolve[1].data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(response.resolve[1].data.total / perPage));
        setItems(
          response.resolve[1].data.data.map((item, index) => [
            item.id,
            item?.image && <Thumbnail source={item.image}></Thumbnail>,
            item?.title && item.title[Object.keys(item.title)[0]],
            item?.category_id && (
              <Badge>
                {
                  findElementByID(
                    response.resolve[0].data.data,
                    item.category_id
                  ).name.en
                }
              </Badge>
            ),
            <ButtonGroup>
              <Button onClick={handleEdit} id={item.id}>
                Edit
              </Button>
            </ButtonGroup>,
          ])
        );
      }
    });
  };
  const fct2 = () => {
    console.log("222");
    axios
      .get(
        `/admin/articles?per_page=${perPage}&page=${page}&filter[${
          availability === "" ? "category_id" : availability
        }]=${
          availability === "" ? selectedCategory : queryValue
        }&sort=${sortState}`
      )
      .then((result) => {
        setIsLoading(false);
        !result.data.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.total / perPage));
        console.log(result.data.data);
        setItems(
          result.data.data.map((item, index) => [
            item.id,
            item?.image && <Thumbnail source={item.image}></Thumbnail>,
            item?.title && item.title[Object.keys(item.title)[0]],
            item?.category_id && (
              <Badge>
                {findElementByID(categories, item.category_id).name.en}
              </Badge>
            ),
            <ButtonGroup>
              <Button onClick={handleEdit} id={item.id}>
                Edit
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => setIsLoading(false));
  };
  // useEffect(() => {
  //   axios
  //     .get(
  //       `/admin/articles?per_page=${perPage}&page=${page}&filter[${
  //         availability == "" ? "id" : availability
  //       }]=${
  //         availability == "category_id"
  //           ? findElementByName(categories, queryValue)
  //             ? findElementByName(categories, queryValue).id
  //             : ""
  //           : queryValue
  //       }&sort=${sortState}`
  //     )
  //     .then((result) => {
  //       setIsLoading(false);
  //       !result.data.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
  //       setTotalPages(Math.ceil(result.data.total / perPage));
  //       setItems(
  //         result.data.data.map((item, index) => [
  //           item.id,
  //           item?.image && <Thumbnail source={item.image}></Thumbnail>,
  //           item?.title && item.title[Object.keys(item.title)[0]],
  //           item?.category_id && (
  //             <Badge>
  //               {findElementByID(result.data.data, item.category_id).name.en}
  //             </Badge>
  //           ),
  //           <ButtonGroup>
  //             <Button onClick={handleEdit} id={item.id}>
  //               Edit
  //             </Button>
  //           </ButtonGroup>,
  //         ])
  //       );
  //     })
  //     .catch((err) => setIsLoading(false));
  // }, [page, sortState, availability, queryValue]);
  // useEffect(() => {
  //   PromiseAll.all([getCategories(), getList()]).then(function (response) {
  //     if (typeof response.resolve[0] === "object") {
  //       setCategories(response.resolve[0].data.data);
  //     }
  //     if (typeof response.resolve[1] === "object") {
  //       setIsLoading(false);
  //       !response.resolve[1].data.data.length
  //         ? setIsListEmpty(true)
  //         : setIsListEmpty(false);
  //       setTotalPages(Math.ceil(response.resolve[1].data.total / perPage));
  //       setItems(
  //         response.resolve[1].data.data.map((item, index) => [
  //           item.id,
  //           item?.image && <Thumbnail source={item.image}></Thumbnail>,
  //           item?.title && item.title[Object.keys(item.title)[0]],
  //           item?.category_id && (
  //             <Badge>
  //               {
  //                 findElementByID(
  //                   response.resolve[0].data.data,
  //                   item.category_id
  //                 ).name.en
  //               }
  //             </Badge>
  //           ),
  //           <ButtonGroup>
  //             <Button onClick={handleEdit} id={item.id}>
  //               Edit
  //             </Button>
  //           </ButtonGroup>,
  //         ])
  //       );
  //     }
  //   });
  // }, []);
  const getCategories = () => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get("/admin/article-categories")
          .then(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 0);
    });
  };
  const getList = () => {
    // console.log("tessst=", findElementByName(categories.media));
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get(
            `/admin/articles?per_page=${perPage}&page=${page}&filter[${
              availability == "" ? "id" : availability
            }]=${
              availability == "category_id"
                ? findElementByName(categories, queryValue)
                  ? findElementByName(categories, queryValue).id
                  : ""
                : queryValue
            }&sort=${sortState}`
          )
          .then(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 1000);
    });
  };

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Title", value: "title" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  return (
    <Page
      fullWidth
      title="Pages"
      primaryAction={{
        content: "Add Page",
        url: "/admin/pages/new",
      }}
    >
      <Card>
        {loadingMarkup}

        <Card.Section>
          <Select
            options={optionsCategory}
            onChange={handleSelectChangeCategory}
            value={selectedCategory}
            placeholder="Please select a category"
          />
        </Card.Section>

        <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">Thumbnail</TextStyle>,
            <TextStyle variation="strong">Title</TextStyle>,
            <TextStyle variation="strong">Category</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[true, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function findElementByID(array, id) {
    return array.find((element) => {
      return element.id == id;
    });
  }
  function findElementByName(array, name) {
    return array.find((element) => {
      return element.name.en.toLowerCase().includes(name);
    });
  }
};

export default List;
