import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  DataTable,
  Link,
  Button,
  Page,
  AppProvider,
  Checkbox,
  Icon,
  FormLayout,
  TextField,
  Select,
  Card,
  Tabs,
  Pagination,
  TextStyle,
} from "@shopify/polaris";
import { ListMajor, CategoriesMajor } from "@shopify/polaris-icons";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import NavigationLayout from "../../Components/NavigationLayout";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   },
// }));

const GridView = () => {
  //   const classes = useStyles();
  return (
    <Page>
      <Card>
        <div style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="gridItem"
                onMouseDown={(e) => history.push("/admin/faqs/categories")}
              >
                <div className="iconDiv">
                  <Icon
                    source={ListMajor}
                    style={{ margin: "0 5px" }}
                    color="subdued"
                  ></Icon>
                </div>
                <div style={{ flexDirection: "column", marginLeft: "10" }}>
                  <div className="titleDev">
                    <TextStyle variation="strong">Categories</TextStyle>
                  </div>
                  <div className="description">
                    <TextStyle></TextStyle>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="gridItem"
                onMouseDown={(e) => history.push("/admin/faqs/items")}
              >
                <div className="iconDiv">
                  <Icon
                    source={CategoriesMajor}
                    style={{ margin: "0 5px" }}
                    color="subdued"
                  ></Icon>
                </div>
                <div style={{ flexDirection: "column", marginLeft: "10" }}>
                  <div className="titleDev">
                    <TextStyle variation="strong">Faqs</TextStyle>
                  </div>
                  <div className="description">
                    <TextStyle></TextStyle>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
    </Page>
  );
};
export default GridView;
