import React, { useCallback, useState, useEffect } from "react";
import {
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  TextStyle,
  Loading,
  InlineError,
  Toast,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
} from "@shopify/polaris";
import axios from "./Assets/Lib/axios";
import NavigationLayout from "./Components/NavigationLayout";
import { Editor } from "@tinymce/tinymce-react";
import { indigo } from "@material-ui/core/colors";

const About = () => {
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = (newValue) => {
    setTextEnValue(newValue);
    setTextError("");
  };
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = (newValue) => {
    setTextArValue(newValue);
    setTextError("");
  };
  const [textError, setTextError] = useState("");
  const [titleOurMissionEnValue, setTitleOurMissionEnValue] = useState("");
  const handleChangeTitleOurMissionEn = useCallback((newValue) => {
    setTitleOurMissionEnValue(newValue);
    setTitleOurMissionError("");
  }, []);
  const [titleOurMissionArValue, setTitleOurMissionArValue] = useState("");
  const handleChangeTitleOurMissionAr = useCallback((newValue) => {
    setTitleOurMissionArValue(newValue);
    setTitleOurMissionError("");
  }, []);
  const [titleOurMissionError, setTitleOurMissionError] = useState("");
  const [textOurMissionEnValue, setTextOurMissionEnValue] = useState("");
  const handleChangeTextOurMissionEn = (newValue) => {
    setTextOurMissionEnValue(newValue);
    setTextOurMissionError("");
  };
  const [textOurMissionArValue, setTextOurMissionArValue] = useState("");
  const handleChangeTextOurMissionAr = (newValue) => {
    setTextOurMissionArValue(newValue);
    setTextOurMissionError("");
  };
  const [homeMessageTextEnValue, setHomeMessageTextEnValue] = useState("");
  const handleChangeHomeMessageTextEnValue = (newValue) => {
    setHomeMessageTextError("");
    setHomeMessageTextEnValue(newValue);
  };
  const [homeMessageTextArValue, setHomeMessageTextArValue] = useState("");
  const handleChangeHomeMessageTextArValue = (newValue) => {
    setHomeMessageTextError("");
    setHomeMessageTextArValue(newValue);
  };
  const [homeMessageTextError, setHomeMessageTextError] = useState("");
  const [homeMessageTitleEnValue, setHomeMessageTitleEnValue] = useState("");
  const handleChangeHomeMessageTitleEn = (newValue) => {
    setHomeMessageTitleError("");
    setHomeMessageTitleEnValue(newValue);
  };
  const [homeMessageTitleArValue, setHomeMessageTitleArValue] = useState("");
  const handleChangeHomeMessageTitleAr = (newValue) => {
    setHomeMessageTitleError("");
    setHomeMessageTitleArValue(newValue);
  };
  const [homeMessageTitleError, setHomeMessageTitleError] = useState("");

  const [textOurMissionError, setTextOurMissionError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content="Page was saved" onDismiss={toggleActive} />
  ) : null;
  const [imagePreview, setImagePreview] = useState("");
  const [imageError, setImageError] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
  };
  useEffect(() => {
    axios
      .get(`admin/who-we-are`)
      .then((result) => {
        console.log(result.data[`home-video`]?.description);
        result?.data?.[`who-we-are`]?.title?.en &&
          setTitleEnValue(result.data[`who-we-are`].title.en);
        result?.data?.[`who-we-are`]?.title.ar &&
          setTitleArValue(result.data[`who-we-are`].title.ar);
        result?.data?.[`who-we-are`]?.description?.en &&
          setTextEnValue(result.data[`who-we-are`]?.description.en);
        result?.data?.[`who-we-are`]?.description.ar &&
          setTextArValue(result.data[`who-we-are`]?.description.ar);
        result?.data?.[`our-mission`]?.title?.en &&
          setTitleOurMissionEnValue(result.data[`our-mission`].title.en);
        result?.data?.[`our-mission`]?.title?.ar &&
          setTitleOurMissionArValue(result.data[`our-mission`].title.ar);
        result?.data?.[`our-mission`]?.description?.en &&
          setTextOurMissionEnValue(result.data[`our-mission`]?.description.en);
        result?.data?.[`our-mission`]?.description?.ar &&
          setTextOurMissionArValue(result.data[`our-mission`]?.description.ar);
        result?.data?.[`home-video`]?.title?.en &&
          setHomeMessageTitleEnValue(result.data[`home-video`]?.title.en);
        result?.data?.[`home-video`]?.title?.ar &&
          setHomeMessageTitleArValue(result.data[`home-video`]?.title.ar);
        result?.data?.[`home-video`]?.description?.en &&
          setHomeMessageTextEnValue(result.data[`home-video`]?.description.en);
        result?.data?.[`home-video`]?.description?.ar &&
          setHomeMessageTextArValue(result.data[`home-video`]?.description.ar);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <NavigationLayout>
      <Page title="About">
        {isSaving ? <Loading /> : null}
        <Card sectioned title="Who we are">
          <FormLayout>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Title En</TextStyle>
                <TextField
                  value={titleEnValue}
                  onChange={handleChangeTitleEn}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Title Ar</TextStyle>
                <TextField
                  value={titleArValue}
                  onChange={handleChangeTitleAr}
                />
              </FormLayout>
            </FormLayout.Group>
            <InlineError message={titleError} />

            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Text En</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  value={textEnValue}
                  init={{
                    branding: false,
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                  }}
                  onEditorChange={handleChangeTextEn}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Text Ar</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  value={textArValue}
                  init={{
                    branding: false,
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                  }}
                  onEditorChange={handleChangeTextAr}
                />
              </FormLayout>
            </FormLayout.Group>
            <InlineError message={textError} />
          </FormLayout>
        </Card>
        <Card sectioned title="Our mission">
          <FormLayout>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Title En</TextStyle>
                <TextField
                  value={titleOurMissionEnValue}
                  onChange={handleChangeTitleOurMissionEn}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Title Ar</TextStyle>
                <TextField
                  value={titleOurMissionArValue}
                  onChange={handleChangeTitleOurMissionAr}
                />
              </FormLayout>
            </FormLayout.Group>
            <InlineError message={titleOurMissionError} />

            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Text En</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  value={textOurMissionEnValue}
                  init={{
                    branding: false,
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                  }}
                  onEditorChange={handleChangeTextOurMissionEn}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Text Ar</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  value={textOurMissionArValue}
                  init={{
                    branding: false,
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                  }}
                  onEditorChange={handleChangeTextOurMissionAr}
                />
              </FormLayout>
            </FormLayout.Group>
            <InlineError message={textOurMissionError} />
            <TextStyle variation="strong">Video</TextStyle>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div style={{ float: "left" }}>
                <Thumbnail
                  size="large"
                  source={imagePreview ? imagePreview : ""}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                  {uploadedFiles}
                  {fileUpload}
                </DropZone>
              </div>
            </div>
          </FormLayout>
        </Card>

        <Card sectioned title="Home Video Message">
          <FormLayout>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Title En</TextStyle>
                <TextField
                  value={homeMessageTitleEnValue}
                  onChange={handleChangeHomeMessageTitleEn}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Title Ar</TextStyle>
                <TextField
                  value={homeMessageTitleArValue}
                  onChange={handleChangeHomeMessageTitleAr}
                />
              </FormLayout>
            </FormLayout.Group>
            <InlineError message={homeMessageTitleError} />

            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Text En</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  value={homeMessageTextEnValue}
                  init={{
                    branding: false,
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                  }}
                  onEditorChange={handleChangeHomeMessageTextEnValue}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Text Ar</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  value={homeMessageTextArValue}
                  init={{
                    branding: false,
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                  }}
                  onEditorChange={handleChangeHomeMessageTextArValue}
                />
              </FormLayout>
            </FormLayout.Group>
            <InlineError message={homeMessageTextError} />
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: "Save",
            onClick: handleSave,
            loading: isSaving && true,
          }}
        />
        {toastMarkup}
      </Page>
    </NavigationLayout>
  );

  function handleSave() {
    if (
      (!titleEnValue && !titleArValue) ||
      (!textEnValue && textArValue) ||
      (!titleOurMissionEnValue && !titleOurMissionArValue) ||
      (!textOurMissionEnValue && !!textOurMissionArValue) ||
      (!homeMessageTextEnValue && !!homeMessageTextArValue) ||
      (!homeMessageTitleEnValue && !!homeMessageTitleArValue)
    ) {
      !titleEnValue && !titleArValue && setTitleError("Please enter a title");
      !textEnValue && !textArValue && setTextError("Please enter a text");
      !titleOurMissionEnValue &&
        !titleOurMissionArValue &&
        setTitleOurMissionError("Please enter a title");
      !textOurMissionEnValue &&
        !textOurMissionArValue &&
        setTextOurMissionError("Please enter a text");
      !homeMessageTextEnValue &&
        !homeMessageTextArValue &&
        setHomeMessageTextError("Please enter a text");
      !homeMessageTitleEnValue &&
        !homeMessageTitleArValue &&
        setHomeMessageTitleError("Please enter a title");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      titleEnValue && form_data.append("whoWeAre_title[en]", titleEnValue);
      titleArValue && form_data.append("whoWeAre_title[ar]", titleArValue);
      textEnValue && form_data.append("whoWeAre_description[en]", textEnValue);
      textArValue && form_data.append("whoWeAre_description[ar]", textArValue);
      titleOurMissionEnValue &&
        form_data.append("ourMission_title[en]", titleOurMissionEnValue);
      titleOurMissionArValue &&
        form_data.append("ourMission_title[ar]", titleOurMissionArValue);
      textOurMissionEnValue &&
        form_data.append("ourMission_description[en]", textOurMissionEnValue);
      textOurMissionArValue &&
        form_data.append("ourMission_description[ar]", textOurMissionArValue);
      files.length &&
        form_data.append("ourMission_video", files[files.length - 1]);
      homeMessageTitleEnValue &&
        form_data.append("homeVideo_title[en]", homeMessageTitleEnValue);
      homeMessageTitleArValue &&
        form_data.append("homeVideo_title[ar]", homeMessageTitleArValue);
      homeMessageTextEnValue &&
        form_data.append("homeVideo_description[en]", homeMessageTextEnValue);
      homeMessageTextArValue &&
        form_data.append("homeVideo_description[ar]", homeMessageTextArValue);
      axios
        .post("/admin/who-we-are", form_data)
        .then((res) => {
          setActive(true);
          setIsSaving(false);
        })
        .catch((err) => console.log(""));
    }
  }
};
export default About;
