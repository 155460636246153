import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  RadioButton,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";
import Cookies from "js-cookie";

const Add = () => {
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [positionEnValue, setPositionEnValue] = useState("");
  const handleChangePositionEn = useCallback((newValue) => {
    setPositionEnValue(newValue);
  }, []);
  const [positionArValue, setPositionArValue] = useState("");
  const handleChangePositionAr = useCallback((newValue) => {
    setPositionArValue(newValue);
  }, []);
  const [degreeEnValue, setDegreeEnValue] = useState("");
  const handleChangeDegreeEn = useCallback((newValue) => {
    setDegreeEnValue(newValue);
  }, []);
  const [degreeArValue, setDegreeArValue] = useState("");
  const handleChangeDegreeAr = useCallback((newValue) => {
    setDegreeArValue(newValue);
  }, []);
  const [yearsOfExperienceEnValue, setYearsOfExperienceEnValue] = useState("");
  const handleChangeYearsOfExperienceEn = useCallback((newValue) => {
    setYearsOfExperienceEnValue(newValue);
  }, []);
  const [yearsOfExperienceArValue, setYearsOfExperienceArValue] = useState("");
  const handleChangeYearsOfExperienceAr = useCallback((newValue) => {
    setYearsOfExperienceArValue(newValue);
  }, []);
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = (newValue) => {
    setTextEnValue(newValue);
    setTextError("");
  };
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = (newValue) => {
    setTextArValue(newValue);
    setTextError("");
  };
  const [servicesEnValue, setServicesEnValue] = useState("");
  const handleChangeServicesEn = (newValue) => {
    setServicesEnValue(newValue);
  };
  const [servicesArValue, setServicesArValue] = useState("");
  const handleChangeServicesAr = (newValue) => {
    setServicesArValue(newValue);
  };
  const [textError, setTextError] = useState("");
  const [profileEnValue, setProfileEnValue] = useState("");
  const handleChangeProfileEn = (newValue) => {
    setProfileEnValue(newValue);
    setProfileError("");
  };
  const [profileArValue, setProfileArValue] = useState("");
  const handleChangeProfileAr = (newValue) => {
    setProfileArValue(newValue);
    setProfileError("");
  };
  const [specialtiesEnValue, setSpecialtyEnValue] = useState("");
  const handleChangeSpecialtyEn = (newValue) => {
    setSpecialtyEnValue(newValue);
  };
  const [specialtiesArValue, setSpecialtyArValue] = useState("");
  const handleChangeSpecialtyAr = (newValue) => {
    setSpecialtyArValue(newValue);
  };
  const [phoneNumberEnValue, setPhoneNumberEnValue] = useState("");
  const handleChangePhoneNumberEn = useCallback((newValue) => {
    setPhoneNumberEnValue(newValue);
  }, []);
  const [phoneNumberArValue, setPhoneNumberArValue] = useState("");
  const handleChangePhoneNumberAr = useCallback((newValue) => {
    setPhoneNumberArValue(newValue);
  }, []);
  const [emailValue, setEmailValue] = useState("");
  const handleChangeEmail = useCallback((newValue) => {
    setEmailValue(newValue);
  }, []);
  const [profileError, setProfileError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setDesktopImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setDesktopImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [optionsOrganizations, setOptionOrganizationss] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [optionsLanguages, setOptionsLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [optionsDisorders, setOptionsDisorders] = useState([]);
  const [selectedDisorders, setSelectedDisorders] = useState([]);
  const [optionsProfessions, setOptionsProfessions] = useState([]);
  const [selectedProfessions, setSelectedProfessions] = useState([]);
  const [optionsCountries, setOptionsCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [optionsContributions, setOptionsContributions] = useState([
    { label: "Content for Databank", value: "Content for Databank" },
    {
      label: "Run support group for Houna",
      value: "Run support group for Houna",
    },
    {
      label: "Content for social media posts",
      value: "Content for social media posts",
    },
    { label: "Instagram live sessions", value: "Instagram live sessions" },
    {
      label: "Provide free sessions to users",
      value: "Provide free sessions to users",
    },
    {
      label: "Provide discounted sessions to users",
      value: "Provide discounted sessions to users",
    },
    {
      label: "Review content for scientific and evidence-based accuracy",
      value: "Review content for scientific and evidence-based accuracy ",
    },
  ]);

  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );
  // const [optionsCountries, setOptionsCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const handleSelectCountry = useCallback((newValue) => {
    setSelectedCountry(newValue);
    setCountryError("");
  }, []);
  const [optionsSpecialicities, setOptionsSpecialicities] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState("");
  const handleSelectSpecialicity = useCallback((newValue) => {
    setSelectedSpeciality(newValue);
    setSpecialtyError("");
  }, []);
  const [optionsAges, setOptionsAges] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);
  const [valueGender, setGender] = useState("");
  const handleChangeGender = useCallback((_checked, newValue) => {
    setGender(newValue);
    setGenderError("");
  }, []);

  const [ageError, setAgeError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [specialtyError, setSpecialtyError] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [languageError, setLanguageError] = useState("");
  const [visitValue, setVisitValue] = useState("");
  const handleChangeVisit = useCallback(
    (newValue) => setVisitValue(newValue),
    []
  );
  const [checkedOnline, setCheckedOnline] = useState(false);
  const handleChangeOnline = useCallback(
    (newChecked) => setCheckedOnline(newChecked),
    []
  );
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    let responseAges = "";
    let responseLanguages = "";
    let responseCountries = "";
    try {
      responseAges = await axios.get(`/admin/age-range`);
      responseAges?.data &&
        setOptionsAges(
          responseAges.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseLanguages = await axios.get(`/admin/languages`);
      responseLanguages?.data &&
        setOptionsLanguages(
          responseLanguages.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseCountries = await axios.get(`/admin/countries`);
      responseCountries?.data &&
        setOptionsCountries(
          responseCountries.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    setIsSaving(false);
  }

  return (
    <Page title="Add Speaker" breadcrumbs={[{ url: "/admin/events/speakers" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField value={titleEnValue} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField value={titleArValue} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>

          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={desktopImagePreview ? desktopImagePreview : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Position En</TextStyle>
              <TextField
                value={positionEnValue}
                onChange={handleChangePositionEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Position Ar</TextStyle>
              <TextField
                value={positionArValue}
                onChange={handleChangePositionAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Degree En</TextStyle>
              <TextField
                value={degreeEnValue}
                onChange={handleChangeDegreeEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Degree Ar</TextStyle>
              <TextField
                value={degreeArValue}
                onChange={handleChangeDegreeAr}
              />
            </FormLayout>
          </FormLayout.Group>
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Years of experience En</TextStyle>
              <TextField
                value={yearsOfExperienceEnValue}
                onChange={handleChangeYearsOfExperienceEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Years of experience Ar</TextStyle>
              <TextField
                value={yearsOfExperienceArValue}
                onChange={handleChangeYearsOfExperienceAr}
              />
            </FormLayout>
          </FormLayout.Group> */}
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Phone Number En</TextStyle>
              <TextField
                value={phoneNumberEnValue}
                onChange={handleChangePhoneNumberEn}
              ></TextField>
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Phone Number Ar</TextStyle>
              <TextField
                value={phoneNumberArValue}
                onChange={handleChangePhoneNumberAr}
              ></TextField>
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Email</TextStyle>
          <TextField
            value={emailValue}
            onChange={handleChangeEmail}
          ></TextField>

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Summary En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // initialValue="<p>This is the initial content of the editor</p>"
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Summary Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={summaryError} />
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Services En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={servicesEnValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeServicesEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Services Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={servicesArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeServicesAr}
              />
            </FormLayout>
          </FormLayout.Group> */}

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Profile En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={profileEnValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeProfileEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Profile Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={profileArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeProfileAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={profileError} />

          <FormLayout>
            {/* <Select
              options={optionsCountries}
              onChange={handleSelectCountry}
              value={selectedCountry}
              placeholder="Please select a country"
            /> */}
            {/* <TextStyle variation="strong">Website</TextStyle>
            <TextField
              value={visitValue}
              onChange={handleChangeVisit}
              autoComplete="off"
            /> */}
            {/* <TextStyle variation="strong">Specialty</TextStyle>
            <Select
              options={optionsSpecialicities}
              value={selectedSpeciality}
              onChange={handleSelectSpecialicity}
              placeholder="Please select a specialty"
            />
            <InlineError message={specialtyError} /> */}

            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Specialties En</TextStyle>

                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  // value={specialtiesEnValue}
                  init={{
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onEditorChange={handleChangeSpecialtyEn}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Specialties Ar</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  // value={specialtiesEnValue}
                  init={{
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onEditorChange={handleChangeSpecialtyAr}
                />
              </FormLayout>
            </FormLayout.Group>

            {/* <TextStyle variation="strong">Organizations</TextStyle>
            <MultiSelect
              options={optionsOrganizations}
              value={selectedOrganizations}
              onChange={setSelectedOrganizations}
              labelledBy="Select"
            />
            <TextStyle variation="strong">Disorders</TextStyle>
            <MultiSelect
              options={optionsDisorders}
              value={selectedDisorders}
              onChange={setSelectedDisorders}
              labelledBy="Select"
            /> */}
            {/* <TextStyle variation="strong">Contributions</TextStyle>
            <MultiSelect
              options={optionsContributions}
              value={selectedContributions}
              onChange={setSelectedContributions}
              labelledBy="Please select"
            />
            <TextStyle variation="strong">Countries</TextStyle>
            <MultiSelect
              options={optionsCountries}
              value={selectedCountries}
              onChange={setSelectedCountries}
              labelledBy="Please select a country"
            /> */}
            {/* //a */}
            {/* <TextStyle variation="strong">Gender</TextStyle>
            <Stack horizontal>
              <RadioButton
                label="Male"
                checked={valueGender === "male"}
                id="male"
                name="male"
                onChange={handleChangeGender}
              />
              <RadioButton
                label="Female"
                id="female"
                name="female"
                checked={valueGender === "female"}
                onChange={handleChangeGender}
              />
            </Stack>
            <InlineError message={genderError} /> */}
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Countries</TextStyle>
                <MultiSelect
                  options={optionsCountries}
                  value={selectedCountries}
                  onChange={setSelectedCountries}
                  labelledBy="Please select a country"
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Age</TextStyle>
                <MultiSelect
                  options={optionsAges}
                  value={selectedAges}
                  onChange={setSelectedAges}
                  labelledBy="Please select age"
                />
              </FormLayout>
            </FormLayout.Group>

            <InlineError message={ageError} />
            {/* <TextStyle variation="strong">Tags</TextStyle>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            /> */}
            <TextStyle variation="strong">Languages</TextStyle>
            <MultiSelect
              options={optionsLanguages}
              value={selectedLanguages}
              onChange={setSelectedLanguages}
              labelledBy="Please select a language"
            />
            <InlineError message={languageError} />
          </FormLayout>
          {/* <Checkbox
            label="Is Purely Online"
            checked={checkedOnline}
            onChange={handleChangeOnline}
          /> */}
          <Checkbox
            label="Is Active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if ((!titleEnValue && !titleArValue) || !selectedLanguages.length) {
      !titleEnValue && !titleArValue && setTitleError("Please enter a title");
      !selectedLanguages.length && setLanguageError("Please select a language");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      titleEnValue && form_data.append("name[en]", titleEnValue);
      titleArValue && form_data.append("name[ar]", titleArValue);
      textEnValue && form_data.append("brief[en]", textEnValue);
      textArValue && form_data.append("brief[ar]", textArValue);
      profileEnValue && form_data.append("profile[en]", profileEnValue);
      profileArValue && form_data.append("profile[ar]", profileArValue);
      degreeEnValue && form_data.append("degree[en]", degreeEnValue);
      degreeArValue && form_data.append("degree[ar]", degreeArValue);
      selectedLanguages.forEach((selected, index) =>
        form_data.append(`languages[${index}]`, selected.value)
      );
      selectedCountries.forEach((selected, index) =>
        form_data.append(`country_id[${index}]`, selected.value)
      );
      selectedAges.forEach((selected, index) =>
        form_data.append(`age_range[${index}]`, selected.value)
      );
      files[files.length - 1] &&
        form_data.append("image", files[files.length - 1]);

      form_data.append("show", checkedShow ? 1 : 0);
      form_data.append("highlighted", 1);
      form_data.append("order", 1);
      titleEnValue && form_data.append("alias", convertToSlug(titleEnValue));
      specialtiesEnValue &&
        form_data.append("speciality[en]", specialtiesEnValue);
      specialtiesArValue &&
        form_data.append("speciality[ar]", specialtiesArValue);
      phoneNumberEnValue && form_data.append("phone[en]", phoneNumberEnValue);
      phoneNumberArValue && form_data.append("phone[ar]", phoneNumberArValue);
      emailValue && form_data.append("email", emailValue);
      positionEnValue && form_data.append("position[en]", positionEnValue);
      positionArValue && form_data.append("position[ar]", positionArValue);

      axios
        .post("/admin/speakers", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/events/speakers");
          }
        })
        .catch((err) => console.log(""));
    }
  }
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
  function findArrayElementByName(array, value) {
    return array.find((element, index) => {
      return element.name.en.toLowerCase() == value.toLowerCase();
    });
  }
  function findArrayProfessionByName(array, value) {
    return array.find((element, index) => {
      return element.value.en.toLowerCase() == value.toLowerCase();
    });
  }
  function average(a, b) {
    // force the input as numbers *1
    return (a * 1 + b * 1) / 2;
  }
  function isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0;
  }
  function isUrl(s) {
    var regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }
};
export default Add;
