import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  TextField,
  Tag,
  Modal,
  TextContainer,
  Toast,
  InlineError,
  FormLayout,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import { Editor } from "@tinymce/tinymce-react";
import { useParams } from "react-router-dom";

const SubscribersEvent = () => {
  const { id } = useParams();
  const refId = useRef(null);
  const perPage = 50;
  const [ts, setTs] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleEdit = (e) => {
    history.push(`/admin/events/items/${e.currentTarget.id}`);
  };
  const handleSubscribers = (e) => {
    history.push(`/admin/events/items/${e.currentTarget.id}_subscribers`);
  };
  const handleUp = (e) => {
    console.log(e.currentTarget.id.split("_")[0]);
    console.log(e.currentTarget.id.split("_")[1]);

    const bodyObj = {
      id: parseInt(e.currentTarget.id.split("_")[0]),
      order: parseInt(e.currentTarget.id.split("_")[1]) - 1,
      title: "eSIM ONE TIME FEE",
      text: "eSIM ONE TIME FEE",
    };
    axios
      .post("/admin/articles/update", bodyObj)
      .then((res) => {
        //getData();
      })
      .catch((err) => console.log(""));
  };
  const getData = () => {
    axios
      .get(`/admin/events/${id}/subscribers`)
      .then((result) => {
        console.log(result.data);
        setIsLoading(false);
        !result.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
        // setTotalPages(Math.ceil(result.data.total / perPage));
        setItems(
          result.data.map((item, index) => [
            item.id,
            item?.name && item.name,
            item?.email && item.email,
            item?.payment_status && item.payment_status,
            <ButtonGroup>
              <Button destructive onClick={() => handleDelete(item.id)}>
                Delete
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, [page, sortState, availability, queryValue, ts]);

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Title", value: "title" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  const [reminderValue, setReminderValue] = useState("");
  const handleChangeReminderValue = useCallback((newValue) => {
    setReminderValue(newValue);
    setErrorReminder("");
  }, []);
  const [activeToast, setActiveToast] = useState(false);
  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const [toastContent, setToastContent] = useState(false);

  const toastMarkup = activeToast ? (
    <Toast content={toastContent} onDismiss={toggleActiveToast} />
  ) : null;
  const [active, setActive] = useState(false);
  const handleChange = useCallback(() => setActive(!active), [active]);
  const handleDelete = (id) => {
    refId.current = id;
    handleChange();
  };
  const handleYes = () => {
    setIsSaving(true);
    const bodyObj = { id: refId.current };
    axios
      .post(`/admin/events/subscribers/delete`, bodyObj)
      .then((result) => {
        setIsSaving(false);
        setTs(+new Date());
        setToastContent("Subscriber deleted successfully");
        handleChange();
        toggleActiveToast();
      })
      .catch((err) => console.log(err));
  };
  const [errorReminder, setErrorReminder] = useState("");
  const [activeReminder, setActiveReminder] = useState(false);
  const handleChangeReminder = useCallback(
    () => setActiveReminder(!activeReminder),
    [activeReminder]
  );
  const [activeAddSubscriber, setActiveAddSubscriber] = useState(false);
  const handleChangeAddSubscriber = useCallback(
    () => setActiveAddSubscriber(!activeAddSubscriber),
    [activeAddSubscriber]
  );
  const handleAddSubscriber = (id) => {
    // refId.current = id;
    handleChangeAddSubscriber();
  };
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleYesAddSubscriber = () => {
    if (
      !emailValue ||
      !nameValue ||
      (emailValue && !isValidEmail(emailValue))
    ) {
      !emailValue && setEmailError("Please enter an email");
      !nameValue && setNameError("Please enter a name");
      emailValue &&
        !isValidEmail(emailValue) &&
        setEmailError("Please enter a valid email");
    } else {
      setIsSaving(true);

      const bodyObj = { email: emailValue, name: nameValue };
      axios
        .post(`/admin/events/${id}/subscribers`, bodyObj)
        .then((result) => {
          setIsSaving(false);
          setEmailValue("");
          handleChangeAddSubscriber();
          setToastContent("Subscriber have been added succesfully");
          toggleActiveToast();
          setTs(+new Date());
        })
        .catch((err) => console.log(err));
    }
  };
  const handleSendReminder = (id) => {
    // refId.current = id;
    handleChangeReminder();
  };
  const handleYesReminder = () => {
    if (!reminderValue) {
      setErrorReminder("Please enter a text");
    } else {
      setIsSaving(true);

      const bodyObj = { content: reminderValue };
      axios
        .post(`/admin/events/${id}/reminder`, bodyObj)
        .then((result) => {
          setIsSaving(false);

          setReminderValue("");
          handleChangeReminder();
          setToastContent("Reminder sent succesfully");
          toggleActiveToast();
        })
        .catch((err) => console.log(err));
    }
  };
  const [nameValue, setNameValue] = useState("");
  const handleChangeName = useCallback((newValue) => {
    setNameValue(newValue);
    setNameError("");
  }, []);
  const [nameError, setNameError] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const handleChangeEmail = useCallback((newValue) => {
    setEmailValue(newValue);
    setEmailError("");
  }, []);
  const [emailError, setEmailError] = useState("");
  return (
    <Page
      fullWidth
      title="Paid Subscribers"
      breadcrumbs={[{ url: `/admin/events/items` }]}
      // primaryAction={{
      //   content: "Send Reminder",
      //   onAction: handleSendReminder,
      //   // url: "/admin/events/items/new",
      //   // onAction: handleAddNew,
      // }}
      primaryAction={
        <ButtonGroup>
          <Button onClick={handleAddSubscriber}>Add Subscriber</Button>

          <Button primary onClick={handleSendReminder}>
            Send Reminder
          </Button>
        </ButtonGroup>
      }
    >
      <Card>
        {loadingMarkup}
        {/* <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section> */}
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">Name</TextStyle>,
            <TextStyle variation="strong">Email</TextStyle>,
            <TextStyle variation="strong">Payment Status</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
      <Modal
        title="Delete Subscriber"
        open={active}
        onClose={handleChange}
        primaryAction={{
          content: "Yes",
          onAction: handleYes,
          loading: isSaving,
        }}
        secondaryActions={[
          {
            content: "No",
            onAction: handleChange,
            destructive: "true",
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to delete this subscriber?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>

      <Modal
        title="Send Reminder"
        open={activeReminder}
        onClose={handleChangeReminder}
        primaryAction={{
          content: "Send",
          onAction: handleYesReminder,
          loading: isSaving,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleChangeReminder,
          },
        ]}
      >
        <Modal.Section>
          <Editor
            apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
            // initialValue="<p>This is the initial content of the editor</p>"
            init={{
              menubar: false,
              plugins: [
                "textcolor advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount image",
              ],
              toolbar:
                "undo redo | formatselect | bold italic forecolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
            }}
            value={reminderValue}
            onEditorChange={handleChangeReminderValue}
          />
          <InlineError message={errorReminder}></InlineError>
        </Modal.Section>
      </Modal>

      <Modal
        title="Add Subscriber"
        open={activeAddSubscriber}
        onClose={handleChangeAddSubscriber}
        primaryAction={{
          content: "Submit",
          onAction: handleYesAddSubscriber,
          loading: isSaving,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleChangeAddSubscriber,
          },
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <TextField
              value={nameValue}
              onChange={handleChangeName}
              label="Name"
              error={nameError}
            />
            <TextField
              value={emailValue}
              onChange={handleChangeEmail}
              label="Email"
              error={emailError}
            />
          </FormLayout>
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default SubscribersEvent;
