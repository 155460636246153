import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import MultiSelect from "react-multi-select-component";
import history from "../../Assets/Lib/history";
import { Editor } from "@tinymce/tinymce-react";

const Add = () => {
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = useCallback((newValue) => {
    setTextEnValue(newValue);
    setTextError("");
  }, []);
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = useCallback((newValue) => {
    setTextArValue(newValue);
    setTextError("");
  }, []);
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [imageError, setImageError] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    axios
      .get(`admin/tags`)
      .then((result) => {
        setOptions(
          result.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: item.id,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Add Support Group"
      breadcrumbs={[{ url: "/admin/support-groups" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={titleEnValue}
                onChange={handleChangeTitleEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={titleArValue}
                onChange={handleChangeTitleAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Text En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // initialValue="<p>This is the initial content of the editor</p>"
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Text Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // initialValue="<p>This is the initial content of the editor</p>"
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />

          <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError} />
          <TextStyle variation="strong">Tags</TextStyle>
          <MultiSelect
            options={options}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      (!titleEnValue && !titleArValue) ||
      (!textEnValue && !textArValue) ||
      !files.length
    ) {
      !titleEnValue && !titleArValue && setTitleError("Please enter a title");
      !textEnValue && !textArValue && setTextError("Please enter a text");
      !files.length && setImageError("Please upload an image");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("order", 1);
      titleEnValue && form_data.append("name[en]", titleEnValue);
      titleArValue && form_data.append("name[ar]", titleArValue);
      textEnValue && form_data.append("text[en]", textEnValue);
      textArValue && form_data.append("text[ar]", textArValue);
      files.length && form_data.append("image", files[files.length - 1]);
      selected.forEach((selected, index) =>
        form_data.append(`tags[${index}]`, selected.value)
      );
      axios
        .post("admin/support-groups", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/support-groups");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Add;
