import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import MultiSelect from "react-multi-select-component";
import history from "../../Assets/Lib/history";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, titleEn: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, titleAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeTextEn = (newValue) => {
    setItem({ ...item, textEn: newValue });
    setTextError("");
  };
  const handleChangeTextAr = (newValue) => {
    setItem({ ...item, textAr: newValue });
    setTextError("");
  };
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [imageError, setImageError] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const handleChangeShow = (newValue) => {
    setItem({ ...item, show: newValue });
  };
  const [item, setItem] = useState({
    titleEn: "",
    textEn: "",
    titleAr: "",
    textAr: "",
    image: "",
    tags: "",
    show: "",
  });
  useEffect(() => {
    Promise.all([axios.get(`admin/tags`), axios.get(`admin/news/${id}`)]).then(
      function (responses) {
        //setPopupActive(false);
        const responseTags = responses[0];
        const responseShowNews = responses[1].data;
        setOptions(
          responseTags.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: item.id,
            };
          })
        );
        setSelected(
          responseShowNews.tags.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: item.id,
            };
          })
        );

        setItem({
          titleEn: responseShowNews?.title?.en ? responseShowNews.title.en : "",
          titleAr: responseShowNews?.title?.ar ? responseShowNews.title.ar : "",
          textEn: responseShowNews?.text?.en ? responseShowNews.text.en : "",
          textAr: responseShowNews?.text?.ar ? responseShowNews.text.ar : "",
          image: responseShowNews?.image ? responseShowNews.image : "",
          show: responseShowNews.show == 0 ? false : true,
        });
      }
    );
  }, []);
  return (
    <Page title="Edit News" breadcrumbs={[{ url: "/admin/news" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={item.titleEn}
                onChange={handleChangeTitleEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={item.titleAr}
                onChange={handleChangeTitleAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Text En</TextStyle>
              <TextField
                value={item.textEn}
                onChange={handleChangeTextEn}
                multiline={4}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Text Ar</TextStyle>
              <TextField
                value={item.textAr}
                onChange={handleChangeTextAr}
                multiline={4}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />

          <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail size="large" source={item.image ? item.image : ""} />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError} />
          <TextStyle variation="strong">Tags</TextStyle>
          <MultiSelect
            options={options}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
          />
          <Checkbox
            label="Is Active"
            checked={item.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/news/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/news`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if ((!item.titleEn && !item.titleAr) || (!item.textEn && !item.textAr)) {
      !item.titleEn && !item.titleAr && setTitleError("Please enter a title");
      !item.textEn && !item.textAr && setTextError("Please enter a text");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("order", 1);
      form_data.append("id", id);
      item.titleEn && form_data.append("title[en]", item.titleEn);
      item.titleAr && form_data.append("title[ar]", item.titleAr);
      item.textEn && form_data.append("text[en]", item.textEn);
      item.textAr && form_data.append("text[ar]", item.textAr);
      files.length && form_data.append("image", files[files.length - 1]);
      selected.forEach((selected, index) =>
        form_data.append(`tags[${index}]`, selected.value)
      );
      axios
        .post("admin/news/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/news");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Edit;
