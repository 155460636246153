import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  Badge,
  Spinner,
  Modal,
  TextContainer,
  Toast,
  Select,
  Tag,
  FormLayout,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import emptyListImage from "../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import Cookies from "js-cookie";
import { ExportMinor } from "@shopify/polaris-icons";
import NavigationLayout from "../../Components/NavigationLayout";
import moment from "moment";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

function OrderDetails() {
  const { email } = useParams();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const [result, setResult] = useState("");
  useEffect(() => {
    axios
      .get(`/admin/orders?per_page=1000000&page=${1}&filter[email]=${email}`)
      .then((result) => {
        console.log("test=", result.data.data);
        setResult(result.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const capitalizeFirstLowercaseRest = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  return (
    <Page
      title="Customer Subscriptions"
      breadcrumbs={[{ url: "/admin/orders" }]}
      // titleMetadata={
      //   result?.payment_status && <Badge>{result?.payment_status}</Badge>
      // }
      // subtitle={
      //   result &&
      //   result?.total_price &&
      //   "Total: " + result?.total_price + " " + result?.currency
      // }
    >
      {result ? (
        result.map((element, index) => {
          return (
            <Card key={index}>
              <Card.Section>
                <FormLayout>
                  <TextStyle>
                    <b>Order ID:</b> {element.id}
                  </TextStyle>
                  <TextStyle>
                    <b>Reference ID:</b> {element.reference_id}
                  </TextStyle>
                  <TextStyle>
                    <b>Payment Status:</b>{" "}
                    <Badge>{element.payment_status}</Badge>
                  </TextStyle>
                </FormLayout>
              </Card.Section>
              {element?.user && (
                <Card.Section title="User Information">
                  <FormLayout>
                    {element?.user?.name && (
                      <TextStyle>
                        <b>Name:</b> {element?.user?.name}
                      </TextStyle>
                    )}
                    {element?.user?.email && (
                      <TextStyle>
                        <b>Email:</b> {element?.user?.email}
                      </TextStyle>
                    )}
                  </FormLayout>
                </Card.Section>
              )}
              <Card.Section title="Subscription Information">
                <FormLayout>
                  {element?.subscription &&
                    element?.subscription?.expiry_date && (
                      <TextStyle>
                        <b>Expiry date:</b> {element?.subscription?.expiry_date}
                      </TextStyle>
                    )}
                  {element?.type && (
                    <TextStyle>
                      <b>Type:</b> {capitalizeFirstLowercaseRest(element.type)}
                    </TextStyle>
                  )}
                  {element?.type && result?.type === "event" ? (
                    <TextStyle>
                      <b>Event Title:</b> {element.event?.title?.en}
                    </TextStyle>
                  ) : null}

                  {element?.total_price ? (
                    <TextStyle>
                      <b>Total Price:</b>{" "}
                      {element?.total_price + " " + element?.currency}
                    </TextStyle>
                  ) : null}
                </FormLayout>
              </Card.Section>
            </Card>
          );
        })
      ) : (
        <Spinner />
      )}
    </Page>
  );
}

export default OrderDetails;
