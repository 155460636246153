import React from "react";
import { Switch, Route, Router, withRouter } from "react-router-dom";
import history from "../../Assets/Lib/history";
import { AppProvider } from "@shopify/polaris";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";
import NavigationLayout from "../../Components/NavigationLayout";

function AdminUsers() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/admin-users" component={List} />
          <Route path="/admin/admin-users/new" component={Add} />
          <Route path="/admin/admin-users/:id" component={Edit} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default AdminUsers;
