import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import MultiSelect from "react-multi-select-component";
import history from "../../Assets/Lib/history";

const Add = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);
  const [titleError, setTitleError] = useState("");

  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );

  return (
    <Page title="Add Item" breadcrumbs={[{ url: "/admin/useful-links" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={titleEnValue}
                onChange={handleChangeTitleEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={titleArValue}
                onChange={handleChangeTitleAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <Checkbox
            label="Is Active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (!titleEnValue && !titleArValue) {
      !titleEnValue && !titleArValue && setTitleError("Please enter a title");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("is_active", checkedShow ? 1 : 0);
      titleEnValue && form_data.append("title[en]", titleEnValue);
      titleArValue && form_data.append("title[ar]", titleArValue);

      axios
        .post("admin/hotlines/area", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/useful-links");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Add;
