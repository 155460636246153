import React, { useState, useCallback, useEffect } from "react";
import NavigationLayout from "../Components/NavigationLayout";
import {
  Page,
  Layout,
  FormLayout,
  Card,
  Loading,
  TextStyle,
  TextField,
  DropZone,
  Thumbnail,
  PageActions,
  Stack,
  Caption,
  Button,
  Toast,
} from "@shopify/polaris";
import axios from "../Assets/Lib/axios";
import TagsInput from "react-tagsinput";
import history from "./../Assets/Lib/history";
import { Editor } from "@tinymce/tinymce-react";

const Settings = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [twitterValue, setTwitterValue] = useState("");
  const handleChangeTwitter = useCallback(
    (newValue) => setTwitterValue(newValue),
    []
  );
  const [facebookValue, setFacebookValue] = useState("");
  const handleChangeFacebook = useCallback(
    (newValue) => setFacebookValue(newValue),
    []
  );
  const [googleValue, setGoogleValue] = useState("");
  const handleChangeGoogle = useCallback(
    (newValue) => setGoogleValue(newValue),
    []
  );
  const [contactEmailValue, setContactEmailValue] = useState("");
  const handleChangeContactEmail = useCallback(
    (newValue) => setContactEmailValue(newValue),
    []
  );
  const [metaTitleValue, setMetaTitleValue] = useState("");
  const handleChangeMetaTitle = useCallback(
    (newValue) => setMetaTitleValue(newValue),
    []
  );
  const [metaDescriptionValue, setMetaDescriptionValue] = useState("");
  const handleChangeMetaDescription = useCallback(
    (newValue) => setMetaDescriptionValue(newValue),
    []
  );
  const [tagsKeywords, setTagsKeywords] = useState([]);
  const handleChangeTagsKeywords = (tagsKeywords) => {
    console.log(tagsKeywords);
    setTagsKeywords(tagsKeywords);
  };
  const [imagePreview, setImagePreview] = useState("");
  const [files, setFiles] = useState([]);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const sendImage = useCallback(
    (files) => {
      setImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
    },

    [files]
  );
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast
      content="Changes have been saved successfully"
      onDismiss={toggleActive}
    />
  ) : null;
  useEffect(() => {
    axios
      .get("/admin/notification-banner/show/1")
      .then((result) => {
        console.log(result.data);
        result?.data?.text &&
          result?.data?.text?.en &&
          setTextEnValue(result?.data?.text?.en);
        result?.data?.text &&
          result?.data?.text?.ar &&
          setTextArValue(result?.data?.text?.ar);
        result?.data?.cta1_link &&
          result?.data?.cta1_link?.en &&
          setCta1Linkvalue(result?.data?.cta1_link?.en);
        result?.data?.cta1_link &&
          result?.data?.cta1_link?.ar &&
          setCta1LinkArvalue(result?.data?.cta1_link?.ar);
        result?.data?.cta1_text &&
          result?.data?.cta1_text?.en &&
          setCta1Textvalue(result?.data?.cta1_text?.en);
        result?.data?.cta1_text &&
          result?.data?.cta1_text?.ar &&
          setCta1TexArvalue(result?.data?.cta1_text?.ar);

        result?.data?.cta2_link &&
          result?.data?.cta2_link?.en &&
          setCta2Linkvalue(result?.data?.cta2_link?.en);
        result?.data?.cta2_link &&
          result?.data?.cta2_link?.ar &&
          setCta2LinkArvalue(result?.data?.cta2_link?.ar);
        result?.data?.cta2_text &&
          result?.data?.cta2_text?.en &&
          setCta2Textvalue(result?.data?.cta2_text?.en);
        result?.data?.cta2_text &&
          result?.data?.cta2_text?.ar &&
          setCta2TextArvalue(result?.data?.cta2_text?.ar);
      })
      .catch((err) => console.log(err));
  }, []);
  const [cta1Textvalue, setCta1Textvalue] = useState("");
  const handleChangeCta1Text = useCallback(
    (newValue) => setCta1Textvalue(newValue),
    []
  );
  const [cta2Textvalue, setCta2Textvalue] = useState("");
  const handleChangeCta2Text = useCallback(
    (newValue) => setCta2Textvalue(newValue),
    []
  );

  const [cta1Linkvalue, setCta1Linkvalue] = useState("");
  const handleChangeCta1Link = useCallback(
    (newValue) => setCta1Linkvalue(newValue),
    []
  );
  const [cta2Linkvalue, setCta2Linkvalue] = useState("");
  const handleChangeCta2Link = useCallback(
    (newValue) => setCta2Linkvalue(newValue),
    []
  );

  const [cta1TextArvalue, setCta1TexArvalue] = useState("");
  const handleChangeCta1TextAr = useCallback(
    (newValue) => setCta1TexArvalue(newValue),
    []
  );
  const [cta2TextArvalue, setCta2TextArvalue] = useState("");
  const handleChangeCta2TextAr = useCallback(
    (newValue) => setCta2TextArvalue(newValue),
    []
  );

  const [cta1LinkArvalue, setCta1LinkArvalue] = useState("");
  const handleChangeCta1LinkAr = useCallback(
    (newValue) => setCta1LinkArvalue(newValue),
    []
  );
  const [cta2LinkArvalue, setCta2LinkArvalue] = useState("");
  const handleChangeCta2LinkAr = useCallback(
    (newValue) => setCta2LinkArvalue(newValue),
    []
  );

  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = (newValue) => {
    setTextEnValue(newValue);
  };
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = (newValue) => {
    setTextArValue(newValue);
  };
  return (
    <NavigationLayout>
      <Page title="Settings">
        {isSaving ? <Loading /> : null}
        <Layout>
          <Layout.AnnotatedSection title="Admin Users">
            <Card sectioned>
              <Button onClick={() => history.push("/admin/admin-users")}>
                Manage Admin Users
              </Button>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Notification Banner">
            <Card sectioned>
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle>Text En</TextStyle>
                  <Editor
                    apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                    value={textEnValue}
                    init={{
                      branding: false,
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={handleChangeTextEn}
                  />
                </FormLayout>
                <FormLayout>
                  <TextStyle>Text Ar</TextStyle>
                  <Editor
                    apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                    value={textArValue}
                    init={{
                      branding: false,
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={handleChangeTextAr}
                  />
                </FormLayout>
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  label="Cta 1 Text En"
                  value={cta1Textvalue}
                  onChange={handleChangeCta1Text}
                />
                <TextField
                  label="Cta 1 Link En"
                  value={cta1Linkvalue}
                  onChange={handleChangeCta1Link}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  label="Cta 1 Text Ar"
                  value={cta1TextArvalue}
                  onChange={handleChangeCta1TextAr}
                />
                <TextField
                  label="Cta 1 Link Ar"
                  value={cta1LinkArvalue}
                  onChange={handleChangeCta1LinkAr}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  label="Cta 2 Text En"
                  value={cta2Textvalue}
                  onChange={handleChangeCta2Text}
                />
                <TextField
                  label="Cta 2 Link En"
                  value={cta2Linkvalue}
                  onChange={handleChangeCta2Link}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  label="Cta 2 Text Ar"
                  value={cta2TextArvalue}
                  onChange={handleChangeCta2TextAr}
                />
                <TextField
                  label="Cta 2 Link Ar"
                  value={cta2LinkArvalue}
                  onChange={handleChangeCta2LinkAr}
                />
              </FormLayout.Group>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <PageActions
          primaryAction={{
            content: "Save",
            onClick: handleSave,
            loading: isSaving && true,
          }}
        />
        {toastMarkup}
      </Page>
    </NavigationLayout>
  );
  function handleSave() {
    setIsSaving(true);
    const form_data = new FormData();
    form_data.append("text[en]", textEnValue);
    form_data.append("text[ar]", textArValue);
    form_data.append("cta1_text[en]", cta1Textvalue);
    form_data.append("cta1_text[ar]", cta1TextArvalue);
    form_data.append("cta2_text[en]", cta2Textvalue);
    form_data.append("cta2_text[ar]", cta2TextArvalue);
    form_data.append("cta1_link[en]", cta1Linkvalue);
    form_data.append("cta1_link[ar]", cta1LinkArvalue);
    form_data.append("cta2_link[en]", cta2Linkvalue);
    form_data.append("cta2_link[ar]", cta2LinkArvalue);
    axios
      .post("/admin/notification-banner/show/1", form_data)
      .then((res) => {
        toggleActive();
        setIsSaving(false);
      })
      .catch((err) => console.log(""));
  }
};

export default Settings;
