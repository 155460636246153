import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  Badge,
  Spinner,
  Modal,
  TextContainer,
  Toast,
  Select,
  Tag,
  ButtonGroup,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import emptyListImage from "../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import Cookies from "js-cookie";
import { ExportMinor } from "@shopify/polaris-icons";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import NavigationLayout from "../../Components/NavigationLayout";
import moment from "moment";

const Orders = () => {
  const refId = useRef(null);
  const refBoolPage = useRef(true);
  const format = "YYYY-MM-DD HH:mm:ss";
  const perPage = 200;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const options = [
    { label: "All", value: "" },
    { label: "Paid", value: "Paid" },
    { label: "Pending", value: "pending" },
    { label: "Failed", value: "failed" },
    { label: "Cancelled", value: "cancelled" },
  ];
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("Paid");
  const handleChangePaymentStatus = useCallback(
    (value) => setSelectedPaymentStatus(value),
    []
  );
  const handleChangePage = (event, value) => {
    refBoolPage.current = true;
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleDetails = (param) => {
    history.push(`/admin/orders/${param}`);
  };
  const handleUp = (e) => {
    console.log(e.currentTarget.id.split("_")[0]);
    console.log(e.currentTarget.id.split("_")[1]);

    const bodyObj = {
      id: parseInt(e.currentTarget.id.split("_")[0]),
      order: parseInt(e.currentTarget.id.split("_")[1]) - 1,
      title: "eSIM ONE TIME FEE",
      text: "eSIM ONE TIME FEE",
    };
    axios
      .post("/admin/articles/update", bodyObj)
      .then((res) => {
        //getData();
      })
      .catch((err) => console.log(""));
  };
  const onRowClick = (e) => {
    history.push(`/admin/leads/therapists/${e.currentTarget.id}`);
  };
  const handleImport = (e) => {
    Cookies.set("houna-lead", e.currentTarget.id);
    history.push(`/admin/therapists/items/new`);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = "myfile";
  const handleDownload = (e) => {
    const ws = XLSX.utils.json_to_sheet([JSON.parse(e.currentTarget.id)]);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, JSON.parse(e.currentTarget.id).name + fileExtension);
  };
  const [ts, setTs] = useState("");
  useEffect(() => {
    getData();
  }, [page, sortState, availability, queryValue, ts, selectedPaymentStatus]);

  const paymentColor = (param) => {
    switch (param) {
      case "paid":
        return "success";
      case "failed":
        return "critical";
      case "cancelled":
        return "attention";
      default:
        return "";
    }
  };

  const getData = () => {
    axios
      .get(
        `/admin/orders?per_page=${perPage}&page=${
          refBoolPage.current ? page : 1
        }&filter[${availability === "" ? "email" : availability}]=${
          availability[0] !== "payment_status"
            ? queryValue
            : selectedPaymentStatus
        }&filter[payment_status]=${selectedPaymentStatus}`
      )
      .then((result) => {
        setIsLoading(false);
        console.log(result.data);
        setTotalPages(Math.ceil(result.data.total / perPage));
        !result.data.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
        setItems(
          result.data.data.map((item, index) => [
            item?.id && item.id,
            // item?.reference_id && item.reference_id,

            item?.user?.name && item?.user?.name,
            item?.user?.email && item?.user?.email,
            item?.total_price !== null &&
              item?.currency !== null &&
              `${item?.total_price} ${item?.currency}`,
            item?.type && <Tag>{capitalizeFirstLowercaseRest(item.type)}</Tag>,
            item?.type === "event" && item?.event?.title?.en
              ? "Event Title: " + item?.event?.title?.en
              : item?.subscription?.expiry_date
              ? "Expiry date: " + item?.subscription?.expiry_date
              : "",
            item?.payment_status && (
              <Badge status={paymentColor(item.payment_status)}>
                {capitalizeFirstLowercaseRest(item.payment_status)}
              </Badge>
            ),
            item?.created_at && moment(item.created_at).format(format),
            // item?.updated_at && moment(item.updated_at).format(format),
            <ButtonGroup>
              <Button onClick={() => handleDetails(item.email)}>Details</Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => setIsLoading(false));
  };
  const capitalizeFirstLowercaseRest = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  function handleFiltersQueryChange(queryValue) {
    refBoolPage.current = false;
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    console.log(availability);
    setAvailability(availability);
  }
  const handleAvailabilityRemove = () => {
    refBoolPage.current = true;
    setAvailability("");
  };
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            // { label: "Reference ID", value: "reference_id" },
            { label: "Email", value: "email" },
            // { label: "Payment status", value: "payment_status" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  const handleExport = () => {
    console.log(Cookies.get("houna-accesstoken"));
    window.open(
      `${process.env.REACT_APP_BASE_URL}/admin/leads/export?token=c3po9c5eEft5wE0tryb5cc5g5a54db12`
    );
  };
  const [activeToast, setActiveToast] = useState(false);
  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const toastMarkup = activeToast ? (
    <Toast
      content="Subscriber deleted successfully"
      onDismiss={toggleActiveToast}
    />
  ) : null;
  const [active, setActive] = useState(false);
  const handleChange = useCallback(() => setActive(!active), [active]);
  const handleDelete = (id) => {
    refId.current = id;
    handleChange();
  };
  const handleYes = () => {
    const bodyObj = { id: refId };
    axios
      .post(`/admin/events/subscribers/delete`, bodyObj)
      .then((result) => {
        setTs(+new Date());
        toggleActiveToast();
      })
      .catch((err) => console.log(err));
  };
  return (
    <Page
      fullWidth
      title="Orders"
      // secondaryActions={[
      //   {
      //     content: "Export",
      //     icon: ExportMinor,
      //     onAction: handleExport,
      //   },
      // ]}
    >
      <Card>
        {loadingMarkup}
        {/* {availability[0] === "payment_status" && ( */}
        <Card.Section>
          <Select
            label="Filter by payment status:"
            labelInline
            options={options}
            onChange={handleChangePaymentStatus}
            value={selectedPaymentStatus}
          />
        </Card.Section>
        {/* )} */}
        <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            // <TextStyle variation="strong">Reference ID</TextStyle>,
            <TextStyle variation="strong">User</TextStyle>,
            <TextStyle variation="strong">Email</TextStyle>,
            <TextStyle variation="strong">Total Price</TextStyle>,
            <TextStyle variation="strong">Type</TextStyle>,
            <TextStyle variation="strong">Details</TextStyle>,
            <TextStyle variation="strong">Payment Status</TextStyle>,
            <TextStyle variation="strong">Created At</TextStyle>,
            // <TextStyle variation="strong">Updated At</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
      <Modal
        title="Delete Subscriber"
        open={active}
        onClose={handleChange}
        primaryAction={{
          content: "Yes",
          onAction: handleYes,
        }}
        secondaryActions={[
          {
            content: "No",
            onAction: handleChange,
            destructive: "true",
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to delete this subscriber?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;

      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function findElementByID(array, id) {
    return array.find((element) => {
      return element.id === id;
    });
  }
};

export default Orders;
