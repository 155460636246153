import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import MultiSelect from "react-multi-select-component";
import history from "../../Assets/Lib/history";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, titleEn: newValue });
    setTitleEnError("");
  };
  const [titleEnError, setTitleEnError] = useState("");
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, titleAr: newValue });
    setTitleArError("");
  };
  const [titleArError, setTitleArError] = useState("");
  const handleChangeTextEn = (newValue) => {
    setItem({ ...item, textEn: newValue });
    setTextEnError("");
  };
  const [textEnError, setTextEnError] = useState("");
  const handleChangeTextAr = (newValue) => {
    setItem({ ...item, textAr: newValue });
    setTextArError("");
  };
  const [textArError, setTextArError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [imageError, setImageError] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setImageError("");
  };
  const handleChangeMembersOnly = (newValue) => {
    setItem({ ...item, membersOnly: newValue });
  };
  const handleChangeStreamLink = (newValue) => {
    setItem({ ...item, streamLink: newValue });
  };
  const handleChangeStartDate = (newValue) => {
    console.log(newValue);
    setItem({ ...item, startDate: newValue });
  };
  const handleChangeStartTime = (newValue) => {
    setItem({ ...item, startTime: newValue });
  };
  const [item, setItem] = useState({
    titleEn: "",
    textEn: "",
    titleAr: "",
    textAr: "",
    image: "",
    membersOnly: "",
    startDate: "",
    startTime: "",
    streamLink: "",
  });
  useEffect(() => {
    axios
      .get(`admin/broadcasts/${id}`)
      .then((result) => {
        setItem({
          titleEn: result?.data?.name?.en ? result.data.name.en : "",
          titleAr: result?.data?.name?.ar ? result.data.name.ar : "",
          textEn: result?.data?.content?.en ? result.data.content.en : "",
          textAr: result?.data?.content?.ar ? result.data.content.ar : "",
          image: result?.data?.image ? result.data.image : "",
          startDate: result?.data?.start_time
            ? result.data.start_time.split(" ")[0]
            : "",
          startTime: result?.data?.start_time
            ? result.data.start_time.split(" ")[1]
            : "",
          streamLink: result?.data?.stream ? result.data.stream : "",
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page title="Edit Broadcasts" breadcrumbs={[{ url: "/admin/broadcasts" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={item.titleEn}
                onChange={handleChangeTitleEn}
                error={titleEnError}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={item.titleAr}
                onChange={handleChangeTitleAr}
                error={titleArError}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Text En</TextStyle>
              <TextField
                value={item.textEn}
                onChange={handleChangeTextEn}
                multiline={4}
                error={textEnError}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Text Ar</TextStyle>
              <TextField
                value={item.textAr}
                onChange={handleChangeTextAr}
                multiline={4}
                error={textArError}
              />
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Stream Link</TextStyle>
          <TextField
            value={item.streamLink}
            onChange={handleChangeStreamLink}
          />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Start Date</TextStyle>
              <TextField
                value={item.startDate}
                onChange={handleChangeStartDate}
                type="date"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Start Time</TextStyle>
              <TextField
                value={item.startTime}
                onChange={handleChangeStartTime}
                type="time"
              />
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail size="large" source={item.image ? item.image : ""} />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError} />
          <Checkbox
            label="Members Only"
            checked={item.membersOnly}
            onChange={handleChangeMembersOnly}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/broadcasts/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/broadcasts`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (!item.titleEn || !item.titleAr || !item.textEn || !item.textAr) {
      !item.titleEn && setTitleEnError("This field is required");
      !item.titleAr && setTitleArError("This field is required");
      !item.textEn && setTextEnError("This field is required");
      !item.textAr && setTextArError("This field is required");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("order", 1);
      form_data.append("id", id);
      item.titleEn && form_data.append("name[en]", item.titleEn);
      item.titleAr && form_data.append("name[ar]", item.titleAr);
      item.textEn && form_data.append("content[en]", item.textEn);
      item.textAr && form_data.append("content[ar]", item.textAr);
      files.length && form_data.append("image", files[files.length - 1]);
      item.startTime &&
        item.startDate &&
        form_data.append("start_time", item.startDate + " " + item.startTime);
      item.streamLink && form_data.append("stream", item.streamLink);
      form_data.append("member_only", item.membersOnly ? 1 : 0);
      axios
        .post("admin/broadcasts/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/broadcasts");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Edit;
