import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import MultiSelect from "react-multi-select-component";
import history from "../../../Assets/Lib/history";
import { useParams } from "react-router-dom";

const Add = () => {
  const { id } = useParams();
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
  }, []);
  const [titleError, setTitleError] = useState("");
  const [descriptionEnValue, setDescriptionEnValue] = useState("");
  const handleChangeDescriptionEn = useCallback((newValue) => {
    setDescriptionEnValue(newValue);
  }, []);
  const [descriptionArValue, setDescriptionArValue] = useState("");
  const handleChangeDescriptionAr = useCallback((newValue) => {
    setDescriptionArValue(newValue);
  }, []);
  const [websiteValue, setWebsiteValue] = useState("");
  const handleChangeWebsite = useCallback((newValue) => {
    setWebsiteValue(newValue);
  }, []);
  const [phoneValue, setPhoneValue] = useState("");
  const handleChangePhone = useCallback((newValue) => {
    setPhoneValue(newValue);
  }, []);
  const [descriptionError, setDescriptionError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [imageError, setImageError] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );
  useEffect(() => {
    axios
      .get(`admin/tags`)
      .then((result) => {
        setOptions(
          result.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: item.id,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Add Center"
      breadcrumbs={[{ url: "/admin/useful-links/centers" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={titleEnValue}
                onChange={handleChangeTitleEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={titleArValue}
                onChange={handleChangeTitleAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Description En</TextStyle>
              <TextField
                value={descriptionEnValue}
                onChange={handleChangeDescriptionEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Description Ar</TextStyle>
              <TextField
                value={descriptionArValue}
                onChange={handleChangeDescriptionAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Website</TextStyle>
              <TextField
                value={websiteValue}
                onChange={handleChangeWebsite}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Phone Number</TextStyle>
              <TextField value={phoneValue} onChange={handleChangePhone} />
            </FormLayout>
          </FormLayout.Group>

          {/* <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError} /> */}
          {/* <TextStyle variation="strong">Tags</TextStyle>
          <MultiSelect
            options={options}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
          /> */}
          <Checkbox
            label="Is Active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    setIsSaving(true);
    const form_data = new FormData();
    // form_data.append("order", 1);
    form_data.append("is_active", checkedShow ? 1 : 0);
    form_data.append("website", websiteValue);
    form_data.append("title[en]", titleEnValue);
    form_data.append("title[ar]", titleArValue);
    form_data.append("description[en]", descriptionEnValue);
    form_data.append("description[ar]", descriptionArValue);
    form_data.append("phone", phoneValue);

    axios
      .post(`admin/hotlines/area/${id}/centers`, form_data)
      .then((res) => {
        if (res.status == 200) {
          history.push(`/admin/useful-links/${id}/centers`);
        }
      })
      .catch((err) => console.log(""));
  }
};
export default Add;
