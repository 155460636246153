import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  RadioButton,
  Select,
  Form,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";
import Cookies from "js-cookie";

const Add = () => {
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [addressEnValue, setAddressEnValue] = useState("");
  const handleChangeAddressEn = useCallback((newValue) => {
    setAddressEnValue(newValue);
  }, []);
  const [addressArValue, setAddressArValue] = useState("");
  const handleChangeAddressAr = useCallback((newValue) => {
    setAddressArValue(newValue);
  }, []);
  const [degreeEnValue, setDegreeEnValue] = useState("");
  const handleChangeDegreeEn = useCallback((newValue) => {
    setDegreeEnValue(newValue);
  }, []);
  const [degreeArValue, setDegreeArValue] = useState("");
  const handleChangeDegreeAr = useCallback((newValue) => {
    setDegreeArValue(newValue);
  }, []);
  const [dateValue, setDateValue] = useState("");
  const handleChangeDate = useCallback((newValue) => {
    setDateValue(newValue);
    setDateError("");
  }, []);

  const [dateError, setDateError] = useState("");

  const [schedEnValue, setSchedEnValue] = useState("");
  const handleChangeSchedEn = useCallback((newValue) => {
    setSchedEnValue(newValue);
    setSchedError("");
  }, []);
  const [schedArValue, setSchedArValue] = useState("");
  const handleChangeSchedAr = useCallback((newValue) => {
    setSchedArValue(newValue);
    setSchedError("");
  }, []);
  const [schedError, setSchedError] = useState("");

  const [timeValue, setTimeValue] = useState("");
  const handleChangeTime = useCallback((newValue) => {
    setTimeValue(newValue);
    setTimeError("");
  }, []);
  const [timeError, setTimeError] = useState("");
  const [seatsValue, setSeatsValue] = useState("");
  const handleChangeSeats = useCallback((newValue) => {
    setSeatsValue(newValue);
  }, []);
  const [actualSeatsValue, setActualSeatsValue] = useState("");
  const handleChangeActualSeats = useCallback((newValue) => {
    setActualSeatsValue(newValue);
  }, []);
  const [priceValue, setPriceValue] = useState("");
  const handleChangePrice = useCallback((newValue) => {
    setPriceValue(newValue);
  }, []);
  const [membersDiscountValue, setMembersDiscountValue] = useState("");
  const handleChangeMembersDiscount = useCallback((newValue) => {
    setMembersDiscountValue(newValue);
  }, []);
  const [discountPriceValue, setDiscountPriceValue] = useState("");
  const handleChangeDiscountPrice = useCallback((newValue) => {
    setDiscountPriceValue(newValue);
  }, []);
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = (newValue) => {
    setTextEnValue(newValue);
    setTextError("");
  };
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = (newValue) => {
    setTextArValue(newValue);
    setTextError("");
  };
  const [servicesEnValue, setServicesEnValue] = useState("");
  const handleChangeServicesEn = (newValue) => {
    setServicesEnValue(newValue);
  };
  const [servicesArValue, setServicesArValue] = useState("");
  const handleChangeServicesAr = (newValue) => {
    setServicesArValue(newValue);
  };
  const [textError, setTextError] = useState("");
  const [profileEnValue, setProfileEnValue] = useState("");
  const handleChangeProfileEn = (newValue) => {
    setProfileEnValue(newValue);
    setProfileError("");
  };
  const [profileArValue, setProfileArValue] = useState("");
  const handleChangeProfileAr = (newValue) => {
    setProfileArValue(newValue);
    setProfileError("");
  };
  const [termsEnValue, setTermsEnValue] = useState("");
  const handleChangeTermsEn = (newValue) => {
    setTermsEnValue(newValue);
  };
  const [termsArValue, setTermsArValue] = useState("");
  const handleChangeTermsAr = (newValue) => {
    setTermsArValue(newValue);
  };
  const [specialtiesEnValue, setSpecialtyEnValue] = useState("");
  const handleChangeSpecialtyEn = (newValue) => {
    setSpecialtyEnValue(newValue);
  };
  const [specialtiesArValue, setSpecialtyArValue] = useState("");
  const handleChangeSpecialtyAr = (newValue) => {
    setSpecialtyArValue(newValue);
  };
  const [phoneNumberEnValue, setPhoneNumberEnValue] = useState("");
  const handleChangePhoneNumberEn = useCallback((newValue) => {
    setPhoneNumberEnValue(newValue);
  }, []);
  const [phoneNumberArValue, setPhoneNumberArValue] = useState("");
  const handleChangePhoneNumberAr = useCallback((newValue) => {
    setPhoneNumberArValue(newValue);
  }, []);
  const [emailValue, setEmailValue] = useState("");
  const handleChangeEmail = useCallback((newValue) => {
    setEmailValue(newValue);
  }, []);
  const [profileError, setProfileError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setDesktopImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setDesktopImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [optionsOrganizations, setOptionOrganizationss] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [optionsLanguages, setOptionsLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [optionsDisorders, setOptionsDisorders] = useState([]);
  const [selectedDisorders, setSelectedDisorders] = useState([]);
  const [optionsProfessions, setOptionsProfessions] = useState([]);
  const [selectedProfessions, setSelectedProfessions] = useState([]);
  const [optionsCountries, setOptionsCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [optionsContributions, setOptionsContributions] = useState([
    { label: "Content for Databank", value: "Content for Databank" },
    {
      label: "Run support group for Houna",
      value: "Run support group for Houna",
    },
    {
      label: "Content for social media posts",
      value: "Content for social media posts",
    },
    { label: "Instagram live sessions", value: "Instagram live sessions" },
    {
      label: "Provide free sessions to users",
      value: "Provide free sessions to users",
    },
    {
      label: "Provide discounted sessions to users",
      value: "Provide discounted sessions to users",
    },
    {
      label: "Review content for scientific and evidence-based accuracy",
      value: "Review content for scientific and evidence-based accuracy ",
    },
  ]);
  const [optionsLabels, setOptionsLabels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [optionsSpeakers, setOptionsSpeakers] = useState([]);
  const [selectedSpeakers, setSelectedSpeakers] = useState([]);
  const [optionsSupportGroups, setOptionsSupportGroups] = useState([]);
  const [selectedSupportGroups, setSelectedSupportGroups] = useState([]);
  const [speakersError, setSpeakersError] = useState("");
  const [categoriesError, setCategoriesError] = useState("");
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );
  // const [optionsCountries, setOptionsCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const handleSelectCountry = useCallback((newValue) => {
    setSelectedCountry(newValue);
    setCountryError("");
  }, []);
  const [optionsSpecialicities, setOptionsSpecialicities] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState("");
  const handleSelectSpecialicity = useCallback((newValue) => {
    setSelectedSpeciality(newValue);
    setSpecialtyError("");
  }, []);
  const [optionsAges, setOptionsAges] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);
  const [valueGender, setGender] = useState("");
  const handleChangeGender = useCallback((_checked, newValue) => {
    setGender(newValue);
    setGenderError("");
  }, []);

  const [ageError, setAgeError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [specialtyError, setSpecialtyError] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [languageError, setLanguageError] = useState("");
  const [visitValue, setVisitValue] = useState("");
  const handleChangeVisit = useCallback(
    (newValue) => setVisitValue(newValue),
    []
  );
  const [checkedOnline, setCheckedOnline] = useState(false);
  const handleChangeOnline = useCallback(
    (newChecked) => setCheckedOnline(newChecked),
    []
  );
  const [checkedMembersOnly, setCheckedMembersOnly] = useState(false);
  const handleChangeMmembersOnly = useCallback((newChecked) => {
    setCheckedMembersOnly(newChecked);
    setMembersDiscountValue("");
  });
  const [checkedIsFree, setCheckedIsFree] = useState(false);
  const handleChangeIsFree = useCallback((newChecked) => {
    setCheckedIsFree(newChecked);
    setPriceValue("");
    setDiscountPriceValue("");
    setMembersDiscountValue("");
  });
  useEffect(() => {
    var a = [18, 32];
    console.log(eval(a.join("+")) / a.length);
    fetchData();
  }, []);

  async function fetchData() {
    let responseAges = "";
    let responseLanguages = "";
    let responseCountries = "";
    let responseLabels = "";
    let responseCategories = "";
    let responseSpeakers = "";
    let responseSupportGroups = "";
    try {
      responseAges = await axios.get(`/admin/age-range`);
      responseAges?.data &&
        setOptionsAges(
          responseAges.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseLanguages = await axios.get(`/admin/languages`);
      responseLanguages?.data &&
        setOptionsLanguages(
          responseLanguages.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseCountries = await axios.get(`/admin/countries`);
      responseCountries?.data &&
        setOptionsCountries(
          responseCountries.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseLabels = await axios.get(`/admin/labels`);
      responseLabels?.data?.data &&
        setOptionsLabels(
          responseLabels.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseCategories = await axios.get(`/admin/categories`);
      responseCategories?.data?.data &&
        setOptionsCategories(
          responseCategories.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseSpeakers = await axios.get(`/admin/speakers`);
      responseSpeakers?.data?.data &&
        setOptionsSpeakers(
          responseSpeakers.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseSupportGroups = await axios.get(`/admin/support-groups`);
      responseSupportGroups?.data?.data &&
        setOptionsSupportGroups(
          responseSupportGroups.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    setIsSaving(false);
  }
  const [checkedYearlyPrice, setCheckedYearlyPrice] = useState(false);
  const handleChangeYearlyPrice = useCallback(
    (newChecked) => setCheckedYearlyPrice(newChecked),
    []
  );
  const [eventTypeValue, setEventTypeValue] = useState("onetime");
  const handleChangeEventType = useCallback(
    (_checked, newValue) => setEventTypeValue(newValue),
    []
  );
  return (
    <Page title="Add Event" breadcrumbs={[{ url: "/admin/events/items" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField value={titleEnValue} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField value={titleArValue} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>

          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={desktopImagePreview ? desktopImagePreview : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Address En</TextStyle>
              <TextField
                value={addressEnValue}
                onChange={handleChangeAddressEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Address Ar</TextStyle>
              <TextField
                value={addressArValue}
                onChange={handleChangeAddressAr}
              />
            </FormLayout>
          </FormLayout.Group>
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Degree En</TextStyle>
              <TextField
                value={degreeEnValue}
                onChange={handleChangeDegreeEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Degree Ar</TextStyle>
              <TextField
                value={degreeArValue}
                onChange={handleChangeDegreeAr}
              />
            </FormLayout>
          </FormLayout.Group> */}
          <TextStyle variation="strong">Event Type</TextStyle>
          <Stack horizontal>
            <RadioButton
              label="One Time Event"
              checked={eventTypeValue === "onetime"}
              id="onetime"
              onChange={handleChangeEventType}
            />
            <RadioButton
              label="Recurring Event"
              id="recurring"
              checked={eventTypeValue === "recurring"}
              onChange={handleChangeEventType}
            />
          </Stack>
          {eventTypeValue === "onetime" ? (
            <FormLayout.Group>
              <FormLayout>
                <TextStyle>Date</TextStyle>
                <TextField
                  value={dateValue}
                  onChange={handleChangeDate}
                  type="date"
                  error={dateError}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle>Time</TextStyle>
                <TextField
                  value={timeValue}
                  onChange={handleChangeTime}
                  type="time"
                  error={timeError}
                />
              </FormLayout>
            </FormLayout.Group>
          ) : (
            <FormLayout>
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle>Schedule En</TextStyle>
                  <TextField
                    value={schedEnValue}
                    onChange={handleChangeSchedEn}
                    // type="date"
                  />
                </FormLayout>

                <FormLayout>
                  <TextStyle>Schedule Ar</TextStyle>
                  <TextField
                    value={schedArValue}
                    onChange={handleChangeSchedAr}
                    // type="time"
                    // error={timeError}
                  />
                </FormLayout>
              </FormLayout.Group>
              <InlineError message={schedError} />
            </FormLayout>
          )}
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Price</TextStyle>
              <TextField
                value={priceValue}
                onChange={handleChangePrice}
                type="number"
                disabled={checkedIsFree && true}
                placeholder="Enter a price here"
                connectedLeft={
                  <Checkbox
                    label="Is Free"
                    checked={checkedIsFree}
                    onChange={handleChangeIsFree}
                  />
                }
              />
              <Checkbox
                label="Yearly Price"
                checked={checkedYearlyPrice}
                onChange={handleChangeYearlyPrice}
              />
            </FormLayout>
            {/* <FormLayout>
              <TextStyle variation="strong">Discounted Price</TextStyle>
              <TextField
                value={discountPriceValue}
                onChange={handleChangeDiscountPrice}
                type="number"
                disabled={checkedIsFree && true}
              />
            </FormLayout> */}
          </FormLayout.Group>
          <Checkbox
            label="Members Only"
            checked={checkedMembersOnly}
            onChange={handleChangeMmembersOnly}
          />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Members Discount</TextStyle>
              <TextField
                value={membersDiscountValue}
                onChange={handleChangeMembersDiscount}
                type="number"
                max={100}
                prefix="%"
                helpText="Enter a number between 0 and 100"
                disabled={checkedIsFree || (checkedMembersOnly && true)}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Available Seats</TextStyle>
              <TextField
                value={seatsValue}
                onChange={handleChangeSeats}
                type="number"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Actual Seats</TextStyle>
              <TextField
                value={actualSeatsValue}
                onChange={handleChangeActualSeats}
                type="number"
              />
            </FormLayout>
          </FormLayout.Group>
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Years of experience En</TextStyle>
              <TextField
                value={yearsOfExperienceEnValue}
                onChange={handleChangeYearsOfExperienceEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Years of experience Ar</TextStyle>
              <TextField
                value={yearsOfExperienceArValue}
                onChange={handleChangeYearsOfExperienceAr}
              />
            </FormLayout>
          </FormLayout.Group> */}
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Phone Number En</TextStyle>
              <TextField
                value={phoneNumberEnValue}
                onChange={handleChangePhoneNumberEn}
              ></TextField>
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Phone Number Ar</TextStyle>
              <TextField
                value={phoneNumberArValue}
                onChange={handleChangePhoneNumberAr}
              ></TextField>
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Email</TextStyle>
          <TextField
            value={emailValue}
            onChange={handleChangeEmail}
          ></TextField> */}

          {/* <TextStyle variation="strong">Tags</TextStyle>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            /> */}

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Summary En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // initialValue="<p>This is the initial content of the editor</p>"
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Summary Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={summaryError} />
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Services En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={servicesEnValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeServicesEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Services Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={servicesArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeServicesAr}
              />
            </FormLayout>
          </FormLayout.Group> */}

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Profile En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={profileEnValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeProfileEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Profile Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={profileArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeProfileAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={profileError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Terms En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={profileEnValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTermsEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Terms Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={profileArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTermsAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout>
            {/* <Select
              options={optionsCountries}
              onChange={handleSelectCountry}
              value={selectedCountry}
              placeholder="Please select a country"
            /> */}
            {/* <TextStyle variation="strong">Website</TextStyle>
            <TextField
              value={visitValue}
              onChange={handleChangeVisit}
              autoComplete="off"
            /> */}
            {/* <TextStyle variation="strong">Specialty</TextStyle>
            <Select
              options={optionsSpecialicities}
              value={selectedSpeciality}
              onChange={handleSelectSpecialicity}
              placeholder="Please select a specialty"
            />
            <InlineError message={specialtyError} /> */}

            {/* <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Specialties En</TextStyle>

                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  // value={specialtiesEnValue}
                  init={{
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onEditorChange={handleChangeSpecialtyEn}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Specialty Ar</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  // value={specialtiesEnValue}
                  init={{
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onEditorChange={handleChangeSpecialtyAr}
                />
              </FormLayout>
            </FormLayout.Group> */}

            {/* <TextStyle variation="strong">Organizations</TextStyle>
            <MultiSelect
              options={optionsOrganizations}
              value={selectedOrganizations}
              onChange={setSelectedOrganizations}
              labelledBy="Select"
            />
            <TextStyle variation="strong">Disorders</TextStyle>
            <MultiSelect
              options={optionsDisorders}
              value={selectedDisorders}
              onChange={setSelectedDisorders}
              labelledBy="Select"
            /> */}
            {/* <TextStyle variation="strong">Contributions</TextStyle>
            <MultiSelect
              options={optionsContributions}
              value={selectedContributions}
              onChange={setSelectedContributions}
              labelledBy="Please select"
            />
            <TextStyle variation="strong">Countries</TextStyle>
            <MultiSelect
              options={optionsCountries}
              value={selectedCountries}
              onChange={setSelectedCountries}
              labelledBy="Please select a country"
            /> */}
            {/* //a */}
            {/* <TextStyle variation="strong">Gender</TextStyle>
            <Stack horizontal>
              <RadioButton
                label="Male"
                checked={valueGender === "male"}
                id="male"
                name="male"
                onChange={handleChangeGender}
              />
              <RadioButton
                label="Female"
                id="female"
                name="female"
                checked={valueGender === "female"}
                onChange={handleChangeGender}
              />
            </Stack>
            <InlineError message={genderError} /> */}
            <FormLayout.Group>
              {/* <FormLayout>
                <TextStyle variation="strong">Countries</TextStyle>
                <MultiSelect
                  options={optionsCountries}
                  value={selectedCountries}
                  onChange={setSelectedCountries}
                  labelledBy="Please select a country"
                />
              </FormLayout> */}
              <FormLayout>
                <TextStyle variation="strong">Age</TextStyle>
                <MultiSelect
                  options={optionsAges}
                  value={selectedAges}
                  onChange={setSelectedAges}
                  labelledBy="Please select age"
                />
              </FormLayout>
            </FormLayout.Group>

            <InlineError message={ageError} />
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Categories</TextStyle>
                <MultiSelect
                  options={optionsCategories}
                  value={selectedCategories}
                  onChange={setSelectedCategories}
                  labelledBy="Please select a category"
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Languages</TextStyle>
                <MultiSelect
                  options={optionsLanguages}
                  value={selectedLanguages}
                  onChange={setSelectedLanguages}
                  labelledBy="Please select a language"
                />
              </FormLayout>
            </FormLayout.Group>
            <InlineError message={categoriesError} />
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Speakers</TextStyle>
                <MultiSelect
                  options={optionsSpeakers}
                  value={selectedSpeakers}
                  onChange={setSelectedSpeakers}
                  labelledBy="Please select a speaker"
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Support Groups</TextStyle>
                <MultiSelect
                  options={optionsSupportGroups}
                  value={selectedSupportGroups}
                  onChange={setSelectedSupportGroups}
                  labelledBy="Please select a support group"
                />
              </FormLayout>
            </FormLayout.Group>

            <InlineError message={speakersError} />
          </FormLayout>
          <Checkbox
            label="Is Online"
            checked={checkedOnline}
            onChange={handleChangeOnline}
          />

          <Checkbox
            label="Is Active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      (!titleEnValue && !titleArValue) ||
      (eventTypeValue === "onetime" && !dateValue) ||
      (eventTypeValue === "onetime" && !timeValue) ||
      (eventTypeValue === "recurring" && !schedEnValue && !schedArValue)
    ) {
      !titleEnValue && !titleArValue && setTitleError("Please enter a title");

      eventTypeValue === "onetime" &&
        !dateValue &&
        setDateError("Please select a date");
      eventTypeValue === "onetime" &&
        !timeValue &&
        setTimeError("Please select a time");
      eventTypeValue === "recurring" &&
        !schedEnValue &&
        !schedArValue &&
        setSchedArValue("Please enter a text");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      eventTypeValue === "onetime" &&
        dateValue &&
        form_data.append("date[en]", dateValue + " " + timeValue);
      eventTypeValue === "onetime" &&
        dateValue &&
        form_data.append("date[ar]", dateValue + " " + timeValue);
      form_data.append("multiple_times", eventTypeValue === "onetime" ? 0 : 1);
      eventTypeValue === "recurring" &&
        schedEnValue &&
        form_data.append("date[en]", schedEnValue);
      eventTypeValue === "recurring" &&
        schedArValue &&
        form_data.append("date[ar]", schedArValue);
      titleEnValue && form_data.append("title[en]", titleEnValue);
      titleArValue && form_data.append("title[ar]", titleArValue);
      textEnValue && form_data.append("brief[en]", textEnValue);
      textArValue && form_data.append("brief[ar]", textArValue);
      profileEnValue && form_data.append("about[en]", profileEnValue);
      profileArValue && form_data.append("about[ar]", profileArValue);
      degreeEnValue && form_data.append("degree[en]", degreeEnValue);
      degreeArValue && form_data.append("degree[ar]", degreeArValue);
      selectedLanguages.forEach((selected, index) =>
        form_data.append(`languages[${index}]`, selected.value)
      );
      selectedCountries.forEach((selected, index) =>
        form_data.append(`countries[${index}]`, selected.value)
      );
      selectedAges.forEach((selected, index) =>
        form_data.append(`age_range[${index}]`, selected.value)
      );
      selectedSupportGroups.forEach((selected, index) =>
        form_data.append(`groups[${index}]`, selected.value)
      );
      selectedCategories.forEach((selected, index) =>
        form_data.append(`categories[${index}]`, selected.value)
      );
      selectedSpeakers.forEach((selected, index) =>
        form_data.append(`speakers[${index}]`, selected.value)
      );
      files[files.length - 1] &&
        form_data.append("image", files[files.length - 1]);

      form_data.append("show", checkedShow ? 1 : 0);
      form_data.append("online", checkedOnline ? 1 : 0);
      form_data.append("highlighted", 1);
      form_data.append("order", 1);
      form_data.append("members_only", checkedMembersOnly ? 1 : 0);
      priceValue && form_data.append("price", priceValue);
      discountPriceValue != "" &&
        form_data.append("discounted_price", discountPriceValue);
      seatsValue && form_data.append("available_seats", seatsValue);
      membersDiscountValue &&
        form_data.append("members_discount", membersDiscountValue);
      titleEnValue && form_data.append("alias", convertToSlug(titleEnValue));

      addressEnValue && form_data.append("address[en]", addressEnValue);
      addressArValue && form_data.append("address[ar]", addressArValue);
      termsEnValue && form_data.append("terms[en]", termsEnValue);
      termsArValue && form_data.append("terms[ar]", termsArValue);
      form_data.append("yearly_price", checkedYearlyPrice ? 1 : 0);
      actualSeatsValue !== "" &&
        form_data.append("actual_seats", actualSeatsValue);
      axios
        .post("/admin/events", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/events/items");
          }
        })
        .catch((err) => console.log(""));
    }
  }
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
  function findArrayElementByName(array, value) {
    return array.find((element, index) => {
      return element.name.en.toLowerCase() == value.toLowerCase();
    });
  }
  function findArrayProfessionByName(array, value) {
    return array.find((element, index) => {
      return element.value.en.toLowerCase() == value.toLowerCase();
    });
  }
  function average(a, b) {
    // force the input as numbers *1
    return (a * 1 + b * 1) / 2;
  }
  function isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0;
  }
  function isUrl(s) {
    var regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }
};
export default Add;
