import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../Assets/Lib/history";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const handleSelectChange = (newValue) => {
    setItem({ ...item, selected: newValue });
    setTypeError("");
  };

  const options = [
    { label: "Team", value: "team" },
    { label: "Founder", value: "founder" },
    { label: "Advisor", value: "advisor" },
  ];
  const [typeError, setTypeError] = useState("");
  const handleChangeFacebook = (newValue) => {
    setItem({ ...item, facebook: newValue });
  };
  const handleChangeYoutube = (newValue) => {
    setItem({ ...item, youtube: newValue });
  };
  const handleChangePhone = (newValue) => {
    setItem({ ...item, phone: newValue });
  };
  const handleChangeEmail = (newValue) => {
    setItem({ ...item, email: newValue });
  };
  const handleChangeAlias = (newValue) => {
    setItem({ ...item, alias: newValue });
  };
  const handleChangeProfileEn = (newValue) => {
    setItem({ ...item, profileEn: newValue });
  };
  const handleChangeProfileAr = (newValue) => {
    setItem({ ...item, profileAr: newValue });
  };

  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, titleEn: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, titleAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangePositionEn = (newValue) => {
    setItem({ ...item, positionEn: newValue });
  };
  const handleChangePositionAr = (newValue) => {
    setItem({ ...item, positionAr: newValue });
  };
  const handleChangeTextEn = (newValue) => {
    setItem({ ...item, textEn: newValue });
    setTextError("");
  };
  const handleChangeTextAr = (newValue) => {
    setItem({ ...item, textAr: newValue });
    setTextError("");
  };
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setDesktopImageError("");
  };
  const [checkedIsPresident, setCheckedIsPresident] = useState(false);
  const handleChangeIsPresident = (newValue) => {
    setItem({ ...item, isPresident: newValue });
  };
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = (newValue) => setItem({ ...item, show: newValue });
  const [item, setItem] = useState({
    titleEn: "",
    textEn: "",
    titleAr: "",
    textAr: "",
    positionEn: "",
    positionAr: "",
    image: "",
    isPresident: "",
    show: "",
    selected: "",
    facebook: "",
    youtube: "",
    phone: "",
    email: "",
    profileEn: "",
    profileAr: "",
    alias: "",
  });

  useEffect(() => {
    axios
      .get(`admin/teams/${id}`)
      .then((result) => {
        setItem({
          selected: String(result?.data?.type),
          image: result?.data?.image,
          titleEn: result?.data?.name?.en ? result.data.name.en : "",
          titleAr: result?.data?.name?.ar ? result.data.name.ar : "",
          textEn: result?.data?.brief?.en ? result.data.brief.en : "",
          textAr: result?.data?.brief?.ar ? result.data.brief.ar : "",
          positionEn: result?.data?.position?.en ? result.data.position.en : "",
          positionAr: result?.data?.position?.ar ? result.data.position.ar : "",
          isPresident: result?.data?.is_president ? true : false,
          show: result?.data?.show == 0 ? false : true,
          profileEn: result?.data?.profile?.en ? result.data.profile.en : "",
          profileAr: result?.data?.profile?.ar ? result.data.profile.ar : "",
          email: result?.data?.email ? result.data.email : "",
          phone: result?.data?.phone?.en ? result.data.phone?.en : "",
          facebook: result?.data?.social_media?.facebook
            ? result.data.social_media?.facebook
            : "",

          youtube: result?.data?.social_media?.youtube
            ? result.data.social_media?.youtube
            : "",
          alias: result?.data?.alias ? result.data.alias : "",
          // phone: result?.data?.phone
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page title="Edit" breadcrumbs={[{ url: "/admin/team-founder-advisor" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Type</TextStyle>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={item.selected}
            placeholder="Please Select"
            error={typeError}
          />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField value={item.titleEn} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField value={item.titleAr} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Phone Number</TextStyle>
              <TextField value={item.phone} onChange={handleChangePhone} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Email</TextStyle>
              <TextField value={item.email} onChange={handleChangeEmail} />
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Alias</TextStyle>
          <TextField value={item.alias} onChange={handleChangeAlias} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Position En</TextStyle>
              <TextField
                value={item.positionEn}
                onChange={handleChangePositionEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Position Ar</TextStyle>
              <TextField
                value={item.positionAr}
                onChange={handleChangePositionAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Facebook Link</TextStyle>
              <TextField
                value={item.facebook}
                onChange={handleChangeFacebook}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Youtube Link</TextStyle>
              <TextField value={item.youtube} onChange={handleChangeYoutube} />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Brief En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.textEn}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Brief Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.textAr}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Profile En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.profileEn}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeProfileEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Profile Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.profileAr}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeProfileAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={item.image ? item.image : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>
          {/* <Checkbox
            label="Is President"
            checked={item.isPresident}
            onChange={handleChangeIsPresident}
          /> */}
          <Checkbox
            label="Is Active"
            checked={item.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/teams/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/team-founder-advisor`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if ((!item.titleEn && !item.titleAr) || (!item.textEn && !item.textAr)) {
      !item.titleEn && !item.titleAr && setTitleError("Please enter a name");
      !item.textEn && !item.textAr && setTextError("Please enter a text");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("id", id);
      item.titleEn && form_data.append("name[en]", item.titleEn);
      item.titleAr && form_data.append("name[ar]", item.titleAr);
      item.positionEn && form_data.append("position[en]", item.positionEn);
      item.positionEn && form_data.append("position[ar]", item.positionAr);
      item.textEn && form_data.append("brief[en]", item.textEn);
      item.textAr && form_data.append("brief[ar]", item.textAr);
      files.length && form_data.append("image", files[files.length - 1]);
      form_data.append("order", 1);
      // form_data.append("is_manager", 1);
      // form_data.append("is_president", item.isPresident ? 1 : 0);
      form_data.append("show", item.show ? 1 : 0);
      item.profileEn && form_data.append("profile[en]", item.profileEn);
      item.profileAr && form_data.append("profile[ar]", item.profileAr);
      item.alias && form_data.append("alias", item.alias);
      files.length && form_data.append("image", files[files.length - 1]);
      form_data.append("type", item.selected);
      form_data.append("order", 1);
      item.facebook &&
        form_data.append("social_media[facebook]", item.facebook);
      item.youtube && form_data.append("social_media[youtube]", item.youtube);
      item.phone && form_data.append("phone[en]", item.phone);
      item.email && form_data.append("email", item.email);
      axios
        .post("/admin/teams/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/team-founder-advisor");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Edit;
