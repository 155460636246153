import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Lib/history";
import List from "./List";
import Add from "./Add";
import NavigationLayout from "../../Components/NavigationLayout";
import Edit from "./Edit";

const News = () => {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/news" component={List} />
          <Route path="/admin/news/new" component={Add} />
          <Route path="/admin/news/:id" component={Edit} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
};
export default News;
