import React, { useCallback, useState, useEffect } from "react";
import {
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Select,
  TextStyle,
  Loading,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import { Editor } from "@tinymce/tinymce-react";

const Add = () => {
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = useCallback((newValue) => {
    setTextEnValue(newValue);
    setTextError("");
  }, []);
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = useCallback((newValue) => {
    setTextArValue(newValue);
    setTextError("");
  }, []);
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const handleSelectChange = useCallback((value) => {
    setSelected(value);
    setCategoryError("");
  }, []);
  useEffect(() => {
    axios
      .get(`admin/faq-categories`)
      .then((result) => {
        setIsSaving(false);
        setOptions(
          result.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
            };
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page title="Add FAQ" breadcrumbs={[{ url: "/admin/faqs/items" }]}>
      {isSaving ? <Loading /> : null}

      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Question En</TextStyle>
              <TextField
                value={titleEnValue}
                onChange={handleChangeTitleEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Question Ar</TextStyle>
              <TextField
                value={titleArValue}
                onChange={handleChangeTitleAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Answer En</TextStyle>

              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textEnValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Answer Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textArValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />
          <TextStyle variation="strong">Category</TextStyle>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
            placeholder="Please select a category"
            error={categoryError}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      (!titleEnValue && !titleArValue) ||
      (!textEnValue && !textArValue) ||
      !selected
    ) {
      !titleEnValue &&
        !titleArValue &&
        setTitleError("Please enter a questinon");
      !textEnValue && !textArValue && setTextError("Please enter an answer");
      !selected && setCategoryError("Please choose a category");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("show", 1);
      titleEnValue && form_data.append("title[en]", titleEnValue);
      titleArValue && form_data.append("title[ar]", titleEnValue);
      textEnValue && form_data.append("text[en]", textEnValue);
      textArValue && form_data.append("text[ar]", textArValue);
      selected && form_data.append("category_id", selected);
      axios
        .post("admin/faqs", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/faqs/items");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Add;
