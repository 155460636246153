import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const handleChangeTitleEn = (newValue) => {
    setArticle({ ...article, titleEn: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setArticle({ ...article, titleAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeTextEn = (newValue) => {
    setArticle({ ...article, textEn: newValue });
    setTextError("");
  };
  const handleChangeTextAr = (newValue) => {
    setArticle({ ...article, textAr: newValue });
    setTextError("");
  };
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [mobileImagePreview, setMobileImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [mobileImageError, setMobileImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setArticle({
      ...article,
      desktopImage:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setDesktopImageError("");
  };

  const [filesMobile, setFilesMobile] = useState([]);
  const handleDropZoneMobile = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesMobile((filesMobile) => [...filesMobile, ...acceptedFiles]),
    []
  );
  const fileUploadMobile = !filesMobile.length && <DropZone.FileUpload />;
  let uploadedFilesMobile = filesMobile.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesMobile[filesMobile.length - 1].name}
        source={
          validImageTypes.indexOf(filesMobile[filesMobile.length - 1].type) > 0
            ? window.URL.createObjectURL(filesMobile[filesMobile.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesMobile[filesMobile.length - 1].name}{" "}
        <Caption>{filesMobile[filesMobile.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageMobile = (filesMobile) => {
    setArticle({
      ...article,
      mobileImage:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setMobileImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const handleChangeShow = (newValue) => {
    setArticle({ ...article, show: newValue });
  };

  const [article, setArticle] = useState({
    titleEn: "",
    textEn: "",
    titleAr: "",
    textAr: "",
    desktopImage: "",
    mobileImage: "",
    tags: "",
    show: false,
  });
  useEffect(() => {
    Promise.all([
      axios.get(`admin/tags`),
      axios.get(`admin/treatments-and-medications/${id}`),
    ]).then(function (responses) {
      //setPopupActive(false);
      const responseTags = responses[0];
      const responseShowArticle = responses[1].data;
      setOptions(
        responseTags.data.data.map((item, index) => {
          return {
            label: item.name[Object.keys(item.name)[0]],
            value: item.id,
          };
        })
      );
      setSelected(
        responseShowArticle.tags.map((item, index) => {
          return { label: item.name.en, value: item.id };
        })
      );

      setArticle({
        titleEn: responseShowArticle?.title?.en
          ? responseShowArticle.title.en
          : "",
        titleAr: responseShowArticle?.title?.ar
          ? responseShowArticle.title.ar
          : "",
        textEn: responseShowArticle?.text?.en
          ? responseShowArticle.text.en
          : "",
        textAr: responseShowArticle?.text?.ar
          ? responseShowArticle.text.ar
          : "",
        desktopImage: responseShowArticle?.image
          ? responseShowArticle.image
          : "",
        mobileImage: responseShowArticle?.image_mob
          ? responseShowArticle.image_mob
          : "",
        show: responseShowArticle.show == 0 ? false : true,
      });
    });
  }, []);
  return (
    <Page
      title="Edit Item"
      breadcrumbs={[{ url: "/admin/treatments-and-medications" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={article.titleEn}
                onChange={handleChangeTitleEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={article.titleAr}
                onChange={handleChangeTitleAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Text En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={article.textEn}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Text Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={article.textAr}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Desktop Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={article.desktopImage ? article.desktopImage : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Mobile Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={article.mobileImage ? article.mobileImage : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone
                    onDrop={handleDropZoneMobile}
                    onDropAccepted={sendImageMobile}
                  >
                    {uploadedFilesMobile}
                    {fileUploadMobile}
                  </DropZone>
                </div>
              </div>
              <InlineError message={mobileImageError} />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout>
            <TextStyle variation="strong">Tags</TextStyle>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            />
          </FormLayout>
          <Checkbox
            label="Is Active"
            checked={article.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/treatments-and-medications/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/treatments-and-medications`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (
      (!article.titleEn && !article.titleAr) ||
      (!article.textEn && !article.textAr)
    ) {
      !article.titleEn &&
        !article.titleAr &&
        setTitleError("Please enter a title");
      !article.textEn && !article.textAr && setTextError("Please enter a text");
    } else {
      let fruits = ["Apple", "Banana"];
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("id", id);
      article.titleEn && form_data.append("title[en]", article.titleEn);
      article.titleAr && form_data.append("title[ar]", article.titleAr);
      article.textEn && form_data.append("text[en]", article.textEn);
      article.textAr && form_data.append("text[ar]", article.textAr);
      selected.forEach((selected, index) =>
        form_data.append(`tags[${index}]`, selected.value)
      );
      files.length && form_data.append("image", files[files.length - 1]);
      filesMobile.length &&
        form_data.append("image_mob", filesMobile[filesMobile.length - 1]);
      form_data.append("show", article.show ? 1 : 0);
      form_data.append("highlighted", 1);
      form_data.append("order", 1);

      axios
        .post("/admin/treatments-and-medications/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/treatments-and-medications");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Edit;
