import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, titleEn: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, titleAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeOccupationEn = (newValue) => {
    setItem({ ...item, occupationEn: newValue });
  };
  const handleChangeOccupationAr = (newValue) => {
    setItem({ ...item, occupationAr: newValue });
  };
  const handleChangeTextEn = (newValue) => {
    setItem({ ...item, textEn: newValue });
    setTextError("");
  };
  const handleChangeTextAr = (newValue) => {
    setItem({ ...item, textAr: newValue });
    setTextError("");
  };
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setDesktopImageError("");
  };
  const [isPresident, setIsPresident] = useState("");
  const handleChangeIsPresident = (newValue) => {
    setItem({ ...item, isPresident: newValue });
  };
  const [isManager, setIsManager] = useState("");
  const handleChangeIsManager = (newValue) => {
    setItem({ ...item, isManager: newValue });
  };
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = (newValue) => setItem({ ...item, show: newValue });
  const [item, setItem] = useState({
    titleEn: "",
    textEn: "",
    titleAr: "",
    textAr: "",
    occupationEn: "",
    occupationAr: "",
    image: "",
    isPresident: "",
    isManager: "",
    show: "",
  });
  useEffect(() => {
    axios
      .get(`admin/members/${id}`)
      .then((result) => {
        setItem({
          image: result?.data?.image,
          titleEn: result?.data?.name?.en ? result.data.name.en : "",
          titleAr: result?.data?.name?.ar ? result.data.name.ar : "",
          textEn: result?.data?.text?.en ? result.data.text.en : "",
          textAr: result?.data?.text?.ar ? result.data.text.ar : "",
          occupationEn: result?.data?.occupation?.en
            ? result.data.occupation.en
            : "",
          occupationAr: result?.data?.occupation?.ar
            ? result.data.occupation.ar
            : "",
          isPresident: result?.data?.is_president ? true : false,
          isManager: result?.data?.is_manager ? true : false,
          show: result?.data?.show == 0 ? false : true,
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page title="Edit Member" breadcrumbs={[{ url: "/admin/members" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField value={item.titleEn} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField value={item.titleAr} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Occupation En</TextStyle>
              <TextField
                value={item.occupationEn}
                onChange={handleChangeOccupationEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Occupation Ar</TextStyle>
              <TextField
                value={item.occupationAr}
                onChange={handleChangeOccupationAr}
              />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Text En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.textEn}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Text Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.textAr}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={item.image ? item.image : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>
          <Checkbox
            label="Is President"
            checked={item.isPresident}
            onChange={handleChangeIsPresident}
          />
          <Checkbox
            label="Is Manager"
            checked={item.isManager}
            onChange={handleChangeIsManager}
          />
          <Checkbox
            label="Is Active"
            checked={item.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/members/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/members`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if ((!item.titleEn && !item.titleAr) || (!item.textEn && !item.textAr)) {
      !item.titleEn && !item.titleAr && setTitleError("Please enter a name");
      !item.textEn && !item.textAr && setTextError("Please enter a text");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("id", id);
      item.titleEn && form_data.append("name[en]", item.titleEn);
      item.titleAr && form_data.append("name[ar]", item.titleAr);
      item.occupationEn &&
        form_data.append("occupation[en]", item.occupationEn);
      item.occupationEn &&
        form_data.append("occupation[ar]", item.occupationAr);
      item.textEn && form_data.append("text[en]", item.textEn);
      item.textAr && form_data.append("text[ar]", item.textAr);
      files.length && form_data.append("image", files[files.length - 1]);
      form_data.append("order", 1);
      form_data.append("is_manager", item.isManager ? 1 : 0);
      form_data.append("is_president", item.isPresident ? 1 : 0);
      form_data.append("show", item.show ? 1 : 0);

      axios
        .post("/admin/members/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/members");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Edit;
