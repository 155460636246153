import React, { useCallback, useState, useEffect } from "react";
import {
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Select,
  TextStyle,
  Loading,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { useParams } from "react-router-dom";
import history from "../../../Assets/Lib/history";
import { Editor } from "@tinymce/tinymce-react";
const Edit = () => {
  var PromiseAll = require("promises-all");
  const { id } = useParams();
  const handleChangeTitleEn = (newValue) => {
    setFaq({ ...faq, titleEn: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setFaq({ ...faq, titleAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeTextEn = (newValue) => {
    setFaq({ ...faq, textEn: newValue });
    setTextError("");
  };
  const handleChangeTextAr = (newValue) => {
    setFaq({ ...faq, textAr: newValue });
    setTextError("");
  };
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const [faq, setFaq] = useState({
    titleEn: "",
    textEn: "",
    titleAr: "",
    textAr: "",
    categoryId: "",
  });
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const handleSelectChange = useCallback((newValue) => {
    setFaq({ ...faq, categoryId: newValue });
    setCategoryError("");
  }, []);
  useEffect(() => {
    PromiseAll.all([getCategories(), getItem()]).then(function (response) {
      setIsSaving(false);
      if (typeof response.resolve[0] === "object") {
        setIsSaving(false);
        setOptions(
          response.resolve[0].data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: item.id,
            };
          })
        );
      }

      if (typeof response.resolve[1] === "object") {
        setFaq({
          titleEn: response.resolve[1]?.data?.title?.en
            ? response.resolve[1].data.title.en
            : "",
          titleAr: response.resolve[1]?.data?.title?.ar
            ? response.resolve[1].data.title.ar
            : "",
          textEn: response.resolve[1]?.data?.text?.en
            ? response.resolve[1].data.text.en
            : "",
          textAr: response.resolve[1]?.data?.text?.ar
            ? response.resolve[1].data.text.ar
            : "",
          categoryId: response.resolve[1]?.data?.category_id
            ? response.resolve[1].data.category_id
            : "",
        });
      }
    });
  }, []);

  const getCategories = () => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get("/admin/faq-categories")
          .then(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 0);
    });
  };
  const getItem = () => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get(`/admin/faqs/${id}`)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 1000);
    });
  };
  return (
    <Page title="Edit FAQ" breadcrumbs={[{ url: "/admin/faqs/items" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Question En</TextStyle>
              <TextField
                value={faq.titleEn}
                onChange={handleChangeTitleEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Question Ar</TextStyle>
              <TextField
                value={faq.titleAr}
                onChange={handleChangeTitleAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Answer En</TextStyle>

              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={faq.textEn}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Answer Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={faq.textAr}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />
          <TextStyle variation="strong">Category</TextStyle>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={faq.categoryId}
            placeholder="Please select a category"
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/faqs/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/faqs/items`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (
      (!faq.titleEn && !faq.titleAr) ||
      (!faq.textEn && !faq.textAr) ||
      !faq.categoryId
    ) {
      !faq.titleEn && !faq.titleAr && setTitleError("Please enter a question");
      !faq.textEn && !faq.textAr && setTitleError("Please enter a question");
      !faq.categoryId && setCategoryError("Please choose a category");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("id", id);
      form_data.append("show", 1);
      faq.titleEn && form_data.append("title[en]", faq.titleEn);
      faq.titleAr && form_data.append("title[ar]", faq.titleAr);
      faq.textEn && form_data.append("text[en]", faq.textEn);
      faq.textAr && form_data.append("text[ar]", faq.textAr);
      faq.categoryId && form_data.append("category_id", faq.categoryId);

      axios
        .post("admin/faqs/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/faqs/items");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Edit;
