import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Lib/history";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";
import Centers from "./Centers/Main";

const MainEdit = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/admin/useful-links/:id" component={Edit} />
        <Route path="/admin/useful-links/:id/centers" component={Centers} />
      </Switch>
    </Router>
  );
};
export default MainEdit;
