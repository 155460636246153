import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  RadioButton,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";
import Cookies from "js-cookie";

const Add = () => {
  const [titleEnValue, setTitleEnValue] = useState(
    Cookies.get("houna-lead") && JSON.parse(Cookies.get("houna-lead"))?.name
      ? JSON.parse(Cookies.get("houna-lead")).name
      : ""
  );
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState(
    Cookies.get("houna-lead") && JSON.parse(Cookies.get("houna-lead"))?.name_ar
      ? JSON.parse(Cookies.get("houna-lead")).name_ar
      : ""
  );
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [occupationEnValue, setOccupationEnValue] = useState("");
  const handleChangeOccupationEn = useCallback((newValue) => {
    setOccupationEnValue(newValue);
  }, []);
  const [occupationArValue, setOccupationArValue] = useState("");
  const handleChangeOccupationAr = useCallback((newValue) => {
    setOccupationArValue(newValue);
  }, []);
  const [yearsOfExperienceEnValue, setYearsOfExperienceEnValue] = useState("");
  const handleChangeYearsOfExperienceEn = useCallback((newValue) => {
    setYearsOfExperienceEnValue(newValue);
  }, []);
  const [yearsOfExperienceArValue, setYearsOfExperienceArValue] = useState("");
  const handleChangeYearsOfExperienceAr = useCallback((newValue) => {
    setYearsOfExperienceArValue(newValue);
  }, []);
  const [textEnValue, setTextEnValue] = useState(
    Cookies.get("houna-lead") &&
      JSON.parse(Cookies.get("houna-lead"))?.biography
      ? JSON.parse(Cookies.get("houna-lead")).biography
      : ""
  );
  const handleChangeTextEn = (newValue) => {
    setTextEnValue(newValue);
    setTextError("");
  };
  const [textArValue, setTextArValue] = useState(
    Cookies.get("houna-lead") &&
      JSON.parse(Cookies.get("houna-lead"))?.biography_ar
      ? JSON.parse(Cookies.get("houna-lead")).biography_ar
      : ""
  );
  const handleChangeTextAr = (newValue) => {
    setTextArValue(newValue);
    setTextError("");
  };
  const [servicesEnValue, setServicesEnValue] = useState(
    Cookies.get("houna-lead") && JSON.parse(Cookies.get("houna-lead"))?.services
      ? JSON.parse(Cookies.get("houna-lead")).services
      : ""
  );
  const handleChangeServicesEn = (newValue) => {
    setServicesEnValue(newValue);
  };
  const [servicesArValue, setServicesArValue] = useState(
    Cookies.get("houna-lead") &&
      JSON.parse(Cookies.get("houna-lead"))?.services_ar
      ? JSON.parse(Cookies.get("houna-lead")).services_ar
      : ""
  );
  const handleChangeServicesAr = (newValue) => {
    setServicesArValue(newValue);
  };
  const [textError, setTextError] = useState("");
  const [profileEnValue, setProfileEnValue] = useState("");
  const handleChangeProfileEn = (newValue) => {
    setProfileEnValue(newValue);
    setProfileError("");
  };
  const [profileArValue, setProfileArValue] = useState("");
  const handleChangeProfileAr = (newValue) => {
    setProfileArValue(newValue);
    setProfileError("");
  };
  const [specialtiesEnValue, setSpecialtiesEnValue] = useState(
    Cookies.get("houna-lead") &&
      JSON.parse(Cookies.get("houna-lead"))?.specialties_text
      ? JSON.parse(Cookies.get("houna-lead")).specialties_text
      : ""
  );
  const handleChangeSpecialtiesEn = (newValue) => {
    setSpecialtiesEnValue(newValue);
  };
  const [specialtiesArValue, setSpecialtiesArValue] = useState(
    Cookies.get("houna-lead") &&
      JSON.parse(Cookies.get("houna-lead"))?.specialties_text_ar
      ? JSON.parse(Cookies.get("houna-lead")).specialties_text_ar
      : ""
  );
  const handleChangeSpecialtiesAr = (newValue) => {
    setSpecialtiesArValue(newValue);
  };
  const [phoneNumberEnValue, setPhoneNumberEnValue] = useState(
    Cookies.get("houna-lead") &&
      JSON.parse(Cookies.get("houna-lead"))?.phone_number
      ? JSON.parse(Cookies.get("houna-lead")).phone_number
      : ""
  );
  const handleChangePhoneNumberEn = useCallback((newValue) => {
    setPhoneNumberEnValue(newValue);
  }, []);
  const [phoneNumberArValue, setPhoneNumberArValue] = useState("");
  const handleChangePhoneNumberAr = useCallback((newValue) => {
    setPhoneNumberArValue(newValue);
  }, []);
  const [emailValue, setEmailValue] = useState(
    Cookies.get("houna-lead") && JSON.parse(Cookies.get("houna-lead"))?.email
      ? JSON.parse(Cookies.get("houna-lead")).email
      : ""
  );
  const handleChangeEmail = useCallback((newValue) => {
    setEmailValue(newValue);
  }, []);
  const [profileError, setProfileError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState(
    Cookies.get("houna-lead") && JSON.parse(Cookies.get("houna-lead"))?.image
      ? JSON.parse(Cookies.get("houna-lead")).image
      : ""
  );
  const [desktopImageError, setDesktopImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setDesktopImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setDesktopImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [optionsOrganizations, setOptionOrganizationss] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [optionsLanguages, setOptionsLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [optionsDisorders, setOptionsDisorders] = useState([]);
  const [selectedDisorders, setSelectedDisorders] = useState([]);
  const [optionsProfessions, setOptionsProfessions] = useState([]);
  const [selectedProfessions, setSelectedProfessions] = useState([]);
  const [optionsCountries, setOptionsCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [optionsContributions, setOptionsContributions] = useState([
    { label: "Content for Databank", value: "Content for Databank" },
    {
      label: "Run support group for Houna",
      value: "Run support group for Houna",
    },
    {
      label: "Content for social media posts",
      value: "Content for social media posts",
    },
    { label: "Instagram live sessions", value: "Instagram live sessions" },
    {
      label: "Provide free sessions to users",
      value: "Provide free sessions to users",
    },
    {
      label: "Provide discounted sessions to users",
      value: "Provide discounted sessions to users",
    },
    {
      label: "Review content for scientific and evidence-based accuracy",
      value: "Review content for scientific and evidence-based accuracy ",
    },
  ]);
  const [selectedContributions, setSelectedContributions] = useState(
    Cookies.get("houna-lead") &&
      JSON.parse(Cookies.get("houna-lead"))?.contribution_type
      ? JSON.parse(Cookies.get("houna-lead"))
          ?.contribution_type.split(",")
          .reduce((result, item, i) => {
            if (
              optionsContributions.filter((e) => e.value === item).length > 0
            ) {
              result.push({
                label: item,
                value: item,
              });
            }
            return result;
          }, [])
      : []
  );
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );
  // const [optionsCountries, setOptionsCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const handleSelectCountry = useCallback((newValue) => {
    setSelectedCountry(newValue);
    setCountryError("");
  }, []);
  const [optionsSpecialicities, setOptionsSpecialicities] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState("");
  const handleSelectSpecialicity = useCallback((newValue) => {
    setSelectedSpeciality(newValue);
    setSpecialtyError("");
  }, []);
  const [optionsAges, setOptionsAges] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);
  const [valueGender, setGender] = useState(
    Cookies.get("houna-lead") && JSON.parse(Cookies.get("houna-lead"))?.gender
      ? JSON.parse(Cookies.get("houna-lead")).gender
      : ""
  );
  const handleChangeGender = useCallback((_checked, newValue) => {
    setGender(newValue);
    setGenderError("");
  }, []);

  const [ageError, setAgeError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [specialtyError, setSpecialtyError] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [languageError, setLanguageError] = useState("");
  const [visitValue, setVisitValue] = useState("");
  const handleChangeVisit = useCallback(
    (newValue) => setVisitValue(newValue),
    []
  );
  const [checkedOnline, setCheckedOnline] = useState(false);
  const handleChangeOnline = useCallback(
    (newChecked) => setCheckedOnline(newChecked),
    []
  );
  const [checkedRecommended, setCheckedRecommended] = useState(false);
  const handleChangeRecommended = useCallback(
    (newChecked) => setCheckedRecommended(newChecked),
    []
  );
  const [youtubeValue, setYoutubeValue] = useState("");
  const handleChangeYoutube = (newValue) => {
    setYoutubeValue(newValue);
  };
  const [printerestValue, setPrinterestValue] = useState("");
  const handleChangePrinterest = (newValue) => {
    setPrinterestValue(newValue);
  };
  const [instagramValue, setInstagramValue] = useState("");
  const handleChangeInstagram = (newValue) => {
    setInstagramValue(newValue);
  };
  const [facebookValue, setFacebookValue] = useState("");
  const handleChangeFacebook = (newValue) => {
    setFacebookValue(newValue);
  };
  const [twitterValue, setTwitterValue] = useState("");
  const handleChangeTwitter = (newValue) => {
    setTwitterValue(newValue);
  };
  const [appStoreValue, setAppStoreValue] = useState("");
  const handleChangeAppStore = (newValue) => {
    setAppStoreValue(newValue);
  };
  const [playStoreValue, setPlayStoreValue] = useState("");
  const handleChangePlayStore = (newValue) => {
    setPlayStoreValue(newValue);
  };
  useEffect(() => {
    var a = [18, 32];
    console.log(eval(a.join("+")) / a.length);
    fetchData();
  }, []);

  async function fetchData() {
    let responseAges = "";
    let responseLanguages = "";
    let responseCountries = "";
    let responseSpecialties = "";
    let responseTags = "";
    let responseDisorders = "";
    let responseOrganizations = "";
    let responseProfessions = "";
    try {
      responseAges = await axios.get(`/admin/age-range`);
      responseAges?.data &&
        setOptionsAges(
          responseAges.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      if (
        Cookies.get("houna-lead") &&
        JSON.parse(Cookies.get("houna-lead")).min_age &&
        JSON.parse(Cookies.get("houna-lead")).max_age
      ) {
        let array = [];
        if (
          isBetween(JSON.parse(Cookies.get("houna-lead")).min_age, 0, 10) ||
          isBetween(JSON.parse(Cookies.get("houna-lead")).max_age, 0, 10)
        ) {
          array.push("Children");
        }
        if (
          isBetween(JSON.parse(Cookies.get("houna-lead")).min_age, 10, 20) ||
          isBetween(JSON.parse(Cookies.get("houna-lead")).max_age, 10, 20)
        ) {
          array.push("Adolescents ");
        }
        if (
          isBetween(JSON.parse(Cookies.get("houna-lead")).min_age, 20, 100) ||
          isBetween(JSON.parse(Cookies.get("houna-lead")).max_age, 20, 100)
        ) {
          array.push("Adults");
        }

        setSelectedAges(
          array.map((item, index) => {
            return {
              label: item,
              value: String(findArrayElementByName(responseAges.data, item).id),
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    }

    try {
      responseLanguages = await axios.get(`/admin/languages`);
      responseLanguages?.data &&
        setOptionsLanguages(
          responseLanguages.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      if (Cookies.get("houna-lead")) {
        JSON.parse(Cookies.get("houna-lead"))?.languages?.length &&
          setSelectedLanguages(
            JSON.parse(Cookies.get("houna-lead")).languages.reduce(
              (result, item, i) => {
                if (findArrayElementByName(responseLanguages.data, item)) {
                  result.push({
                    label: item,
                    value: String(
                      findArrayElementByName(responseLanguages.data, item).id
                    ),
                  });
                }
                return result;
              },
              []
            )
          );
      }
    } catch (error) {
      console.log(error);
    }

    try {
      responseCountries = await axios.get(`/admin/countries`);
      responseCountries?.data &&
        setOptionsCountries(
          responseCountries.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      if (Cookies.get("houna-lead")) {
        JSON.parse(Cookies.get("houna-lead"))?.country &&
          setSelectedCountry(
            String(
              findArrayElementByName(
                responseCountries.data,
                JSON.parse(Cookies.get("houna-lead")).country
              ).id
            )
          );
      }
    } catch (error) {
      console.log(error);
    }
    try {
      responseProfessions = await axios.get(`/admin/professions`);
      responseProfessions?.data?.data &&
        setOptionsProfessions(
          responseProfessions.data.data.map((item, index) => {
            return {
              label: item.value[Object.keys(item.value)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );

      if (Cookies.get("houna-lead")) {
        JSON.parse(Cookies.get("houna-lead"))?.profession?.length &&
          setSelectedProfessions(
            JSON.parse(Cookies.get("houna-lead")).profession.reduce(
              (result, item, i) => {
                if (
                  findArrayProfessionByName(responseProfessions.data.data, item)
                ) {
                  result.push({
                    label: item,
                    value: String(
                      findArrayProfessionByName(
                        responseProfessions.data.data,
                        item
                      ).id
                    ),
                  });
                }
                return result;
              },
              []
            )
          );
      }
    } catch (error) {
      console.log(error);
    }

    try {
      responseSpecialties = await axios.get(`/admin/therapists-speciality`);
      responseSpecialties?.data &&
        setOptionsSpecialicities(
          responseSpecialties.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseTags = await axios.get(`/admin/tags`);
      responseTags?.data &&
        setOptions(
          responseTags.data.data.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseOrganizations = await axios.get(`/admin/organizations`);
      responseOrganizations?.data &&
        setOptionOrganizationss(
          responseOrganizations.data.data.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    try {
      responseDisorders = await axios.get(`/admin/disorders`);
      responseDisorders?.data &&
        setOptionsDisorders(
          responseDisorders.data.data.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    setIsSaving(false);
  }

  return (
    <Page
      title="Add Therapist"
      breadcrumbs={[{ url: "/admin/therapists/items" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField value={titleEnValue} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField value={titleArValue} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Occupation En</TextStyle>
              <TextField
                value={occupationEnValue}
                onChange={handleChangeOccupationEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Occupation Ar</TextStyle>
              <TextField
                value={occupationArValue}
                onChange={handleChangeOccupationAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Years of experience En</TextStyle>
              <TextField
                value={yearsOfExperienceEnValue}
                onChange={handleChangeYearsOfExperienceEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Years of experience Ar</TextStyle>
              <TextField
                value={yearsOfExperienceArValue}
                onChange={handleChangeYearsOfExperienceAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Summary En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // initialValue="<p>This is the initial content of the editor</p>"
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Summary Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={summaryError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Services En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={servicesEnValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeServicesEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Services Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={servicesArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeServicesAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Phone Number En</TextStyle>
              <TextField
                value={phoneNumberEnValue}
                onChange={handleChangePhoneNumberEn}
              ></TextField>
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Phone Number Ar</TextStyle>
              <TextField
                value={phoneNumberArValue}
                onChange={handleChangePhoneNumberAr}
              ></TextField>
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Email</TextStyle>
          <TextField
            value={emailValue}
            onChange={handleChangeEmail}
          ></TextField>

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Profile En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={profileEnValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeProfileEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Profile Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={profileArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeProfileAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={profileError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={desktopImagePreview ? desktopImagePreview : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Youtube Link</TextStyle>
                <TextField
                  value={youtubeValue}
                  onChange={handleChangeYoutube}
                  autoComplete="off"
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Printerest Link</TextStyle>
                <TextField
                  value={printerestValue}
                  onChange={handleChangePrinterest}
                  autoComplete="off"
                />
              </FormLayout>
            </FormLayout.Group>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Instagram Link</TextStyle>
                <TextField
                  value={instagramValue}
                  onChange={handleChangeInstagram}
                  autoComplete="off"
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Facebook Link</TextStyle>
                <TextField
                  value={facebookValue}
                  onChange={handleChangeFacebook}
                  autoComplete="off"
                />
              </FormLayout>
            </FormLayout.Group>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Twitter Link</TextStyle>
                <TextField
                  value={twitterValue}
                  onChange={handleChangeTwitter}
                  autoComplete="off"
                />
              </FormLayout>
            </FormLayout.Group>
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">App Store Link</TextStyle>
                <TextField
                  value={appStoreValue}
                  onChange={handleChangeAppStore}
                  autoComplete="off"
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Play Store Link</TextStyle>
                <TextField
                  value={playStoreValue}
                  onChange={handleChangePlayStore}
                  autoComplete="off"
                />
              </FormLayout>
            </FormLayout.Group>
            {/* <Select
              options={optionsCountries}
              onChange={handleSelectCountry}
              value={selectedCountry}
              placeholder="Please select a country"
            /> */}
            <TextStyle variation="strong">Website</TextStyle>
            <TextField
              value={visitValue}
              onChange={handleChangeVisit}
              autoComplete="off"
            />
            <TextStyle variation="strong">Specialty</TextStyle>
            <Select
              options={optionsSpecialicities}
              value={selectedSpeciality}
              onChange={handleSelectSpecialicity}
              placeholder="Please select a specialty"
            />
            <InlineError message={specialtyError} />
            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Specialties En</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  // value={specialtiesEnValue}
                  init={{
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onEditorChange={handleChangeSpecialtiesEn}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Specialties Ar</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  // value={specialtiesArValue}
                  init={{
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onEditorChange={handleChangeSpecialtiesAr}
                />
              </FormLayout>
            </FormLayout.Group>
            <TextStyle variation="strong">Professions</TextStyle>
            <MultiSelect
              options={optionsProfessions}
              value={selectedProfessions}
              onChange={setSelectedProfessions}
              labelledBy="Please select a profession"
            />
            <TextStyle variation="strong">Age</TextStyle>
            <MultiSelect
              options={optionsAges}
              value={selectedAges}
              onChange={setSelectedAges}
              labelledBy="Please select age"
            />
            <InlineError message={ageError} />
            <TextStyle variation="strong">Tags</TextStyle>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            />
            <TextStyle variation="strong">Languages</TextStyle>
            <MultiSelect
              options={optionsLanguages}
              value={selectedLanguages}
              onChange={setSelectedLanguages}
              labelledBy="Please select a language"
            />
            <InlineError message={languageError} />
            <TextStyle variation="strong">Organizations</TextStyle>
            <MultiSelect
              options={optionsOrganizations}
              value={selectedOrganizations}
              onChange={setSelectedOrganizations}
              labelledBy="Select"
            />
            <TextStyle variation="strong">Disorders</TextStyle>
            <MultiSelect
              options={optionsDisorders}
              value={selectedDisorders}
              onChange={setSelectedDisorders}
              labelledBy="Select"
            />
            <TextStyle variation="strong">Contributions</TextStyle>
            <MultiSelect
              options={optionsContributions}
              value={selectedContributions}
              onChange={setSelectedContributions}
              labelledBy="Please select"
            />
            <TextStyle variation="strong">Countries</TextStyle>
            <MultiSelect
              options={optionsCountries}
              value={selectedCountries}
              onChange={setSelectedCountries}
              labelledBy="Please select a country"
            />
            {/* //a */}
            <InlineError message={countryError} />

            <TextStyle variation="strong">Gender</TextStyle>
            <Stack horizontal>
              <RadioButton
                label="Male"
                checked={valueGender === "male"}
                id="male"
                name="male"
                onChange={handleChangeGender}
              />
              <RadioButton
                label="Female"
                id="female"
                name="female"
                checked={valueGender === "female"}
                onChange={handleChangeGender}
              />
            </Stack>
            <InlineError message={genderError} />
          </FormLayout>
          <Checkbox
            label="Is Purely Online"
            checked={checkedOnline}
            onChange={handleChangeOnline}
          />
          <Checkbox
            label="Is Recommended"
            checked={checkedRecommended}
            onChange={handleChangeRecommended}
          />
          <Checkbox
            label="Is Active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      (!titleEnValue && !titleArValue) ||
      (!textEnValue && !textArValue) ||
      (!profileEnValue && !profileArValue) ||
      !selectedAges.length ||
      !selectedCountries.length ||
      valueGender == "" ||
      selectedSpeciality == "" ||
      !selectedLanguages.length
    ) {
      !titleEnValue && !titleArValue && setTitleError("Please enter a title");
      !textEnValue && !textArValue && setSummaryError("Please enter a summary");
      !profileEnValue &&
        !profileArValue &&
        setProfileError("Please enter a profile");
      !selectedAges.length && setAgeError("Please select an age");
      !selectedCountries.length && setCountryError("Please select a country");
      valueGender == "" && setGenderError("Please select a gender");
      selectedSpeciality == "" && setSpecialtyError("Please select an age");
      !selectedLanguages.length && setLanguageError("Please select a language");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      visitValue && form_data.append("website", visitValue);
      titleEnValue && form_data.append("name[en]", titleEnValue);
      titleArValue && form_data.append("name[ar]", titleArValue);
      textEnValue && form_data.append("summary[en]", textEnValue);
      textArValue && form_data.append("summary[ar]", textArValue);
      profileEnValue && form_data.append("profile[en]", profileEnValue);
      profileArValue && form_data.append("profile[ar]", profileArValue);
      selected.forEach((selected, index) =>
        form_data.append(`tags[${index}]`, selected.value)
      );
      selectedLanguages.forEach((selected, index) =>
        form_data.append(`languages[${index}]`, selected.value)
      );
      selectedOrganizations.forEach((selected, index) =>
        form_data.append(`organization[${index}]`, selected.value)
      );
      selectedDisorders.length &&
        selectedDisorders.forEach((selected, index) =>
          form_data.append(`disorders[${index}]`, selected.value)
        );
      selectedContributions.length &&
        selectedContributions.forEach((selected, index) =>
          form_data.append(`contributions[${index}]`, selected.value)
        );
      selectedCountries.length &&
        selectedCountries.forEach((selected, index) =>
          form_data.append(`country_id[${index}]`, selected.value)
        );
      selectedAges.forEach((selected, index) =>
        form_data.append(`age_range[${index}]`, selected.value)
      );
      selectedSpeciality &&
        form_data.append("speciality_id", selectedSpeciality);
      if (files.length) {
        form_data.append("image", files[files.length - 1]);
      } else if (
        Cookies.get("houna-lead") &&
        JSON.parse(Cookies.get("houna-lead"))?.image
          ? JSON.parse(Cookies.get("houna-lead")).image
          : ""
      ) {
        form_data.append("image", JSON.parse(Cookies.get("houna-lead")).image);
      }

      form_data.append("show", checkedShow ? 1 : 0);
      form_data.append("recommended", checkedRecommended ? 1 : 0);
      form_data.append("highlighted", 1);
      form_data.append("order", 1);
      valueGender && form_data.append("gender", valueGender);
      titleEnValue && form_data.append("alias", convertToSlug(titleEnValue));
      specialtiesEnValue &&
        form_data.append("specialties_text[en]", specialtiesEnValue);
      specialtiesArValue &&
        form_data.append("specialties_text[ar]", specialtiesArValue);
      phoneNumberEnValue && form_data.append("phone[en]", phoneNumberEnValue);
      phoneNumberArValue && form_data.append("phone[ar]", phoneNumberArValue);
      emailValue && form_data.append("email", emailValue);
      occupationEnValue &&
        form_data.append("occupation[en]", occupationEnValue);
      occupationArValue &&
        form_data.append("occupation[ar]", occupationArValue);
      servicesEnValue && form_data.append("services[en]", servicesEnValue);
      servicesArValue && form_data.append("services[ar]", servicesArValue);
      selectedProfessions.length &&
        selectedProfessions.forEach((selected, index) =>
          form_data.append(`professions[${index}]`, selected.value)
        );
      yearsOfExperienceEnValue &&
        form_data.append("experience[en]", yearsOfExperienceEnValue);
      yearsOfExperienceArValue &&
        form_data.append("experience[ar]", yearsOfExperienceArValue);
      form_data.append("online", checkedOnline ? 1 : 0);
      youtubeValue && form_data.append("social[youtube]", youtubeValue);
      printerestValue &&
        form_data.append("social[printerest]", printerestValue);
      instagramValue && form_data.append("social[instagram]", instagramValue);
      facebookValue && form_data.append("social[facebook]", facebookValue);
      twitterValue && form_data.append("social[twitter]", twitterValue);
      appStoreValue && form_data.append("social[app_store]", appStoreValue);
      playStoreValue && form_data.append("social[play_store]", playStoreValue);
      axios
        .post("/admin/therapists", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/therapists/items");
          }
        })
        .catch((err) => console.log(""));
    }
  }
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
  function findArrayElementByName(array, value) {
    return array.find((element, index) => {
      return element.name.en.toLowerCase() == value.toLowerCase();
    });
  }
  function findArrayProfessionByName(array, value) {
    return array.find((element, index) => {
      return element.value.en.toLowerCase() == value.toLowerCase();
    });
  }
  function average(a, b) {
    // force the input as numbers *1
    return (a * 1 + b * 1) / 2;
  }
  function isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0;
  }
  function isUrl(s) {
    var regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }
};
export default Add;
