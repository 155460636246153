import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../../Assets/Lib/history";
import List from "./List";
import Details from "./Details";

const WellnessCenters = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/admin/leads/wellness-centers" component={List} />
        <Route
          exact
          path="/admin/leads/wellness-centers/:id"
          component={Details}
        />
      </Switch>
    </Router>
  );
};
export default WellnessCenters;
