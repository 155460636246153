import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../../Assets/Lib/history";
import List from "./List";
import Add from "./Add";
import NavigationLayout from "../../../Components/NavigationLayout";
import Edit from "./Edit";

const Professions = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/admin/therapists/professions" component={List} />
        <Route path="/admin/therapists/professions/new" component={Add} />
        <Route path="/admin/therapists/professions/:id" component={Edit} />
      </Switch>
    </Router>
  );
};
export default Professions;
