import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import MultiSelect from "react-multi-select-component";
import history from "../../../Assets/Lib/history";

const Add = () => {
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
  }, []);
  const [titleError, setTitleError] = useState("");
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = useCallback((newValue) => {
    setTextEnValue(newValue);
  }, []);
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = useCallback((newValue) => {
    setTextArValue(newValue);
  }, []);
  const [linkValue, setLinkValue] = useState("");
  const handleChangeLink = useCallback((newValue) => {
    setLinkValue(newValue);
  }, []);
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [imageError, setImageError] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );
  useEffect(() => {
    axios
      .get(`admin/tags`)
      .then((result) => {
        setOptions(
          result.data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: item.id,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page title="Add Item" breadcrumbs={[{ url: "/admin/hotlines/worldwide" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={titleEnValue}
                onChange={handleChangeTitleEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={titleArValue}
                onChange={handleChangeTitleAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Subtitle En</TextStyle>
              <TextField value={textEnValue} onChange={handleChangeTextEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Subtitle Ar</TextStyle>
              <TextField
                value={textArValue}
                onChange={handleChangeTextAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Link</TextStyle>
          <TextField
            value={linkValue}
            onChange={handleChangeLink}
            align="left"
          />

          <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError} />
          {/* <TextStyle variation="strong">Tags</TextStyle>
          <MultiSelect
            options={options}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
          /> */}
          <Checkbox
            label="Is Active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    setIsSaving(true);
    const form_data = new FormData();
    // form_data.append("order", 1);
    form_data.append("is_active", checkedShow ? 1 : 0);
    form_data.append("link", linkValue);
    form_data.append("title[en]", titleEnValue);
    form_data.append("title[ar]", titleArValue);
    form_data.append("subtitle[en]", textEnValue);
    form_data.append("subtitle[ar]", textArValue);
    files.length && form_data.append("image", files[files.length - 1]);

    axios
      .post("admin/hotlines/worldwide", form_data)
      .then((res) => {
        if (res.status == 200) {
          history.push("/admin/hotlines/worldwide");
        }
      })
      .catch((err) => console.log(""));
  }
};
export default Add;
