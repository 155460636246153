import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../../Assets/Lib/history";
import List from "./List";
import Add from "./Add";
import Edit from "./Edit";
import OrganizationsList from "./List";

const Organizations = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/admin/therapists/organizations"
          component={OrganizationsList}
        />
        <Route path="/admin/therapists/organizations/new" component={Add} />
        <Route path="/admin/therapists/organizations/:id" component={Edit} />
      </Switch>
    </Router>
  );
};
export default Organizations;
