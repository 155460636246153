import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  AppProvider,
  ActionList,
  Card,
  TextField,
  TextContainer,
  ContextualSaveBar,
  FormLayout,
  Modal,
  Frame,
  Layout,
  Loading,
  Navigation,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Toast,
  TopBar,
} from "@shopify/polaris";
import {
  ProfileMinor,
  LogOutMinor,
  QuestionMarkMajor,
  ListMajor,
  EmailNewsletterMajor,
  BankMajor,
  SettingsMajor,
  ImagesMajor,
  CollectionsMajor,
  ThemesMajor,
  CustomersMajor,
  CircleInformationMajor,
  JobsMajor,
  CategoriesMajor,
  PageMajor,
  CalendarMajor,
  OrdersMajor,
  LinkMinor,
} from "@shopify/polaris-icons";
import Cookies from "js-cookie";
import whiteLogo from "../Assets/Images/logo-white.png";
import history from "../Assets/Lib/history";

function NavigationLayout(props) {
  const defaultState = useRef({
    emailFieldValue: "dharma@jadedpixel.com",
    nameFieldValue: "Jaded Pixel",
  });
  const skipToContentRef = useRef(null);

  const [toastActive, setToastActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [nameFieldValue, setNameFieldValue] = useState(
    defaultState.current.nameFieldValue
  );
  const [emailFieldValue, setEmailFieldValue] = useState(
    defaultState.current.emailFieldValue
  );
  const [storeName, setStoreName] = useState(
    defaultState.current.nameFieldValue
  );
  const [supportSubject, setSupportSubject] = useState("");
  const [supportMessage, setSupportMessage] = useState("");

  const handleSubjectChange = useCallback(
    (value) => setSupportSubject(value),
    []
  );
  const handleMessageChange = useCallback(
    (value) => setSupportMessage(value),
    []
  );
  const handleDiscard = useCallback(() => {
    setEmailFieldValue(defaultState.current.emailFieldValue);
    setNameFieldValue(defaultState.current.nameFieldValue);
    setIsDirty(false);
  }, []);
  const handleSave = useCallback(() => {
    defaultState.current.nameFieldValue = nameFieldValue;
    defaultState.current.emailFieldValue = emailFieldValue;

    setIsDirty(false);
    setToastActive(true);
    setStoreName(defaultState.current.nameFieldValue);
  }, [emailFieldValue, nameFieldValue]);
  const handleNameFieldChange = useCallback((value) => {
    setNameFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleEmailFieldChange = useCallback((value) => {
    setEmailFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue("");
  }, []);
  const handleSearchFieldChange = useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );
  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const toggleModalActive = useCallback(
    () => setModalActive((modalActive) => !modalActive),
    []
  );

  const toastMarkup = toastActive ? (
    <Toast onDismiss={toggleToastActive} content="Changes saved" />
  ) : null;

  const userMenuActions = [
    {
      items: [
        {
          content: "Your profile",
          icon: ProfileMinor,
          url: "/profile",
        },
      ],
    },
    {
      items: [
        {
          content: "Log out",
          icon: LogOutMinor,
          // url: "/",
          onAction: () => {
            Cookies.set("houna-accesstoken", null);
            history.push("/");
          },
        },
      ],
    },
  ];

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: handleSave,
      }}
      discardAction={{
        onAction: handleDiscard,
      }}
    />
  ) : null;

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={
        Cookies.get("houna-fn") &&
        Cookies.get("houna-fn") + " " + Cookies.get("houna-ln")
      }
      initials={
        Cookies.get("houna-fn").charAt(0) +
        " " +
        Cookies.get("houna-ln").charAt(0)
      }
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const searchResultsMarkup = (
    <ActionList
      items={[
        { content: "Shopify help center" },
        { content: "Community forums" },
      ]}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Search"
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      // searchResultsVisible={searchActive}
      // searchField={searchFieldMarkup}
      // searchResults={searchResultsMarkup}
      // onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const navigationMarkup = (
    <Navigation location="/">
      {Cookies.get("houna-role") === "Super Admin" && (
        <Navigation.Section
          items={[
            {
              url: "/admin/about",
              label: "About",
              icon: CircleInformationMajor,
              selected: window.location.href.indexOf("/admin/about") > -1,
            },
            {
              url: "/admin/banners",
              label: "Banners",
              icon: ImagesMajor,
              selected: window.location.href.indexOf("/admin/banners") > -1,
            },
            {
              url: "/admin/tags",
              label: "Tags",
              icon: CollectionsMajor,
              selected: window.location.href.indexOf("/admin/tags") > -1,
            },
            {
              url: "/admin/pages-categories",
              label: "Pages Categories",
              icon: CategoriesMajor,
              selected:
                (window.location.href.indexOf("/admin/pages-categories") > -1 &&
                  window.location.href.substr(
                    window.location.href.length - 5
                  ) == "ories") ||
                window.location.href.indexOf("pages-categories/") > -1,
            },
            {
              url: "/admin/pages",
              label: "Pages",
              icon: PageMajor,
              selected:
                window.location.href.indexOf("/admin/pages") > -1 &&
                window.location.href.indexOf("/admin/pages-categories") === -1,
            },
            {
              url: "/admin/support-groups",
              label: "Support Groups",
              icon: ListMajor,
              selected:
                window.location.href.indexOf("/admin/support-groups") > -1,
            },
            {
              url: "/admin/news",
              label: "News",
              icon: CategoriesMajor,
              selected: window.location.href.indexOf("/admin/news") > -1,
            },
            {
              url: "/admin/broadcasts",
              label: "Online Broadcasts",
              icon: ThemesMajor,
              selected: window.location.href.indexOf("/admin/broadcasts") > -1,
            },
            {
              url: "/admin/faqs",
              label: "FAQs",
              icon: QuestionMarkMajor,
              selected: window.location.href.indexOf("/admin/faqs") > -1,
            },
            {
              url: "/admin/members",
              label: "Members",
              icon: CustomersMajor,
              selected: window.location.href.indexOf("/admin/members") > -1,
            },
            {
              url: "/admin/sponsors",
              label: "Sponsors",
              icon: CustomersMajor,
              selected: window.location.href.indexOf("/admin/sponsors") > -1,
            },
            {
              url: "/admin/volunteers",
              label: "Volunteers",
              icon: CustomersMajor,
              selected: window.location.href.indexOf("/admin/volunteers") > -1,
            },
            {
              url: "/admin/boards",
              label: "Boards of directors",
              icon: CustomersMajor,
              selected: window.location.href.indexOf("/admin/boards") > -1,
            },
            {
              url: "/admin/team-founder-advisor",
              label: "Team, Founder & Advisor",
              icon: CustomersMajor,
              selected:
                window.location.href.indexOf("/admin/team-founder-advisor") >
                -1,
            },
            {
              url: "/admin/disorders",
              label: "Disorders",
              icon: ListMajor,
              selected: window.location.href.indexOf("/admin/disorders") > -1,
            },
            {
              url: "/admin/therapists",
              label: "Therapists",
              icon: JobsMajor,
              selected: window.location.href.indexOf("/admin/therapists") > -1,
            },
            {
              url: "/admin/wellness-centers",
              label: "Welness Centers",
              icon: BankMajor,
              selected:
                window.location.href.indexOf("/admin/wellness-centers") > -1,
            },
            {
              url: "/admin/events",
              label: "Events",
              icon: CalendarMajor,
              selected: window.location.href.indexOf("/admin/events") > -1,
            },
            {
              url: "/admin/leads",
              label: "Leads",
              icon: CustomersMajor,
              selected: window.location.href.indexOf("/admin/leads") > -1,
            },
            {
              url: "/admin/teaser-leads",
              label: "Teaser Leads",
              icon: CustomersMajor,
              selected:
                window.location.href.indexOf("/admin/teaser-leads") > -1,
            },
            {
              url: "/admin/subscribers",
              label: "Newsletter Subscribers",
              icon: EmailNewsletterMajor,
              selected: window.location.href.indexOf("/admin/subscribers") > -1,
            },
            {
              url: "/admin/orders",
              label: "Orders",
              icon: OrdersMajor,
              selected: window.location.href.indexOf("/admin/orders") > -1,
            },
            {
              url: "/admin/useful-links",
              label: "Useful Links",
              icon: LinkMinor,
              selected:
                window.location.href.indexOf("/admin/useful-links") > -1,
            },
            // {
            //   url: "/admin/hotlines",
            //   label: "Hotlines",
            //   icon: CategoriesMajor,
            //   selected: window.location.href.indexOf("/admin/hotlines") > -1,
            // },
            // {
            //   url: "/admin/treatments-and-medications",
            //   label: "Treatments and Medications",
            //   icon: CategoriesMajor,
            //   selected:
            //     window.location.href.indexOf(
            //       "/admin/treatments-and-medications"
            //     ) > -1,
            // },
          ]}
        ></Navigation.Section>
      )}

      {Cookies.get("houna-role") === "Data Bank Admin" && (
        <Navigation.Section
          items={[
            {
              url: "/admin/disorders",
              label: "Disorders",
              icon: ListMajor,
              selected: window.location.href.indexOf("/admin/disorders") > -1,
            },
            {
              url: "/admin/therapists",
              label: "Therapists",
              icon: JobsMajor,
              selected: window.location.href.indexOf("/admin/therapists") > -1,
            },
            {
              url: "/admin/wellness-centers",
              label: "Welness Centers",
              icon: BankMajor,
              selected:
                window.location.href.indexOf("/admin/wellness-centers") > -1,
            },

            {
              url: "/admin/leads",
              label: "Leads",
              icon: CustomersMajor,
              selected: window.location.href.indexOf("/admin/leads") > -1,
            },
          ]}
        ></Navigation.Section>
      )}

      {Cookies.get("houna-role") === "Content Admin" && (
        <Navigation.Section
          items={[
            {
              url: "/admin/about",
              label: "About",
              icon: CircleInformationMajor,
              selected: window.location.href.indexOf("/admin/about") > -1,
            },
            {
              url: "/admin/banners",
              label: "Banners",
              icon: ImagesMajor,
              selected: window.location.href.indexOf("/admin/banners") > -1,
            },
            {
              url: "/admin/tags",
              label: "Tags",
              icon: CollectionsMajor,
              selected: window.location.href.indexOf("/admin/tags") > -1,
            },
            {
              url: "/admin/pages-categories",
              label: "Pages Categories",
              icon: CategoriesMajor,
              selected:
                (window.location.href.indexOf("/admin/pages-categories") > -1 &&
                  window.location.href.substr(
                    window.location.href.length - 5
                  ) == "ories") ||
                window.location.href.indexOf("pages-categories/") > -1,
            },
            {
              url: "/admin/pages",
              label: "Pages",
              icon: PageMajor,
              selected:
                window.location.href.indexOf("/admin/pages") > -1 &&
                window.location.href.indexOf("/admin/pages-categories") === -1,
            },
            {
              url: "/admin/support-groups",
              label: "Support Groups",
              icon: ListMajor,
              selected:
                window.location.href.indexOf("/admin/support-groups") > -1,
            },
            {
              url: "/admin/news",
              label: "News",
              icon: CategoriesMajor,
              selected: window.location.href.indexOf("/admin/news") > -1,
            },
            {
              url: "/admin/broadcasts",
              label: "Online Broadcasts",
              icon: ThemesMajor,
              selected: window.location.href.indexOf("/admin/broadcasts") > -1,
            },
            {
              url: "/admin/faqs",
              label: "FAQs",
              icon: QuestionMarkMajor,
              selected: window.location.href.indexOf("/admin/faqs") > -1,
            },
            {
              url: "/admin/members",
              label: "Members",
              icon: CustomersMajor,
              selected: window.location.href.indexOf("/admin/members") > -1,
            },
            {
              url: "/admin/sponsors",
              label: "Sponsors",
              icon: CustomersMajor,
              selected: window.location.href.indexOf("/admin/sponsors") > -1,
            },
            {
              url: "/admin/volunteers",
              label: "Volunteers",
              icon: CustomersMajor,
              selected: window.location.href.indexOf("/admin/volunteers") > -1,
            },
            {
              url: "/admin/boards",
              label: "Boards of directors",
              icon: CustomersMajor,
              selected: window.location.href.indexOf("/admin/boards") > -1,
            },
            {
              url: "/admin/team-founder-advisor",
              label: "Team, Founder & Advisor",
              icon: CustomersMajor,
              selected:
                window.location.href.indexOf("/admin/team-founder-advisor") >
                -1,
            },
            // {
            //   url: "/admin/disorders",
            //   label: "Disorders",
            //   icon: ListMajor,
            //   selected: window.location.href.indexOf("/admin/disorders") > -1,
            // },
            // {
            //   url: "/admin/therapists",
            //   label: "Therapists",
            //   icon: JobsMajor,
            //   selected: window.location.href.indexOf("/admin/therapists") > -1,
            // },
            // {
            //   url: "/admin/wellness-centers",
            //   label: "Welness Centers",
            //   icon: BankMajor,
            //   selected:
            //     window.location.href.indexOf("/admin/wellness-centers") > -1,
            // },
            {
              url: "/admin/events",
              label: "Events",
              icon: CalendarMajor,
              selected: window.location.href.indexOf("/admin/events") > -1,
            },
          ]}
        ></Navigation.Section>
      )}

      {Cookies.get("houna-role") === "Event Admin" && (
        <Navigation.Section
          items={[
            {
              url: "/admin/events",
              label: "Events",
              icon: CalendarMajor,
              selected: window.location.href.indexOf("/admin/events") > -1,
            },
          ]}
        ></Navigation.Section>
      )}

      {Cookies.get("houna-role") === "Super Admin" && (
        <Navigation.Section
          separator
          items={[
            {
              icon: SettingsMajor,
              label: "Settings",
              url: "/admin/settings",
              selected:
                window.location.href.indexOf("/admin/settings") > -1 && true,
            },
          ]}
        />
      )}
    </Navigation>
  );
  const loadingMarkup = isLoading ? <Loading /> : null;

  const skipToContentTarget = (
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );

  const actualPageMarkup = (
    <Page title="Account">
      <Layout>
        {skipToContentTarget}
        <Layout.AnnotatedSection
          title="Account details"
          description="Jaded Pixel will use this as your account information."
        >
          <Card sectioned>
            <FormLayout>
              <TextField
                label="Full name"
                value={nameFieldValue}
                onChange={handleNameFieldChange}
              />
              <TextField
                type="email"
                label="Email"
                value={emailFieldValue}
                onChange={handleEmailFieldChange}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  const modalMarkup = (
    <Modal
      open={modalActive}
      onClose={toggleModalActive}
      title="Contact support"
      primaryAction={{
        content: "Send",
        onAction: toggleModalActive,
      }}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label="Subject"
            value={supportSubject}
            onChange={handleSubjectChange}
          />
          <TextField
            label="Message"
            value={supportMessage}
            onChange={handleMessageChange}
            multiline
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const theme = {
    colors: "Yellow",
    logo: {
      width: 124,
      topBarSource: whiteLogo,
      // url: "/admin/about",
    },
  };
  useEffect(() => {
    console.log(Cookies.get("houna-role"));
    console.log(Cookies.get("houna-role") === "Super Admin");
  }, []);

  return (
    <div style={{ height: "500px" }}>
      <AppProvider
        theme={theme}
        i18n={{
          Polaris: {
            Avatar: {
              label: "Avatar",
              labelWithInitials: "Avatar with initials {initials}",
            },
            ContextualSaveBar: {
              save: "Save",
              discard: "Discard",
            },
            TextField: {
              characterCount: "{count} characters",
            },
            TopBar: {
              toggleMenuLabel: "Toggle menu",

              SearchField: {
                clearButtonLabel: "Clear",
                search: "Search",
              },
            },
            Modal: {
              iFrameTitle: "body markup",
            },
            Frame: {
              skipToContent: "Skip to content",
              Navigation: {
                closeMobileNavigationLabel: "Close navigation",
              },
            },
          },
        }}
      >
        <Frame
          topBar={topBarMarkup}
          navigation={navigationMarkup}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
          skipToContentTarget={skipToContentRef.current}
        >
          {props.children}
        </Frame>
      </AppProvider>
    </div>
  );
}
export default React.memo(NavigationLayout);
