import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Lib/history";
import List from "./List";
import Add from "./Add";
import NavigationLayout from "../../Components/NavigationLayout";
import Edit from "./Edit";

const TreatmentsAndMedications = () => {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/admin/treatments-and-medications"
            component={List}
          />
          <Route path="/admin/treatments-and-medications/new" component={Add} />
          <Route
            path="/admin/treatments-and-medications/:id"
            component={Edit}
          />
        </Switch>
      </Router>
    </NavigationLayout>
  );
};
export default TreatmentsAndMedications;
