import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../../Assets/Lib/history";
import List from "./List";
import Add from "./Add";
import NavigationLayout from "../../../Components/NavigationLayout";
import Edit from "./Edit";
import MainEdit from "./MainEdit";

const Centers = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/admin/useful-links/:id/centers" component={List} />
        <Route path="/admin/useful-links/:id/centers/new" component={Add} />
        <Route
          path="/admin/useful-links/:linkId/centers/:centerId"
          component={MainEdit}
        />
      </Switch>
    </Router>
  );
};
export default Centers;
