import React, { useCallback, useState } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";

const Add = () => {
  const [nameEnValue, setNameEnValue] = useState("");
  const handleChangeNameEn = useCallback((newValue) => {
    setNameEnValue(newValue);
    setNameError("");
  }, []);
  const [nameArValue, setNameArValue] = useState("");
  const handleChangeNameAr = useCallback((newValue) => {
    setNameArValue(newValue);
    setNameError("");
  }, []);
  const [nameError, setNameError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Page
      title="Add Language"
      breadcrumbs={[{ url: "/admin/therapists/languages" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Language En</TextStyle>
              <TextField
                value={nameEnValue}
                onChange={handleChangeNameEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Language Ar</TextStyle>
              <TextField
                value={nameArValue}
                onChange={handleChangeNameAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={nameError} />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (!nameEnValue && !nameArValue) {
      setNameError("Please enter a language");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      nameEnValue && form_data.append("name[en]", nameEnValue);
      nameArValue && form_data.append("name[ar]", nameArValue);
      axios
        .post("admin/languages", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/therapists/languages");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Add;
