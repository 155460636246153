import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Lib/history";
import List from "./List";
import NavigationLayout from "../../Components/NavigationLayout";
import OrderDetails from "./OrderDetails";

const MainOrders = () => {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/orders" component={List} />
          <Route path="/admin/orders/:email" component={OrderDetails} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
};
export default MainOrders;
