import React, { useCallback, useState } from "react";
import "@shopify/polaris/dist/styles.css";
import { Switch, Route, Router } from "react-router-dom";
import history from "./Assets/Lib/history";
import { AppProvider, Toast } from "@shopify/polaris";
import "./Assets/Styles/globals.css";
import "./Assets/Styles/react-tagsinput.css";
import Login from "./Pages/Authentication/Login";
import ForgotPassword from "./Pages/Authentication/ForgotPassword";
import Profile from "./Pages/Profile";
import Settings from "./Pages/Settings";
import FAQs from "./Pages/FAQs/Main";
import ResetPassword from "./Pages/Authentication/ResetPassword";
import AdminUsers from "./Pages/AdminUsers/Main";
import Tags from "./Pages/Tags/Main";
import SupportGroups from "./Pages/Support Groups/Main";
import News from "./Pages/News/Main";
import Banners from "./Pages/Banners/Main";
import Broadcasts from "./Pages/Broadcasts/Main";
import Members from "./Pages/Members/Main";
import Boards from "./Pages/Boards/Main";
import About from "./About";
import Disorders from "./Pages/Disorders/Main";
import Therapists from "./Pages/Therapists/Main";
import TreatmentsAndMedications from "./Pages/TreatmentsAndMedications/Main";
import PagesCategories from "./Pages/Pages Categories/Main";
import Pages from "./Pages/Pages/Main";
import sponsors from "./Pages/Sponsors/Main";
import Volunteers from "./Pages/Volunteers/Main";
import Leads from "./Pages/Leads/Main";
import WellnessCenters from "./Pages/Wellness Centers/Main";
import Events from "./Pages/Events/Main";
import CampaignSubscribers from "./Pages/Campaign Subscribers/List";
import Subscribers from "./Pages/Subscribers/List";
import TeamFounderAdvisor from "./Pages/TeamFounderAdvisor/Main";
import Orders from "./Pages/Orders/List";
import MainOrders from "./Pages/Orders/MainOrders";
import Hotlines from "./Pages/Hotlines/Main";
import UsefulLinks from "./Pages/UsefulLinks/Main";
import Centers from "./Pages/UsefulLinks/Centers/Main";

function App() {
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }

  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/profile" component={Profile} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/admin/about" component={About} />
          <Route path="/admin/banners" component={Banners} />
          <Route path="/admin/tags" component={Tags} />
          <Route path="/password/reset" component={ResetPassword} />
          <Route path="/admin/settings" component={Settings} />
          <Route path="/admin/pages-categories" component={PagesCategories} />
          <Route path="/admin/pages" component={Pages} />
          <Route path="/admin/faqs" component={FAQs} />
          <Route path="/admin/support-groups" component={SupportGroups} />
          <Route path="/admin/news" component={News} />
          <Route path="/admin/broadcasts" component={Broadcasts} />
          <Route path="/admin/members" component={Members} />
          <Route path="/admin/sponsors" component={sponsors} />
          <Route path="/admin/volunteers" component={Volunteers} />
          <Route path="/admin/boards" component={Boards} />
          <Route path="/admin/disorders" component={Disorders} />
          <Route path="/admin/therapists" component={Therapists} />
          <Route path="/admin/settings" component={Settings} />
          <Route path="/admin/teaser-leads" component={CampaignSubscribers} />
          <Route path="/admin/subscribers" component={Subscribers} />
          <Route path="/admin/admin-users" component={AdminUsers} />
          <Route
            path="/admin/treatments-and-medications"
            component={TreatmentsAndMedications}
          />
          <Route path="/admin/leads" component={Leads} />
          <Route path="/admin/wellness-centers" component={WellnessCenters} />
          <Route path="/admin/events" component={Events} />
          <Route
            path="/admin/team-founder-advisor"
            component={TeamFounderAdvisor}
          />
          <Route path="/admin/orders" component={MainOrders} />
          <Route path="/admin/hotlines" component={Hotlines} />
          <Route path="/admin/useful-links" component={UsefulLinks} />{" "}
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default App;
