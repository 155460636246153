import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  RadioButton,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, nameEn: newValue });
    setTitleError("");
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, nameAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangePositionEn = (newValue) => {
    setItem({ ...item, positionEn: newValue });
  };
  const handleChangePositionAr = (newValue) => {
    setItem({ ...item, positionAr: newValue });
  };
  const handleChangeDegreeEn = (newValue) => {
    setItem({ ...item, degreeEn: newValue });
  };
  const handleChangeDegreeAr = (newValue) => {
    setItem({ ...item, degreeAr: newValue });
  };
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = (newValue) => {
    setItem({ ...item, summaryEn: newValue });
  };
  const handleChangeTextAr = (newValue) => {
    setItem({ ...item, summaryAr: newValue });
  };

  const handleChangeProfileEn = (newValue) => {
    setItem({ ...item, profileEn: newValue });
  };
  const handleChangeProfileAr = (newValue) => {
    setItem({ ...item, profileAr: newValue });
  };
  const handleChangeSpecialtyEn = (newValue) => {
    setItem({ ...item, specialtiesEn: newValue });
  };
  const handleChangeSpecialtyAr = (newValue) => {
    setItem({ ...item, specialtiesAr: newValue });
  };
  const handleChangePhoneNumberEn = (newValue) => {
    setItem({ ...item, phoneNumberEn: newValue });
  };
  const handleChangePhoneNumberAr = (newValue) => {
    setItem({ ...item, phoneNumberAr: newValue });
  };
  const [emailValue, setEmailValue] = useState("");
  const handleChangeEmail = (newValue) => {
    setItem({ ...item, email: newValue });
  };
  const [profileError, setProfileError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
  };

  const [optionsLanguages, setOptionsLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [optionsCountries, setOptionsCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [checkedShow, setCheckedShow] = useState(true);

  const handleChangeShow = (newValue) => {
    setCheckedShow({ ...item, show: newValue });
  };

  const [optionsAges, setOptionsAges] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);
  const [ageError, setAgeError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [specialtyError, setSpecialtyError] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [languageError, setLanguageError] = useState("");
  const [visitValue, setVisitValue] = useState("");
  const handleChangeVisit = useCallback(
    (newValue) => setVisitValue(newValue),
    []
  );
  const [checkedOnline, setCheckedOnline] = useState(false);
  const handleChangeOnline = useCallback(
    (newChecked) => setCheckedOnline(newChecked),
    []
  );
  const [item, setItem] = useState({
    nameEn: "",
    nameAr: "",
    image: "",
    positionEn: "",
    positionAr: "",
    degreeEn: "",
    degreeAr: "",
    phoneNumberEn: "",
    phoneNumberAr: "",
    email: "",
    summaryEn: "",
    summaryAr: "",
    profileEn: "",
    profileAr: "",
    specialtiesEn: "",
    specialtiesAr: "",
    show: "",
    profileEn: "",
    profileAr: "",
  });
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    let responseAges = "";
    let responseLanguages = "";
    let responseCountries = "";
    try {
      responseAges = await axios.get(`/admin/age-range`);
      responseAges?.data &&
        setOptionsAges(
          responseAges.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseLanguages = await axios.get(`/admin/languages`);
      responseLanguages?.data &&
        setOptionsLanguages(
          responseLanguages.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseCountries = await axios.get(`/admin/countries`);
      responseCountries?.data &&
        setOptionsCountries(
          responseCountries.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      const response = await axios.get(`/admin/speakers/${id}`);
      response?.data &&
        setItem({
          image: response?.data?.image ? response.data.image : "",
          nameEn: response?.data?.name?.en ? response.data.name.en : "",
          nameAr: response?.data?.name?.ar ? response.data.name.ar : "",
          positionEn: response?.data?.position?.en
            ? response.data.position.en
            : "",
          positionAr: response?.data?.position?.ar
            ? response.data.position.ar
            : "",
          degreeEn: response?.data?.degree?.en ? response.data.degree.en : "",
          degreeAr: response?.data?.degree?.ar ? response.data.degree.ar : "",
          phoneNumberEn: response?.data?.phone?.en
            ? response.data.phone.en
            : "",
          phoneNumberAr: response?.data?.phone?.ar
            ? response.data.phone.ar
            : "",
          summaryEn: response?.data?.brief?.en ? response.data.brief.en : "",
          summaryAr: response?.data?.brief?.ar ? response.data.brief.ar : "",
          specialtiesEn: response?.data?.speciality?.en
            ? response.data.speciality.en
            : "",
          specialtiesAr: response?.data?.speciality?.ar
            ? response.data.speciality.ar
            : "",
          profileEn: response?.data?.profile?.en
            ? response.data.profile.en
            : "",
          profileAr: response?.data?.profile?.ar
            ? response.data.profile.ar
            : "",
          show: response.data.show ? true : false,
          email: response.data?.email && response.data.email,
        });
      response.data.countries &&
        setSelectedCountries(
          response.data.countries.map((item, index) => {
            return {
              label: item.en_name,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      console.log(" response.data.languages=", response.data.languages);
      response.data.languages &&
        setSelectedLanguages(
          response.data.languages.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      response.data.age_range &&
        setSelectedAges(
          response.data.age_range.map((item, index) => {
            return {
              label: item.name.en,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }

  return (
    <Page
      title="Edit Speaker"
      breadcrumbs={[{ url: "/admin/events/speakers" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField value={item.nameEn} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField value={item.nameAr} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>

          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={item.image ? item.image : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Position En</TextStyle>
              <TextField
                value={item.positionEn}
                onChange={handleChangePositionEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Position Ar</TextStyle>
              <TextField
                value={item.positionAr}
                onChange={handleChangePositionAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Degree En</TextStyle>
              <TextField
                value={item.degreeEn}
                onChange={handleChangeDegreeEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Degree Ar</TextStyle>
              <TextField
                value={item.degreeAr}
                onChange={handleChangeDegreeAr}
              />
            </FormLayout>
          </FormLayout.Group>
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Years of experience En</TextStyle>
              <TextField
                value={yearsOfExperienceEnValue}
                onChange={handleChangeYearsOfExperienceEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Years of experience Ar</TextStyle>
              <TextField
                value={yearsOfExperienceArValue}
                onChange={handleChangeYearsOfExperienceAr}
              />
            </FormLayout>
          </FormLayout.Group> */}
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Phone Number En</TextStyle>
              <TextField
                value={item.phoneNumberEn}
                onChange={handleChangePhoneNumberEn}
              ></TextField>
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Phone Number Ar</TextStyle>
              <TextField
                value={item.phoneNumberAr}
                onChange={handleChangePhoneNumberAr}
              ></TextField>
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Email</TextStyle>
          <TextField
            value={item.email}
            onChange={handleChangeEmail}
          ></TextField>

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Summary En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // initialValue="<p>This is the initial content of the editor</p>"
                value={item.summaryEn}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Summary Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.summaryAr}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={summaryError} />
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Services En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={servicesEnValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeServicesEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Services Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                // value={servicesArValue}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeServicesAr}
              />
            </FormLayout>
          </FormLayout.Group> */}

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Profile En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.profileEn}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeProfileEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Profile Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={item.profileAr}
                init={{
                  plugins: "link image code",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code",
                }}
                onEditorChange={handleChangeProfileAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={profileError} />

          <FormLayout>
            {/* <Select
              options={optionsCountries}
              onChange={handleSelectCountry}
              value={selectedCountry}
              placeholder="Please select a country"
            /> */}
            {/* <TextStyle variation="strong">Website</TextStyle>
            <TextField
              value={visitValue}
              onChange={handleChangeVisit}
              autoComplete="off"
            /> */}
            {/* <TextStyle variation="strong">Specialty</TextStyle>
            <Select
              options={optionsSpecialicities}
              value={selectedSpeciality}
              onChange={handleSelectSpecialicity}
              placeholder="Please select a specialty"
            />
            <InlineError message={specialtyError} /> */}

            <FormLayout.Group>
              <FormLayout>
                <TextStyle variation="strong">Specialties En</TextStyle>

                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  value={item.specialtiesEn}
                  init={{
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onEditorChange={handleChangeSpecialtyEn}
                />
              </FormLayout>
              <FormLayout>
                <TextStyle variation="strong">Specialties Ar</TextStyle>
                <Editor
                  apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                  value={item.specialtiesAr}
                  init={{
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onEditorChange={handleChangeSpecialtyAr}
                />
              </FormLayout>
            </FormLayout.Group>

            {/* <TextStyle variation="strong">Organizations</TextStyle>
            <MultiSelect
              options={optionsOrganizations}
              value={selectedOrganizations}
              onChange={setSelectedOrganizations}
              labelledBy="Select"
            />
            <TextStyle variation="strong">Disorders</TextStyle>
            <MultiSelect
              options={optionsDisorders}
              value={selectedDisorders}
              onChange={setSelectedDisorders}
              labelledBy="Select"
            /> */}
            {/* <TextStyle variation="strong">Contributions</TextStyle>
            <MultiSelect
              options={optionsContributions}
              value={selectedContributions}
              onChange={setSelectedContributions}
              labelledBy="Please select"
            />
            <TextStyle variation="strong">Countries</TextStyle>
            <MultiSelect
              options={optionsCountries}
              value={selectedCountries}
              onChange={setSelectedCountries}
              labelledBy="Please select a country"
            /> */}
            {/* //a */}
            {/* <TextStyle variation="strong">Gender</TextStyle>
            <Stack horizontal>
              <RadioButton
                label="Male"
                checked={valueGender === "male"}
                id="male"
                name="male"
                onChange={handleChangeGender}
              />
              <RadioButton
                label="Female"
                id="female"
                name="female"
                checked={valueGender === "female"}
                onChange={handleChangeGender}
              />
            </Stack>
            <InlineError message={genderError} /> */}
          </FormLayout>
          {/* <Checkbox
            label="Is Purely Online"
            checked={checkedOnline}
            onChange={handleChangeOnline}
          /> */}
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Countries</TextStyle>
              <MultiSelect
                options={optionsCountries}
                value={selectedCountries}
                onChange={setSelectedCountries}
                labelledBy="Please select a country"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Age</TextStyle>
              <MultiSelect
                options={optionsAges}
                value={selectedAges}
                onChange={setSelectedAges}
                labelledBy="Please select age"
              />
            </FormLayout>
          </FormLayout.Group>

          <InlineError message={ageError} />
          {/* <TextStyle variation="strong">Tags</TextStyle>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            /> */}
          <TextStyle variation="strong">Languages</TextStyle>
          <MultiSelect
            options={optionsLanguages}
            value={selectedLanguages}
            onChange={setSelectedLanguages}
            labelledBy="Please select a language"
          />
          <InlineError message={languageError} />
          <Checkbox
            label="Is Active"
            checked={item.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/speakers/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/events/speakers`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if ((!item.nameEn && !item.nameAr) || !selectedLanguages.length) {
      !item.nameEn && !item.nameAr && setTitleError("Please enter a title");
      !selectedLanguages.length && setLanguageError("Please select a language");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      item.nameEn && form_data.append("name[en]", item.nameEn);
      item.nameAr && form_data.append("name[ar]", item.nameAr);
      item.summaryEn && form_data.append("brief[en]", item.summaryEn);
      item.summaryAr && form_data.append("brief[ar]", item.summaryAr);
      item.profileEn && form_data.append("profile[en]", item.profileEn);
      item.profileAr && form_data.append("profile[ar]", item.profileAr);
      item.degreeEn && form_data.append("degree[en]", item.degreeEn);
      item.degreeAr && form_data.append("degree[ar]", item.degreeAr);
      selectedLanguages.forEach((selected, index) =>
        form_data.append(`languages[${index}]`, selected.value)
      );
      selectedCountries.forEach((selected, index) =>
        form_data.append(`country_id[${index}]`, selected.value)
      );
      selectedAges.forEach((selected, index) =>
        form_data.append(`age_range[${index}]`, selected.value)
      );
      files[files.length - 1] &&
        form_data.append("image", files[files.length - 1]);

      form_data.append("show", item.show ? 1 : 0);
      form_data.append("highlighted", 1);
      form_data.append("order", 1);
      item.nameEn && form_data.append("alias", convertToSlug(item.nameEn));
      item.specialtiesEn &&
        form_data.append("speciality[en]", item.specialtiesEn);
      item.specialtiesAr &&
        form_data.append("speciality[ar]", item.specialtiesAr);
      item.phoneNumberEn && form_data.append("phone[en]", item.phoneNumberEn);
      item.phoneNumberAr && form_data.append("phone[ar]", item.phoneNumberAr);
      item.email && form_data.append("email", item.email);
      item.positionEn && form_data.append("position[en]", item.positionEn);
      item.positionAr && form_data.append("position[ar]", item.positionAr);
      form_data.append("id", id);
      axios
        .post("/admin/speakers/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/events/speakers");
          }
        })
        .catch((err) => console.log(""));
    }
  }
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
  function findArrayElementByName(array, value) {
    return array.find((element, index) => {
      return element.name.en.toLowerCase() == value.toLowerCase();
    });
  }
  function findArrayProfessionByName(array, value) {
    return array.find((element, index) => {
      return element.value.en.toLowerCase() == value.toLowerCase();
    });
  }
  function average(a, b) {
    // force the input as numbers *1
    return (a * 1 + b * 1) / 2;
  }
  function isBetween(n, a, b) {
    return (n - a) * (n - b) <= 0;
  }
  function isUrl(s) {
    var regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }
};
export default Edit;
