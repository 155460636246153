import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { useParams } from "react-router-dom";
import history from "../../../Assets/Lib/history";

const Edit = () => {
  const { id } = useParams();
  const handleChangeNameEn = (newValue) => {
    setTag({ ...tag, nameEn: newValue });
    setNameError("");
  };
  const handleChangeNameAr = (newValue) => {
    setTag({ ...tag, nameAr: newValue });
    setNameError("");
  };
  const [nameError, setNameError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [tag, setTag] = useState({
    nameEn: "",
    nameAr: "",
  });
  useEffect(() => {
    axios
      .get(`admin/labels/${id}`)
      .then((result) => {
        setTag({
          nameEn: result?.data?.name?.en ? result.data.name.en : "",
          nameAr: result?.data?.name?.ar ? result.data.name.ar : "",
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page title="Edit Label" breadcrumbs={[{ url: "/admin/events/labels" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField
                value={tag.nameEn}
                onChange={handleChangeNameEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField
                value={tag.nameAr}
                onChange={handleChangeNameAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={nameError} />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/labels/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/events/labels`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (!tag.nameEn && !tag.nameAr) {
      !tag.nameEn && !tag.nameAr && setNameError("Please enter a name");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("id", id);
      tag.nameEn && form_data.append("name[en]", tag.nameEn);
      tag.nameAr && form_data.append("name[ar]", tag.nameAr);
      axios
        .post("admin/labels/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/events/labels");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Edit;
