import React, { useCallback, useState, useEffect } from "react";
import {
  Page,
  FormLayout,
  TextField,
  Loading,
  Card,
  PageActions,
  Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordConfirmationValue, setPasswordConfirmationValue] =
    useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const handleChangeFirstName = useCallback((newValue) => {
    setFirstNameValue(newValue);
    setFirstNameError("");
  }, []);
  const handleChangeLastName = useCallback((newValue) => {
    setLastNameValue(newValue);
    setLastNameError("");
  }, []);
  const handleChangeEmail = useCallback((newValue) => {
    setEmailValue(newValue);
    setEmailError("");
  }, []);
  const handleChangePassword = useCallback((newValue) => {
    setPasswordValue(newValue);
    setPasswordError("");
  }, []);
  const handleChangePasswordConfirmation = useCallback((newValue) => {
    setPasswordConfirmationValue(newValue);
    setPasswordError("");
  }, []);
  const [selected, setSelected] = useState("");

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const options = [
    { label: "Super Admin", value: "1" },
    { label: "Content Admin", value: "4" },
    { label: "Data Bank Admin", value: "5" },
    { label: "Events Admin", value: "3" },
  ];

  useEffect(() => {
    axios
      .get(`admin/administrators/${id}`)
      .then((result) => {
        result?.data?.first_name && setFirstNameValue(result.data.first_name);
        result?.data?.last_name && setLastNameValue(result.data.last_name);
        result?.data?.email && setEmailValue(result.data.email);
        result?.data?.role_id && setSelected(String(result.data.role_id));
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Edit User"
      breadcrumbs={[{ content: "Admin Users", url: "/admin/admin-users" }]}
    >
      {isSaving ? <Loading /> : null}

      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              value={firstNameValue}
              onChange={handleChangeFirstName}
              label="First Name"
              error={firstNameError}
            />
            <TextField
              value={lastNameValue}
              onChange={handleChangeLastName}
              label="Last Name"
              error={lastNameError}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={emailValue}
              onChange={handleChangeEmail}
              label="Email"
              error={emailError}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={passwordValue}
              onChange={handleChangePassword}
              label="Password"
              error={passwordError}
              helpText="Password must be at least 6 characters"
            />
            <TextField
              value={passwordConfirmationValue}
              onChange={handleChangePasswordConfirmation}
              label="Confirm Password"
            />
          </FormLayout.Group>
          <Select
            label="Role"
            options={options}
            onChange={handleSelectChange}
            value={selected}
            placeholder="Please Select"
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            onClick: () =>
              axios
                .post(`/admin/administrators/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/admin-users`);
                })
                .catch((err) => console.log(err)),
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (
      !firstNameValue ||
      !lastNameValue ||
      !emailValue ||
      (emailValue &&
        !emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
      (passwordValue.length < 8 && passwordValue) ||
      passwordConfirmationValue != passwordValue
    ) {
      !firstNameValue && setFirstNameError("This field is required");
      !lastNameValue && setLastNameError("This field is required");
      !emailValue && setEmailError("This field is required");
      emailValue &&
        !emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) &&
        setEmailError("Please enter a valid email");
      passwordValue &&
        passwordValue.length < 8 &&
        setPasswordError("Password must be at least 8 characters");
      passwordConfirmationValue != passwordValue &&
        setPasswordError("Password are not matching");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("id", id);
      firstNameValue && form_data.append("first_name", firstNameValue);
      lastNameValue && form_data.append("last_name", lastNameValue);
      emailValue && form_data.append("email", emailValue);
      passwordValue && form_data.append("password", passwordValue);
      passwordConfirmationValue &&
        form_data.append("password_confirmation", passwordConfirmationValue);
      selected !== "" && form_data.append("role_id", selected);

      axios
        .post("/admin/administrators/update", form_data)
        .then((res) => {
          history.push("/admin/admin-users");
        })
        .catch((err) => console.log(err));
    }
  }
};
export default Edit;
