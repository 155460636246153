import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import MultiSelect from "react-multi-select-component";
import history from "../../../Assets/Lib/history";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, titleEn: newValue });
  };
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, titleAr: newValue });
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeTextEn = (newValue) => {
    setItem({ ...item, textEn: newValue });
  };
  const handleChangeTextAr = (newValue) => {
    setItem({ ...item, textAr: newValue });
  };
  const handleChangeLink = (newValue) => {
    setItem({ ...item, link: newValue });
  };
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [imageError, setImageError] = useState("");
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
    setImageError("");
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const handleChangeShow = (newValue) => {
    setItem({ ...item, show: newValue });
  };
  const [item, setItem] = useState({
    titleEn: "",
    textEn: "",
    titleAr: "",
    textAr: "",
    image: "",
    link: "",
    show: "",
  });
  useEffect(() => {
    Promise.all([axios.get(`admin/hotlines/worldwide/${id}`)]).then(function (
      responses
    ) {
      //setPopupActive(false);
      const responseShowNews = responses[0].data;

      setItem({
        titleEn: responseShowNews?.title?.en ? responseShowNews.title.en : "",
        titleAr: responseShowNews?.title?.ar ? responseShowNews.title.ar : "",
        textEn: responseShowNews?.subtitle?.en
          ? responseShowNews.subtitle.en
          : "",
        textAr: responseShowNews?.subtitle?.ar
          ? responseShowNews.subtitle.ar
          : "",
        link: responseShowNews?.link ? responseShowNews.link : "",
        image: responseShowNews?.image ? responseShowNews.image : "",
        show: responseShowNews.is_active == 0 ? false : true,
      });
    });
  }, []);
  return (
    <Page
      title="Edit Item"
      breadcrumbs={[{ url: "/admin/hotlines/worldwide" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Title En</TextStyle>
              <TextField
                value={item.titleEn}
                onChange={handleChangeTitleEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title Ar</TextStyle>
              <TextField
                value={item.titleAr}
                onChange={handleChangeTitleAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Link</TextStyle>
          <TextField value={item.link} onChange={handleChangeLink} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Subtitle En</TextStyle>
              <TextField value={item.textEn} onChange={handleChangeTextEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Subtitle Ar</TextStyle>
              <TextField value={item.textAr} onChange={handleChangeTextAr} />
            </FormLayout>
          </FormLayout.Group>

          <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail size="large" source={item.image ? item.image : ""} />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div>
          <InlineError message={imageError} />

          <Checkbox
            label="Is Active"
            checked={item.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/hotlines/worldwide/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/hotlines/worldwide`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    setIsSaving(true);
    const form_data = new FormData();
    form_data.append("title[en]", item.titleEn);
    form_data.append("title[ar]", item.titleAr);
    form_data.append("subtitle[en]", item.textEn);
    form_data.append("subtitle[ar]", item.textAr);
    files.length && form_data.append("image", files[files.length - 1]);
    form_data.append("link", item.link);
    form_data.append("is_active", item.show ? 1 : 0);

    axios
      .post(`admin/hotlines/worldwide/${id}`, form_data)
      .then((res) => {
        if (res.status == 200) {
          history.push("/admin/hotlines/worldwide");
        }
      })
      .catch((err) => console.log(""));
  }
};
export default Edit;
