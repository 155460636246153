import React from "react";
import Grid from "@material-ui/core/Grid";
import { Page, Icon, Card, TextStyle } from "@shopify/polaris";
import { CustomersMajor, BankMajor } from "@shopify/polaris-icons";
import history from "../../Assets/Lib/history";
import WellnessCenters from "./../Wellness Centers/Main";

const GridView = () => {
  return (
    <Page>
      <Card>
        <div style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="gridItem"
                onMouseDown={(e) => history.push("/admin/leads/therapists")}
              >
                <div className="iconDiv">
                  <Icon
                    source={CustomersMajor}
                    style={{ margin: "0 5px" }}
                    color="subdued"
                  ></Icon>
                </div>
                <div style={{ flexDirection: "column", marginLeft: "10" }}>
                  <div className="titleDev">
                    <TextStyle variation="strong">Therapists</TextStyle>
                  </div>
                  <div className="description">
                    <TextStyle></TextStyle>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="gridItem"
                onMouseDown={(e) => history.push("/admin/leads/organizations")}
              >
                <div className="iconDiv">
                  <Icon
                    source={BankMajor}
                    style={{ margin: "0 5px" }}
                    color="subdued"
                  ></Icon>
                </div>
                <div style={{ flexDirection: "column", marginLeft: "10" }}>
                  <div className="titleDev">
                    <TextStyle variation="strong">Organizations</TextStyle>
                  </div>
                  <div className="description">
                    <TextStyle></TextStyle>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="gridItem"
                onMouseDown={(e) =>
                  history.push("/admin/leads/wellness-centers")
                }
              >
                <div className="iconDiv">
                  <Icon
                    source={BankMajor}
                    style={{ margin: "0 5px" }}
                    color="subdued"
                  ></Icon>
                </div>
                <div style={{ flexDirection: "column", marginLeft: "10" }}>
                  <div className="titleDev">
                    <TextStyle variation="strong">Wellness Centers</TextStyle>
                  </div>
                  <div className="description">
                    <TextStyle></TextStyle>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
    </Page>
  );
};
export default GridView;
