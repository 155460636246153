import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";

const Add = () => {
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
    setTitleError("");
  }, []);
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = useCallback((newValue) => {
    setTitleArValue(newValue);
    setTitleError("");
  }, []);
  const [titleError, setTitleError] = useState("");
  const [positionEnValue, setpositionEnValue] = useState("");
  const handleChangepositionEn = useCallback((newValue) => {
    setpositionEnValue(newValue);
  }, []);
  const [positionArValue, setpositionArValue] = useState("");
  const handleChangepositionAr = useCallback((newValue) => {
    setpositionArValue(newValue);
  }, []);
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = (newValue) => {
    setTextEnValue(newValue);
    setTextError("");
  };
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = (newValue) => {
    setTextArValue(newValue);
    setTextError("");
  };
  const [profileEnValue, setProfileEnValue] = useState("");
  const handleChangeProfileEn = (newValue) => {
    setProfileEnValue(newValue);
  };
  const [profileArValue, setProfileArValue] = useState("");
  const handleChangeProfileAr = (newValue) => {
    setProfileArValue(newValue);
  };
  const [phoneValue, setPhoneValue] = useState("");
  const handleChangePhone = useCallback((newValue) => {
    setPhoneValue(newValue);
  }, []);
  const [emailValue, setEmailValue] = useState("");
  const handleChangeEmail = useCallback((newValue) => {
    setEmailValue(newValue);
  }, []);
  const [aliasValue, setAliasValue] = useState("");
  const handleChangeAlias = useCallback((newValue) => {
    setAliasValue(newValue);
  }, []);
  const [facebookValue, setFacebookValue] = useState("");
  const handleChangeFacebook = useCallback((newValue) => {
    setFacebookValue(newValue);
  }, []);
  const [youtubeValue, setYoutubeValue] = useState("");
  const handleChangeYoutube = useCallback((newValue) => {
    setYoutubeValue(newValue);
  }, []);
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setDesktopImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setDesktopImageError("");
  };
  const [checkedIsPresident, setCheckedIsPresident] = useState(false);
  const handleChangeIsPresident = useCallback(
    (newChecked) => setCheckedIsPresident(newChecked),
    []
  );
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );
  const [selected, setSelected] = useState("");
  const handleSelectChange = (newChecked) => {
    setSelected(newChecked);
    setTypeError("");
  };

  const options = [
    { label: "Team", value: "team" },
    { label: "Founder", value: "founder" },
    { label: "Advisor", value: "advisor" },
  ];
  const [typeError, setTypeError] = useState("");

  return (
    <Page
      title="Add New"
      breadcrumbs={[{ url: "/admin/team-founder-advisor" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Type</TextStyle>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
            placeholder="Please Select"
            error={typeError}
          />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField value={titleEnValue} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField value={titleArValue} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Phone Number</TextStyle>
              <TextField value={phoneValue} onChange={handleChangePhone} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Email</TextStyle>
              <TextField value={emailValue} onChange={handleChangeEmail} />
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Alias</TextStyle>
          <TextField value={aliasValue} onChange={handleChangeAlias} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Position En</TextStyle>
              <TextField
                value={positionEnValue}
                onChange={handleChangepositionEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Position Ar</TextStyle>
              <TextField
                value={positionArValue}
                onChange={handleChangepositionAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Facebook Link</TextStyle>
              <TextField
                value={facebookValue}
                onChange={handleChangeFacebook}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Youtube Link</TextStyle>
              <TextField value={youtubeValue} onChange={handleChangeYoutube} />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Brief En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textEnValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Brief Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textArValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Profile En</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={profileEnValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeProfileEn}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Profile Ar</TextStyle>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={profileArValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
                }}
                onEditorChange={handleChangeProfileAr}
              />
            </FormLayout>
          </FormLayout.Group>

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={desktopImagePreview ? desktopImagePreview : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>
          {/* <Checkbox
            label="Is President"
            checked={checkedIsPresident}
            onChange={handleChangeIsPresident}
          /> */}
          <Checkbox
            label="Is Active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      (!titleEnValue && !titleArValue) ||
      (!textArValue && !textEnValue) ||
      !files.length ||
      !selected
    ) {
      !titleEnValue && !titleArValue && setTitleError("Please enter a name");
      !textArValue && !textEnValue && setTextError("Please enter a text");
      !files.length && setDesktopImageError("Please upload an image");
      !selected && setTypeError("Please select a type");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      titleEnValue && form_data.append("name[en]", titleEnValue);
      titleArValue && form_data.append("name[ar]", titleArValue);
      positionEnValue && form_data.append("position[en]", positionEnValue);
      positionArValue && form_data.append("position[ar]", positionArValue);
      textEnValue && form_data.append("brief[en]", textEnValue);
      textArValue && form_data.append("brief[ar]", textArValue);
      profileEnValue && form_data.append("profile[en]", profileEnValue);
      profileArValue && form_data.append("profile[ar]", profileArValue);
      aliasValue && form_data.append("alias", aliasValue);
      phoneValue && form_data.append("phone[en]", phoneValue);
      emailValue && form_data.append("email", emailValue);
      files.length && form_data.append("image", files[files.length - 1]);
      form_data.append("type", selected);
      form_data.append("order", 1);
      facebookValue &&
        form_data.append("social_media[facebook]", facebookValue);
      youtubeValue && form_data.append("social_media[youtube]", youtubeValue);

      form_data.append("show", checkedShow ? 1 : 0);

      axios
        .post("/admin/teams", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/team-founder-advisor");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Add;
