import React, { useCallback, useState, useEffect } from "react";
import {
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Checkbox,
  ColorPicker,
  TextStyle,
  Loading,
  InlineError,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import { useParams } from "react-router-dom";
import history from "../../Assets/Lib/history";

const Edit = () => {
  const { id } = useParams();
  const handleChangeNameEn = (newValue) => {
    setTag({ ...tag, nameEn: newValue });
    setNameError("");
  };
  const handleChangeNameAr = (newValue) => {
    setTag({ ...tag, nameAr: newValue });
    setNameError("");
  };
  const [nameError, setNameError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const handleChangeShow = (newValue) => setTag({ ...tag, show: newValue });
  const [color, setColor] = useState({
    hue: 120,
    brightness: 1,
    saturation: 1,
  });
  const onColorChange = (value) => {
    setTag({ ...tag, color: value });
  };

  const HSBToRGB = (h, s, b) => {
    // s /= 100;
    // b /= 100;
    const k = (n) => (n + h / 60) % 6;
    const f = (n) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)));
    return [255 * f(5), 255 * f(3), 255 * f(1)];
  };
  const [tag, setTag] = useState({
    nameEn: "",
    nameAr: "",
    show: "",
    color: "",
  });
  useEffect(() => {
    axios
      .get(`admin/tags/${id}`)
      .then((result) => {
        const col = result?.data?.color
          ? rgb2hsv(
              parseInt(result.data.color.split(",")[0].replace("rgb(", "")),
              parseInt(result.data.color.split(",")[1]),
              parseInt(result.data.color.split(",")[2].replace(")", ""))
            )
          : "";
        setTag({
          nameEn: result?.data?.name?.en ? result.data.name.en : "",
          nameAr: result?.data?.name?.ar ? result.data.name.ar : "",
          show: result?.data?.show ? true : false,
          color: result?.data?.color
            ? { alpha: 1, brightness: col[2], hue: col[0], saturation: col[1] }
            : "",
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page title="Edit Tag" breadcrumbs={[{ url: "/admin/tags" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField
                value={tag.nameEn}
                onChange={handleChangeNameEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField
                value={tag.nameAr}
                onChange={handleChangeNameAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={nameError} />
          <TextStyle variation="strong">Color</TextStyle>
          <ColorPicker color={tag.color} onChange={onColorChange} />
          <Checkbox
            label="Is active"
            checked={tag.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/tags/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/tags`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );
  function rgb2hsv(r, g, b) {
    var computedH = 0;
    var computedS = 0;
    var computedV = 0;

    //remove spaces from input RGB values, convert to int
    var r = parseInt(("" + r).replace(/\s/g, ""), 10);
    var g = parseInt(("" + g).replace(/\s/g, ""), 10);
    var b = parseInt(("" + b).replace(/\s/g, ""), 10);

    if (
      r == null ||
      g == null ||
      b == null ||
      isNaN(r) ||
      isNaN(g) ||
      isNaN(b)
    ) {
      alert("Please enter numeric RGB values!");
      return;
    }
    if (r < 0 || g < 0 || b < 0 || r > 255 || g > 255 || b > 255) {
      alert("RGB values must be in the range 0 to 255.");
      return;
    }
    r = r / 255;
    g = g / 255;
    b = b / 255;
    var minRGB = Math.min(r, Math.min(g, b));
    var maxRGB = Math.max(r, Math.max(g, b));

    // Black-gray-white
    if (minRGB == maxRGB) {
      computedV = minRGB;
      return [0, 0, computedV];
    }

    // Colors other than black-gray-white:
    var d = r == minRGB ? g - b : b == minRGB ? r - g : b - r;
    var h = r == minRGB ? 3 : b == minRGB ? 1 : 5;
    computedH = 60 * (h - d / (maxRGB - minRGB));
    computedS = (maxRGB - minRGB) / maxRGB;
    computedV = maxRGB;
    return [computedH, computedS, computedV];
  }
  function handleSave() {
    if (!tag.nameEn && !tag.nameAr) {
      !tag.nameEn && !tag.nameAr && setNameError("Please enter a name");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("id", id);
      tag.nameEn && form_data.append("name[en]", tag.nameEn);
      tag.nameAr && form_data.append("name[ar]", tag.nameAr);
      form_data.append("show", tag.show ? 1 : 0);
      tag.color &&
        form_data.append(
          "color",
          `rgb(${
            HSBToRGB(
              tag.color.hue,
              tag.color.saturation,
              tag.color.brightness
            )[0]
          },${
            HSBToRGB(
              tag.color.hue,
              tag.color.saturation,
              tag.color.brightness
            )[1]
          },${
            HSBToRGB(
              tag.color.hue,
              tag.color.saturation,
              tag.color.brightness
            )[2]
          })`
        );

      axios
        .post("admin/tags/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/tags");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Edit;
