import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Thumbnail,
  Badge,
  FormLayout,
} from "@shopify/polaris";
import axios from "axios";
import history from "../../../Assets/Lib/history";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

const Details = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState("");
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`https://partners.houna.org/api/partnership/${id}`, {
        headers: {
          Authorization: "c0299c8e794e59c061b5cc7f7a43db19",
        },
      })
      .then((result) => {
        console.log(result.data);
        setIsLoading(false);
        setResponse(result.data);
      })
      .catch((err) => setIsLoading(false));
  }, []);

  return (
    <Page
      title={response.name}
      breadcrumbs={[{ url: "/admin/leads/therapists" }]}
    >
      {isLoading ? <Loading /> : null}
      {response && (
        <Card sectioned>
          <FormLayout>
            {response.image && <Thumbnail source={response.image} />}
            <FormLayout.Group>
              {response.name && (
                <TextStyle>{`Name En : ${response.name}`}</TextStyle>
              )}
              {response.name_ar && (
                <TextStyle>{`Name Ar : ${response.name_ar}`}</TextStyle>
              )}
            </FormLayout.Group>

            <FormLayout.Group>
              {response.country && (
                <TextStyle>{`Country : ${response.country}`}</TextStyle>
              )}
              {response.gender && (
                <TextStyle>{`Gender : ${response.gender}`}</TextStyle>
              )}
            </FormLayout.Group>
            <FormLayout.Group>
              {response.organization_name && (
                <TextStyle>{`Organization Name : ${response.organization_name}`}</TextStyle>
              )}

              {response.type && (
                <TextStyle>{`Type : ${response.type}`}</TextStyle>
              )}
            </FormLayout.Group>
            <FormLayout.Group>
              {response.office_address && (
                <TextStyle>{`Office Address En : ${response.office_address}`}</TextStyle>
              )}
              {response.office_address_ar && (
                <TextStyle>{`Office Address Ar : ${response.office_address_ar}`}</TextStyle>
              )}
            </FormLayout.Group>
            <FormLayout.Group>
              {response.email && (
                <TextStyle>{`Email : ${response.email}`}</TextStyle>
              )}
              {response.phone_number && (
                <TextStyle>{`Phone Number : ${response.phone_number}`}</TextStyle>
              )}
            </FormLayout.Group>
            <FormLayout.Group>
              {response.min_age && (
                <TextStyle>{`Minimum Age : ${response.min_age}`}</TextStyle>
              )}
              {response.max_age && (
                <TextStyle>{`Maximum Age : ${response.max_age}`}</TextStyle>
              )}
            </FormLayout.Group>
            <FormLayout.Group>
              {response.services && (
                <TextStyle>{`Services En : ${response.services}`}</TextStyle>
              )}
              {response.services_ar && (
                <TextStyle>{`Services Ar : ${response.services_ar}`}</TextStyle>
              )}
            </FormLayout.Group>
            <FormLayout.Group>
              {response.specialties_text && (
                <TextStyle>{`Specialties En : ${response.specialties_text}`}</TextStyle>
              )}
              {response.specialties_text_ar && (
                <TextStyle>{`Specialties Ar : ${response.specialties_text_ar}`}</TextStyle>
              )}
            </FormLayout.Group>
            <FormLayout.Group>
              {response.biography && (
                <TextStyle>{`Biography En : ${response.biography}`}</TextStyle>
              )}
              {response.biography_ar && (
                <TextStyle>{`Biography Ar : ${response.biography_ar}`}</TextStyle>
              )}
            </FormLayout.Group>
            <FormLayout.Group>
              {response.short_description && (
                <TextStyle>{`Short Description : ${response.short_description}`}</TextStyle>
              )}
              {response.contribution_type && (
                <TextStyle>{`Contributions : ${response.contribution_type}`}</TextStyle>
              )}
            </FormLayout.Group>
          </FormLayout>
        </Card>
      )}
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function findElementByID(array, id) {
    return array.find((element) => {
      return element.id === id;
    });
  }
};

export default Details;
