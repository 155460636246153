import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  DataTable,
  Select,
  Modal,
  Button,
  ButtonGroup,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import history from "../../../Assets/Lib/history";
import MultiSelect from "react-multi-select-component";
import { useParams } from "react-router-dom";
import EllipsisText from "react-ellipsis-text";
import RichEditor from "@methodexists/me-rich-editor";

const Edit = () => {
  const { id } = useParams();
  var PromiseAll = require("promises-all");
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = (newValue) => {
    setItem({ ...item, nameEn: newValue });
    setTitleError("");
  };
  const [titleArValue, setTitleArValue] = useState("");
  const handleChangeTitleAr = (newValue) => {
    setItem({ ...item, nameAr: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeTextEn = (newValue) => {
    setItem({ ...item, textEn: newValue });
  };
  const handleChangeTextAr = (newValue) => {
    setItem({ ...item, textAr: newValue });
  };
  const [textError, setTextError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const validImageTypes = [
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/json",
  ];
  const [desktopImagePreview, setDesktopImagePreview] = useState("");
  const [mobileImagePreview, setMobileImagePreview] = useState("");
  const [desktopImageError, setDesktopImageError] = useState("");
  const [mobileImageError, setMobileImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
  };
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = (newValue) => setItem({ ...item, show: newValue });
  const [optionsCriterias, setOptionsCriterias] = useState([]);
  const [selectedCriteria, setSelectedCriteria] = useState("");
  const handleSelectChangeCriteria = useCallback(
    (value) => setSelectedCriteria(value),
    []
  );
  const [countValue, setCountValue] = useState("");
  const handleChangeCountValue = useCallback(
    (value) => setCountValue(value),
    []
  );
  const [rows, setRows] = useState([]);
  const [items, setItems] = useState([]);
  const [active, setActive] = useState(false);
  const handleChange = useCallback(() => setActive(!active), [active]);
  const [checked, setChecked] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const handleCheckbox = useCallback((value) => setChecked(value), []);
  const [textCriteriaEnValue, setTextCriteriaEnValue] = useState("");
  const handleChangeTextCriteriaEn = (newValue) => {
    setTextCriteriaEnValue(newValue);
  };
  const [textCriteriaArValue, setTextCriteriaArValue] = useState("");
  const handleChangeTextCriteriaAr = (newValue) => {
    setTextCriteriaArValue(newValue);
  };
  const [item, setItem] = useState({
    nameEn: "",
    nameAr: "",
    textEn: "",
    textAr: "",
    show: "",
    image: "",
    criterias: [],
  });
  const [isNew, setIsNew] = useState(true);
  const [clickedRow, setClickedRow] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    PromiseAll.all([getTags(), getCriterias(), getItem()]).then(function (
      response
    ) {
      if (typeof response.resolve[0] === "object") {
        setIsSaving(false);
        setOptions(
          response.resolve[0].data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: item.id,
            };
          })
        );
      }
      if (typeof response.resolve[1] === "object") {
        setOptionsCriterias(
          response.resolve[1].data.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
            };
          })
        );
      }
      if (typeof response.resolve[2] === "object") {
        setItem({
          nameEn: response.resolve[2]?.data?.name?.en
            ? response.resolve[2].data.name.en
            : "",
          nameAr: response.resolve[2]?.data?.name?.ar
            ? response.resolve[2].data.name.ar
            : "",
          textEn: response.resolve[2]?.data?.text?.en
            ? response.resolve[2].data.text.en
            : "",
          textAr: response.resolve[2]?.data?.text?.ar
            ? response.resolve[2].data.text.ar
            : "",
          show: response.resolve[2]?.data?.show ? true : false,
          image: response.resolve[2]?.data?.image
            ? response.resolve[2].data.image
            : "",
        });

        setItems(
          response.resolve[2].data.criteria.map(function (item, index) {
            return [
              index + 1,
              item?.name?.en && item.name.en,
              item?.text?.en && (
                <EllipsisText
                  text={item.text.en.replace(/<[^>]+>/g, "")}
                  length={25}
                />
              ),
              <ButtonGroup>
                <Button
                  id={
                    String(index + 1) +
                    "|_|" +
                    item.text.en +
                    "|_|" +
                    item.text.ar +
                    "|_|" +
                    String(item.count) +
                    "|_|" +
                    item.id
                  }
                  onClick={(e) => handleEdit(e)}
                >
                  Edit
                </Button>
              </ButtonGroup>,
            ];
          })
        );
        setRows(
          response.resolve[2].data.criteria.map(function (item, index) {
            return [
              <ButtonGroup>
                <Button
                  id={
                    String(index + 1) +
                    "|_|" +
                    item.text.en +
                    "|_|" +
                    item.text.ar +
                    "|_|" +
                    String(item.count) +
                    "|_|" +
                    item.id
                  }
                  onClick={(e) => handleEdit(e)}
                >
                  Edit
                </Button>
              </ButtonGroup>,
              index + 1,
              item?.text?.en && item.text.en,
              item?.text?.ar && item.text.ar,
              item?.count && item.count,
              item.id,
            ];
          })
        );
        setCurrentIndex(response.resolve[2].data.criteria.length);
        response.resolve[2].data.tags &&
          setSelected(
            response.resolve[2].data.tags.map((item, index) => {
              return {
                label: item.name.en,
                value: String(item.id),
                key: String(item.id),
              };
            })
          );
      }
    });
  }, []);
  const handleImageUpload = (file) => {
    const form_data = new FormData();
    form_data.append("image", file);
    return axios
      .post("/admin/images", form_data)
      .then((res) => {
        return res.data.path;
      })
      .catch((err) => console.log(""));
  };
  useEffect(() => {
    console.log("rows=", rows);
  }, [rows]);
  const handleAddCriteria = () => {
    setActive(true);
    setIsNew(true);
    setTextCriteriaArValue("");
    setTextCriteriaEnValue("");
    setCountValue("");
    setSelectedCriteria("");
  };
  const getTags = () => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get("/admin/tags")
          .then(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 0);
    });
  };
  const getItem = () => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get(`/admin/disorders/${id}`)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 1000);
    });
  };
  const getCriterias = () => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get("/admin/criteria-disorders")
          .then(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 500);
    });
  };
  const handleSubmit = () => {
    setTimeout(() => {
      console.log("World!");
    }, 2000);
    console.log("textCriteriaEnValue=", textCriteriaEnValue);
    if (isNew) {
      setCurrentIndex(currentIndex + 1);
      toggleActive();
      setRows((rows) => [
        ...rows,
        [
          <ButtonGroup>
            <Button
              id={
                currentIndex +
                1 +
                "|_|" +
                textCriteriaEnValue +
                "|_|" +
                textCriteriaArValue +
                "|_|" +
                countValue +
                "|_|" +
                selectedCriteria
              }
              onClick={(e) => handleEdit(e)}
            >
              Edit
            </Button>
          </ButtonGroup>,
          currentIndex + 1,
          textCriteriaEnValue,
          textCriteriaArValue,
          countValue,
          selectedCriteria,
        ],
      ]);

      setItems((items) => [
        ...items,
        [
          currentIndex + 1,
          findArrayElementById(optionsCriterias, selectedCriteria).label,
          <EllipsisText
            text={textCriteriaEnValue.replace(/<[^>]+>/g, "")}
            length={25}
          />,
          <ButtonGroup>
            <Button
              id={
                currentIndex +
                1 +
                "|_|" +
                textCriteriaEnValue +
                "|_|" +
                textCriteriaArValue +
                "|_|" +
                countValue +
                "|_|" +
                selectedCriteria
              }
              onClick={(e) => handleEdit(e)}
            >
              Edit
            </Button>
          </ButtonGroup>,
        ],
      ]);
    } else {
      // setCurrentIndex(currentIndex + 1);
      toggleActive();
      setRows(
        rows.map((item) =>
          item[1] == clickedRow
            ? [
                <ButtonGroup>
                  <Button
                    id={
                      clickedRow +
                      "|_|" +
                      textCriteriaEnValue +
                      "|_|" +
                      textCriteriaArValue +
                      "|_|" +
                      countValue +
                      "|_|" +
                      selectedCriteria
                    }
                    onClick={(e) => handleEdit(e)}
                  >
                    Edit
                  </Button>
                </ButtonGroup>,
                clickedRow,
                textCriteriaEnValue,
                textCriteriaArValue,
                countValue,
                selectedCriteria,
              ]
            : item
        )
      );

      setItems(
        items.map((item) =>
          item[0] == clickedRow
            ? [
                clickedRow,
                findArrayElementById(optionsCriterias, selectedCriteria).label,
                <EllipsisText
                  text={textCriteriaEnValue.replace(/<[^>]+>/g, "")}
                  length={25}
                />,
                <ButtonGroup>
                  <Button
                    id={
                      clickedRow +
                      "|_|" +
                      textCriteriaEnValue +
                      "|_|" +
                      textCriteriaArValue +
                      "|_|" +
                      countValue +
                      "|_|" +
                      selectedCriteria
                    }
                    onClick={(e) => handleEdit(e)}
                  >
                    Edit
                  </Button>
                </ButtonGroup>,
              ]
            : item
        )
      );
    }

    setActive(false);
  };

  return (
    <Page
      title="Edit Disorder"
      breadcrumbs={[{ url: "/admin/disorders/items" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField value={item.nameEn} onChange={handleChangeTitleEn} />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField value={item.nameAr} onChange={handleChangeTitleAr} />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={titleError} />
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Text En</TextStyle>
              <TextField
                value={item.textEn}
                onChange={handleChangeTextEn}
              ></TextField>
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Text Ar</TextStyle>
              <TextField
                value={item.textAr}
                onChange={handleChangeTextAr}
              ></TextField>
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={textError} />

          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={item.image ? item.image : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                    {uploadedFiles}
                    {fileUpload}
                  </DropZone>
                </div>
              </div>
              <InlineError message={desktopImageError} />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout>
            <TextStyle variation="strong">Tags</TextStyle>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            />
          </FormLayout>
          <Card
            title="Criterias"
            actions={[{ content: "Add Criteria", onAction: handleAddCriteria }]}
          >
            <DataTable
              columnContentTypes={["text", "text", "text", "text"]}
              headings={["ID", "Category Name", "Text En", ""]}
              rows={items}
            />
          </Card>
          <Checkbox
            label="Is Active"
            checked={item.show}
            onChange={handleChangeShow}
          />
        </FormLayout>
        {isNew ? (
          <Modal
            large
            open={active}
            onClose={toggleActive}
            title="Add Criteria"
            primaryAction={{
              content: "Submit",
              onAction: handleSubmit,
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: toggleActive,
              },
            ]}
          >
            <Modal.Section>
              <Stack vertical>
                <Select
                  options={optionsCriterias}
                  onChange={handleSelectChangeCriteria}
                  value={selectedCriteria}
                  placeholder="Please select a criteria"
                />
                <TextStyle variation="strong">Count</TextStyle>
                <TextField
                  value={countValue}
                  onChange={handleChangeCountValue}
                  type="number"
                />
                <FormLayout.Group>
                  <FormLayout>
                    <TextStyle variation="strong">Text En</TextStyle>

                    <RichEditor
                      value={textCriteriaEnValue}
                      onChange={handleChangeTextCriteriaEn}
                      onImageUpload={handleImageUpload}
                      debounceDelay={0}
                    />
                  </FormLayout>
                  <FormLayout>
                    <TextStyle variation="strong">Text Ar</TextStyle>
                    <RichEditor
                      value={textCriteriaArValue}
                      onChange={handleChangeTextCriteriaAr}
                      onImageUpload={handleImageUpload}
                      debounceDelay={0}
                    />
                  </FormLayout>
                </FormLayout.Group>
              </Stack>
            </Modal.Section>
          </Modal>
        ) : (
          <Modal
            large
            open={active}
            onClose={toggleActive}
            title="Edit Criteria"
            primaryAction={{
              content: "Submit",
              onAction: handleSubmit,
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: handleChange,
              },
              {
                content: "Delete",
                onAction: handleDelete,
                destructive: true,
              },
            ]}
          >
            <Modal.Section>
              <Stack vertical>
                <Select
                  options={optionsCriterias}
                  onChange={handleSelectChangeCriteria}
                  value={selectedCriteria}
                  placeholder="Please select a criteria"
                />
                <TextStyle variation="strong">Count</TextStyle>
                <TextField
                  value={countValue}
                  onChange={handleChangeCountValue}
                  type="number"
                />
                <FormLayout.Group>
                  <FormLayout>
                    <TextStyle variation="strong">Text En</TextStyle>

                    <RichEditor
                      value={textCriteriaEnValue}
                      onChange={handleChangeTextCriteriaEn}
                      onImageUpload={handleImageUpload}
                      debounceDelay={0}
                    />
                  </FormLayout>
                  <FormLayout>
                    <TextStyle variation="strong">Text Ar</TextStyle>

                    <RichEditor
                      value={textCriteriaArValue}
                      onChange={handleChangeTextCriteriaAr}
                      onImageUpload={handleImageUpload}
                      debounceDelay={0}
                    />
                  </FormLayout>
                </FormLayout.Group>
              </Stack>
            </Modal.Section>
          </Modal>
        )}
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .post(`/admin/disorders/delete`, { id: id })
                .then((result) => {
                  history.push(`/admin/disorders/items`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );
  function handleEdit(e) {
    console.log(e.currentTarget.id.split("|_|")[3]);
    setIsNew(false);
    setClickedRow(e.currentTarget.id.split("|_|")[0]);
    setTextCriteriaEnValue(e.currentTarget.id.split("|_|")[1]);
    setTextCriteriaArValue(e.currentTarget.id.split("|_|")[2]);
    e.currentTarget.id.split("|_|")[3] != 0 &&
      setCountValue(e.currentTarget.id.split("|_|")[3]);
    setActive(true);
    setSelectedCriteria(String(e.currentTarget.id.split("|_|")[4]));
  }
  function handleDelete(e) {
    setRows(rows.filter((item) => parseInt(item[1]) != parseInt(clickedRow)));
    setItems(items.filter((item) => parseInt(item[0]) != parseInt(clickedRow)));
    setActive(false);
  }
  function handleSave() {
    if ((!item.nameEn && !item.nameAr) || (!item.textEn && !item.textAr)) {
      !item.nameEn && !item.nameAr && setTitleError("Please enter a title");
      !item.textEn && !item.textAr && setTextError("Please enter a text");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("id", id);
      item.nameEn && form_data.append("name[en]", item.nameEn);
      item.nameAr && form_data.append("name[ar]", item.nameAr);
      item.textEn && form_data.append("text[en]", item.textEn);
      item.textAr && form_data.append("text[ar]", item.textAr);
      selected.forEach((selected, index) =>
        form_data.append(`tags[${index}]`, selected.value)
      );
      rows.forEach(function (item, index) {
        form_data.append(`criteria[${index}][id]`, item[5]);
        form_data.append(`criteria[${index}][text][en]`, item[2]);
        form_data.append(`criteria[${index}][text][ar]`, item[3]);
        form_data.append(`criteria[${index}][show]`, 1);
        form_data.append(`criteria[${index}][count]`, item[4]);
      });
      files.length && form_data.append("image", files[files.length - 1]);
      form_data.append("show", item.show ? 1 : 0);
      form_data.append("order", 1);
      item.nameEn && form_data.append("alias", string_to_slug(item.nameEn));

      axios
        .post("/admin/disorders/update", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/disorders/items");
          }
        })
        .catch((err) => console.log(""));
    }
  }

  function findArrayElementById(array, value) {
    return array.find((element, index) => {
      return parseInt(element.value) === parseInt(value);
    });
  }
  function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  }
};
export default Edit;
