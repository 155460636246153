import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Lib/history";
import NavigationLayout from "../../Components/NavigationLayout";
import GridView from "./Grid";
import Labels from "./Labels/Main";
import Categories from "./Categories/Main";
import Speakers from "./Speakers/Main";
import Items from "./Items/Main";

const Events = () => {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/events" component={GridView} />
          <Route path="/admin/events/labels" component={Labels} />
          <Route path="/admin/events/speakers" component={Speakers} />
          <Route path="/admin/events/items" component={Items} />
          <Route path="/admin/events/categories" component={Categories} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
};
export default Events;
