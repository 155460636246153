import React, { useCallback, useState } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  ColorPicker,
  Checkbox,
  Caption,
  TextStyle,
  Loading,
  InlineError,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import Cookie from "js-cookie";
import history from "../../Assets/Lib/history";

const Add = () => {
  var hsl = require("hsl-to-hex");
  const [nameEnValue, setNameEnValue] = useState("");
  const handleChangeNameEn = useCallback((newValue) => {
    setNameEnValue(newValue);
    setNameError("");
  }, []);
  const [nameArValue, setNameArValue] = useState("");
  const handleChangeNameAr = useCallback((newValue) => {
    setNameArValue(newValue);
    setNameError("");
  }, []);
  const [nameError, setNameError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [color, setColor] = useState({
    hue: 120,
    brightness: 1,
    saturation: 1,
  });
  const onColorChange = (value) => {
    console.log(HSBToRGB(value.hue, value.saturation, value.brightness));
    setColor(value);
  };
  const [checked, setChecked] = useState(false);
  const handleChange = useCallback((newChecked) => setChecked(newChecked), []);
  const HSBToRGB = (h, s, b) => {
    // s /= 100;
    // b /= 100;
    const k = (n) => (n + h / 60) % 6;
    const f = (n) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)));
    return [255 * f(5), 255 * f(3), 255 * f(1)];
  };
  return (
    <Page title="Add Tag" breadcrumbs={[{ url: "/admin/tags" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Name En</TextStyle>
              <TextField
                value={nameEnValue}
                onChange={handleChangeNameEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Name Ar</TextStyle>
              <TextField
                value={nameArValue}
                onChange={handleChangeNameAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={nameError} />
          <TextStyle variation="strong">Color</TextStyle>
          <ColorPicker color={color} onChange={onColorChange} />
          <Checkbox
            label="Is active"
            checked={checked}
            onChange={handleChange}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (!nameEnValue && !nameArValue) {
      setNameError("Please enter a name");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      nameEnValue && form_data.append("name[en]", nameEnValue);
      nameArValue && form_data.append("name[ar]", nameArValue);
      form_data.append("show", checked ? 1 : 0);
      form_data.append(
        "color",
        `rgb(${HSBToRGB(color.hue, color.saturation, color.brightness)[0]},${
          HSBToRGB(color.hue, color.saturation, color.brightness)[1]
        },${HSBToRGB(color.hue, color.saturation, color.brightness)[2]})`
      );
      axios
        .post("admin/tags", form_data)
        .then((res) => {
          if (res.status == 200) {
            history.push("/admin/tags");
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Add;
