import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Lib/history";
import NavigationLayout from "../../Components/NavigationLayout";
import GridView from "./Grid";
import Criterias from "./Criterias/Main";
import Items from "./Items/Main";

const Disorders = () => {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/disorders" component={GridView} />
          <Route path="/admin/disorders/criterias" component={Criterias} />
          <Route path="/admin/disorders/items" component={Items} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
};
export default Disorders;
