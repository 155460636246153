import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Thumbnail,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import EllipsisText from "react-ellipsis-text";

const List = () => {
  const perPage = 50;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleEdit = (e) => {
    history.push(`/admin/hotlines/worldwide/${e.currentTarget.id}`);
  };
  useEffect(() => {
    axios
      .get(
        `/admin/hotlines/worldwide?per_page=${perPage}&page=${page}&filter[${
          availability == "" ? "title" : availability
        }]=${queryValue}&sort=${sortState}`
      )
      .then((result) => {
        setIsLoading(false);
        !result.data.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.total / perPage));
        setItems(
          result.data.data.map((item) => [
            item.image && <Thumbnail source={item.image}></Thumbnail>,
            item?.title && item.title[Object.keys(item.title)[0]],
            item?.link && item.link,
            <ButtonGroup>
              <Button onClick={handleEdit} id={item.id}>
                Edit
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => setIsLoading(false));
  }, [page, sortState, availability, queryValue]);

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Title", value: "title" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  return (
    <Page
      fullWidth
      title="Worldwide"
      primaryAction={{
        content: "Add Item",
        url: "/admin/hotlines/worldwide/new",
      }}
      breadcrumbs={[{ url: "/admin/hotlines" }]}
    >
      <Card>
        {loadingMarkup}
        <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">Image</TextStyle>,
            <TextStyle variation="strong">Title</TextStyle>,
            <TextStyle variation="strong">Link</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default List;
